import React from 'react'
import ReactDOM from 'react-dom'
// Redux
// https://github.com/rt2zz/redux-persist
import {PersistGate} from 'redux-persist/integration/react'
import {Provider} from 'react-redux'
import * as _redux from './setup'
import store, {persistor} from './setup/redux/Store'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'

// Apps
import {App} from './app/App'
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'

import { datadogRum } from '@datadog/browser-rum';
// import BugsnagClient from './app/bugsnag/BugsnagClient';

import Utils from './app/utils/utils'

/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import './app/v5/assets/scss/bootstrap.scss'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {
  PUBLIC_URL,
  REACT_APP_ENVIRONMENT,
  REACT_APP_VERSION,
  REACT_APP_DATADOG_APLICATION_ID,
  REACT_APP_DATADOG_CLIENT_TOKEN,
  REACT_APP_COMMON_PROFILE_API_URL,
  REACT_APP_DISPATCH_API_URL
} = process.env
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store)

Chart.register(...registerables)


if(REACT_APP_ENVIRONMENT != 'development'){
  datadogRum.init({
    applicationId: '' + REACT_APP_DATADOG_APLICATION_ID,
    clientToken: '' + REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'us5.datadoghq.com',
    service:'wasteporter-' + REACT_APP_ENVIRONMENT,
    env: REACT_APP_ENVIRONMENT,
    version: REACT_APP_VERSION,
    sessionSampleRate: 100,
    premiumSampleRate: 100,
    defaultPrivacyLevel:'allow',
    trackUserInteractions: true,
    trackFrustrations: true,
    allowedTracingUrls: [(url) => url.startsWith('' + REACT_APP_COMMON_PROFILE_API_URL), (url) => url.startsWith('' + REACT_APP_DISPATCH_API_URL)]
  });
  
  try {
    let auth = Utils.getAuth();
    let user = (auth && auth.user) ? auth.user : null;
    let userInfo = Utils.getUserInfo(user);

    if(userInfo){
      let id = (userInfo && userInfo.userId) ? userInfo.userId : 0;
      let name = (userInfo && userInfo.name) ? userInfo.name : '';
      let email = (userInfo && userInfo.email) ? userInfo.email : '';

      let currentUser = {
        id: '' + id,
        name: name,
        email: email,
      };
      datadogRum.setUser(currentUser);
    }
  }catch(e){}
  
  datadogRum.startSessionReplayRecording();
}


ReactDOM.render(
  <MetronicI18nProvider>
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
        {/* <BugsnagClient store={store}> */}
          <App basename={PUBLIC_URL} />
        {/* </BugsnagClient> */}
      </PersistGate>
    </Provider>
  </MetronicI18nProvider>,
  document.getElementById('root')
)
