import React from 'react';
import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';

import { Tooltip } from 'antd';

import { useRouter } from '../auth/auth-guard.js';

import { MAIN_BREAKPOINT } from '../utils/enums';

import { ReactComponent as HomeIcon } from "../assets/icons/home.svg";
import { ReactComponent as MapPinIcon } from "../assets/icons/map-pin.svg";
import { ReactComponent as UsersIcon } from "../assets/icons/users.svg";
import { ReactComponent as BriefcaseIcon } from "../assets/icons/briefcase.svg";
import { ReactComponent as GlobeIcon } from "../assets/icons/globe.svg";
import { ReactComponent as CreditCardIcon } from "../assets/icons/credit-card.svg";
import { ReactComponent as SettingsIcon } from "../assets/icons/settings.svg";
import { ReactComponent as BarChart2Icon } from "../assets/icons/bar-chart-2.svg";

// ----------------------------------------------------------------------

const StyledMenu = styled.div`
  z-index: 1;
  background-color: white;

  &.responsive {
    ${up(MAIN_BREAKPOINT)} {
      width: 80px;
      border-right-width: 1px;
      border-right-style: solid;
      border-color: var(--bs-gray-100);
    }
  
    ${down(MAIN_BREAKPOINT)} {
      display: none;
      width: auto;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-color: var(--bs-gray-100);
  
      > div {
        display: inline-block;
      }
    }
  }

  &.not-responsive {
    width: 80px;
    border-right-width: 1px;
    border-right-style: solid;
    border-color: var(--bs-gray-100);
  }

  .navigation {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    background-color: var(--basewhite);

    ${up('xs')} {
      display: flex;
      overflow: auto;
      padding: 12px 16px 0px;
      height: calc(100vh - var(--manage-offcanvas-header-height, 82px) - 1px);
    }

    ${up('sm')} {
      display: flex;
      overflow: auto;
      padding: 12px 16px 0px;
      height: calc(100vh - var(--manage-offcanvas-header-height, 82px) - 1px);
    }

    ${up('lg')} {
      position: fixed !important;
      overflow: auto;
      height: calc(100vh - var(--header-height, 60px));
    }

    ${down(MAIN_BREAKPOINT)} {
      position: relative;
      display: inline-block;
      padding: 12px 16px;
      height: calc(100vh - var(--manage-offcanvas-header-height, 82px) - 1px);
    }

    svg {
      position: relative;
      width: 20px;
      height: 20px;
    }

    .img-wrapper {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 12px;
      position: relative;
      flex: 0 0 auto;
      background-color: var(--basewhite);
      border-radius: 6px;
  
      path {
        stroke: var(--bs-gray-600);
      }
  
      &:hover {
        background-color: var(--bs-blue-focus1);

        path {
          stroke: var(--bs-blue-500);
        }
      }

      &.active {
        background-color: var(--bs-blue-focus1);

        path {
          stroke: var(--bs-blue-500);
        }
      }
    }
  }
`;

// ----------------------------------------------------------------------

const Menu = ({ isResponsive }: any) => {
  const router = useRouter();


  return <StyledMenu className={(isResponsive ? 'responsive' : 'not-responsive')}>
    <div className="navigation">
      <Tooltip placement={'right'} title={'Dashboard'}>
        <a href={'/dashboard'}
          className={`img-wrapper ${(window.location.pathname.startsWith('/dashboard')) ? 'active' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            router.push('/dashboard');
          }}
        >
          <HomeIcon />
        </a>
      </Tooltip>
      <Tooltip placement={'right'} title={'Map'}>
        <a href={'/map'}
          className={`img-wrapper ${(window.location.pathname.startsWith('/map')) ? 'active' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            router.push('/map');
          }}
        >
          <MapPinIcon />
        </a>
      </Tooltip>
      <Tooltip placement={'right'} title={'Customers'}>
        <a href={'/customers'}
          className={`img-wrapper ${(window.location.pathname.startsWith('/customers')) ? 'active' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            router.push('/customers');
          }}
        >
          <UsersIcon />
        </a>
      </Tooltip>
      <Tooltip placement={'right'} title={'Jobs'}>
        <a href={'/jobs'}
          className={`img-wrapper ${(window.location.pathname.startsWith('/jobs')) ? 'active' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            router.push('/jobs');
          }}
        >
          <BriefcaseIcon />
        </a>
      </Tooltip>
      <Tooltip placement={'right'} title={'Global services'}>
        <a href={'/services'}
          className={`img-wrapper ${(window.location.pathname.startsWith('/services')) ? 'active' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            router.push('/services');
          }}
        >
          <GlobeIcon />
        </a>
      </Tooltip>
      <Tooltip placement={'right'} title={'Billing'}>
        <a href={'/billing/invoice-table/list-by-dates'}
          className={`img-wrapper ${(window.location.pathname.startsWith('/billing')) ? 'active' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            router.push('/billing/invoice-table/list-by-dates');
          }}
        >
          <CreditCardIcon />
        </a>
      </Tooltip>
      <Tooltip placement={'right'} title={'Manage'}>
        <a href={'/v5/manage/job/job-types'}
          className={`img-wrapper ${(window.location.pathname.startsWith('/v5/manage')) ? 'active' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            router.push('/v5/manage/job/job-types');
          }}
        >
          <SettingsIcon />
        </a>
      </Tooltip>
      <Tooltip placement={'right'} title={'Reports'}>
        <a href={'/Reports-bins-on-site'}
          className={`img-wrapper ${(window.location.pathname.startsWith('/reports')) ? 'active' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            router.push('/Reports-bins-on-site');
          }}
        >
          <BarChart2Icon />
        </a>
      </Tooltip>
    </div>
  </StyledMenu>
}

export default Menu;