import React from 'react';

import {
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap';
import styled from 'styled-components';

import { 
  Popover,
} from 'antd';

import { ReactComponent as XIcon } from "../../assets/icons/x.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";

// ----------------------------------------------------------------------

export default function EditTitle({ value, required, disabled, onUpdate }: any) {
  const [open, setOpen] = React.useState(false);
  const [val, setVal] = React.useState(value);
  const inputRef = React.useRef<HTMLInputElement>(null);

  
  React.useEffect(() => {
    setVal(value);

    if(open){
      setTimeout(() => {
        if(inputRef && inputRef.current){
          inputRef.current.focus();
        }
      }, 200);
    }
  }, [open]);


  const hide = () => {
    setOpen(false);
  };

  const update = () => {
    if(required === true){
      if(val != ''){
        if(onUpdate){
          onUpdate(val);
        }

        hide();
      }
    } else {
      if(onUpdate){
        onUpdate(val);
      }
      
      hide();
    }
  };

  return <Popover
    open={open}
    onOpenChange={(newOpen: boolean) => {
      setOpen(newOpen);
    }}

    content={<div style={{ minWidth: '280px' }}>
      <Row className='align-items-center g-0 p-16 pb-0'>
        <Col xs={true} className='fw-medium text-center'>Edit title</Col>
        <Col xs={'auto'}>
          <Button
            className='mb-1'
            size={'sm'}
            variant={'custom-none-primary'}
            onClick={(e) => { 
              e.preventDefault();
              e.stopPropagation();

              hide();
            }}
          >
            <XIcon />
          </Button>
        </Col>
      </Row>
      
      <Row className='align-items-center g-0 p-16 pt-18'>
        <Col xs={12}>
          <Form.Group>
            <Form.Label className='xsmall fw-bold'>Title name</Form.Label>
            <Form.Control
              ref={inputRef}
              autoFocus
              type={'text'}
              autoComplete='off'
              placeholder={'Title'}
              value={val}
              onChange={(e) => {
                setVal(e.target.value);
              }}
              isInvalid={(required === true) ? !(val != '') : false}
            />
            <Form.Control.Feedback type="invalid">Title is required</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      
      <Row className='align-items-center g-0 p-16' style={{ backgroundColor: 'var(--bs-blue-focus1)' }}>
        <Col xs={12}>
          <Button
            variant={'custom-primary'}
            disabled={(required === true) ? !(val != '') : false}
            onClick={() => {
              update();
            }}
          >Update</Button>
        </Col>
      </Row>
    </div>}
    arrow={false}
    placement="rightTop"
    trigger="click"
  >
    <Button
      className={'ps-1'}
      size={'sm'}
      variant={'custom-none-primary'}
      disabled={disabled}
    >
      <EditIcon />
    </Button>
  </Popover>
}
