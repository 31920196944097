/* eslint-disable spaced-comment */
import PropTypes from 'prop-types';

import { useAuthContext } from './use-auth-context';

import ErrorLayout from '../layouts/ErrorLayout';
import PermissionPage from '../pages/error/Permission.tsx';

import { Permissions } from '../utils/enums.tsx';
import { isProduction, getUser } from '../utils/utils.tsx';

// ----------------------------------------------------------------------

export default function RoleBasedGuard({ env, roles, strictly, children }) {
  const user = getUser();

  const rolesArr = (roles && roles.length > 0) ? roles : [];
  const permissions = (user && user.permissions && user.permissions.length > 0) ? user.permissions : [];
  
  let isOk = false;
  if(env === 'production') {
    if(rolesArr.findIndex(x => x === Permissions.All) !== -1){
      isOk = true;
    } else {
      if(strictly){
        isOk = permissions.every(element => rolesArr.includes(element.permissionId));
      } else {
        isOk = permissions.some(element => rolesArr.includes(element.permissionId));
      }
    }

  } else {
    if(isProduction()){
      isOk = false;
    } else {
      if(rolesArr.findIndex(x => x === Permissions.All) !== -1){
        isOk = true;
      } else {
        if(strictly){
          isOk = permissions.every(element => rolesArr.includes(element.permissionId));
        } else {
          isOk = permissions.some(element => rolesArr.includes(element.permissionId));
        }
      }
    }
  }

  if(isOk){
    return children
  } else {
    return <ErrorLayout>
      <PermissionPage />
    </ErrorLayout>
  }
}

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  Layout: PropTypes.any,
  hasContent: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.number),
};
