import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Form,
  Button,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Divider, Radio } from "antd";

// Auth
// import { useAuthContext } from '../../../../../auth/use-auth-context.js';
// import { useRouter } from '../../../../../auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../../utils/store.tsx';
import slice, { useFormikContext, fields, LocationEnum, DriverActivityEnum, getVehicleBinStatus, disableLocations, disableDriverActivityes, initStep, initStepSite } from '../slice.tsx';
import confirmSlice from '../../../../../components/modal/confirmSlice.tsx';

// enums
import { success, warning, autoFoucus, scrollTo, getAddressPlace } from '../../../../../utils/utils.tsx';
// import {  } from '../../../../../utils/enums.tsx';

// components
// import MainContainer from '../../../../../components/container/MainContainer.jsx';
import SmartAutocomplete from '../../../../../components/autocomplete/SmartAutocomplete.tsx';
import GoogleSearchAddress from '../../../../../components/googleMap/GoogleSearchAddress.tsx';

import { ReactComponent as PlusIcon } from "../../../../../../v5/assets/icons/plus.svg";
import { ReactComponent as TrashIcon } from "../../../../../../v5/assets/icons/trash-2.svg";
import { ReactComponent as InfoIcon } from "../../../../../../v5/assets/icons/i.svg";

// ----------------------------------------------------------------------

const StyledStep = styled(Col)`
  border-radius: 6px;
  background-color: var(--bs-blue-focus1);

  > div {
    padding: 16px 24px;

    > div {
      padding-left: 0px;
      padding-right: 0px;

      .ant-divider {
        margin-top: 4px;
        margin-bottom: 4px;
        border-color: var(--bs-gray-100);
      }
    }
  }
`;
const StyledStepStatus = styled(Col)`
  border-radius: 6px;
  padding: 16px 24px;
  background-color: var(--bs-green-focus2);

  
  .ant-divider {
    margin-left: 0px;
    margin-right: 0px;
    border-color: var(--bs-gray-100);
  }
`;
const StyledInfoIcon = styled(InfoIcon)`
  position: relative;
  top: -1px;
  width: 18px;
  height: 19px;
  margin-right: 5px;

  path {
    stroke: var(--bs-gray-600);
  }
`;
const StyledVehicleBinStatus = styled.span`
  color: var(--bs-gray-600);

  &.Loaded {
    color: var(--bs-yellow-500);
  }
  
  &.Empty {
    color: var(--bs-green-500);
  }
`;
const StyledStepLocation = styled(Col)`
  border-radius: 6px;
  padding: 12px 16px;
  background-color: white;
`;


const Tab2 = () => {
  // const location = useLocation();
  // const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  // const { isLoading } = useTypedSelector((state: RootState) => state.manageJobTypesSlice);
  const dispatch = useDispatch<AppDispatch>();


  const setFormElements = () => {
    return <Row className='g-16'>

      <Col xs={12}>
        <Row className='g-16'>
          {values.steps.map((item: any, i: number) => {
            let vehicleBinStatus: any = getVehicleBinStatus(i, item, values.steps);
            let disableLocation: any = disableLocations(i, item, values.steps);
            let disableDriverActivity: any = disableDriverActivityes(i, item, values.steps);

            return <React.Fragment key={'step_tab2_' + i} >
              <StyledStep xs={12} className={'step-' + i}>
                <Row className='g-16'>
                  <Col xs={12} className='semibold gray-900'>Step {(i+1)}</Col>

                  <Col xs={12}>
                    <Row className='g-12'>
                      <Col xs={12} className='gray-800'>Choose a location</Col>
                      <Col xs={'auto'}>
                        <Radio
                          disabled={disableLocation?.CustomerSite}
                          checked={(item.location === LocationEnum.CustomerSite)}
                          onChange={(e) => {
                            setFieldValue('steps.' + i + '.location', LocationEnum.CustomerSite);
                            setFieldValue('steps.' + i + '.driverActivity', null);
                          }}
                        >Customer Site</Radio>
                      </Col>
                      <Col xs={'auto'}>
                        <Radio
                          disabled={disableLocation?.Yard}
                          checked={(item.location === LocationEnum.Yard)}
                          onChange={(e) => {
                            setFieldValue('steps.' + i + '.location', LocationEnum.Yard);
                            setFieldValue('steps.' + i + '.driverActivity', null);
                          }}
                        >Yard</Radio>
                      </Col>
                      <Col xs={'auto'}>
                        <Radio
                          disabled={disableLocation?.Landfill}
                          checked={(item.location === LocationEnum.Landfill)}
                          onChange={(e) => {
                            setFieldValue('steps.' + i + '.location', LocationEnum.Landfill);
                            setFieldValue('steps.' + i + '.driverActivity', null);
                          }}
                        >Landfill</Radio>
                      </Col>
                    </Row>
                  </Col>

                  {
                    (item.location === LocationEnum.CustomerSite)
                    ?
                    <Col xs={12} className='gry-600'><i>Specific customer location will be entered when creating a job.</i></Col>
                    :
                    <StyledStepLocation xs={12} className='shadow1 px-16'>
                      <Row className='g-8'>
                        <Col xs={12}>
                          <DropdownButton
                            variant={'custom-outlined'}
                            size={'sm'}
                            title={item.hasDefaultLocation ? 'Default Location' : 'No Default location. I will enter address when creating job'}
                          >
                            <Dropdown.Item
                              active={(item.hasDefaultLocation === true)}
                              onClick={() => {
                                setFieldValue('steps.' + i + '.hasDefaultLocation', true);
                              }}
                            >Default Location</Dropdown.Item>
                            <Dropdown.Item
                              active={(item.hasDefaultLocation === false)}
                              onClick={() => {
                                setFieldValue('steps.' + i + '.hasDefaultLocation', false);
                              }}
                            >No Default location. I will enter address when creating job</Dropdown.Item>
                          </DropdownButton>
                        </Col>
                        
                        {item.hasDefaultLocation && <>
                          <Col xs={12}>
                            <Row className='g-8'>
                              <Col xs={'auto'} md={2} lg={1}><small className="semibold gray-600">Set as default</small></Col>
                              <Col xs={'auto'} md={3} lg={4} xl={3} xxl={2}><small className="semibold gray-600">Location Name</small></Col>
                              <Col xs={true} md={true}><small className="semibold gray-600">Address</small></Col>
                              <Col xs={'auto'} md={'auto'}><small className="semibold gray-600">&nbsp;</small></Col>
                            </Row>
                          </Col>

                          <Col xs={12}><Divider /></Col>

                          <Col xs={12}>
                            <Row className='g-16'>

                              {item.templateStepSites.map((site: any, j: number) => {
                                return <Col key={'step_' + i + '_site_' + j} xs={12}>
                                  <Row className='align-items-center g-8'>
                                    <Col xs={12} md={2} lg={1} className='text-center'>
                                      <Radio
                                        disabled={false}
                                        checked={site.isDefault}
                                        onChange={(e) => {
                                          let items = _.cloneDeep(item.templateStepSites);
                                          items.forEach((itm: any) => {
                                            itm.isDefault = false;
                                          });
                                          setFieldValue('steps.' + i + '.templateStepSites', items);
                                          setFieldValue('steps.' + i + '.templateStepSites.' + j + '.isDefault', true);
                                        }}
                                      />
                                    </Col>
                                    <Col xs={12} md={3} lg={4} xl={3} xxl={2}>
                                      <Form.Group>
                                        <Form.Control
                                          className={'location-name-' + i + '-site-' + j}
                                          as={SmartAutocomplete}
                                          isInvalid={false}
                                          value={site.stepSiteName}
                                          placeholder={'Enter site name'}
                                          addText={'Add new'}
                                          apiPath={'jobtemplate/get-job-template-site-address'}
                                          fieldNames={{ value: 'jobTemplateStepSiteId', label: 'stepSiteName' }}
                                          selected={{ jobTemplateStepSiteId: site.jobTemplateStepSiteId, stepSiteName: site.stepSiteName }}
                                          hasOptionRender={false}
                                          hasFooterRender={true}
                                          searchable={true}
                                          allowClear={true}
                                          selectedIcon={false}
                                          onChangeValue={async (value: any, option: any, ref: any) => {
                                            if(option){
                                              if(option.isDefault){
                                                let items = _.cloneDeep(item.templateStepSites);
                                                items.forEach((itm: any) => {
                                                  itm.isDefault = false;
                                                });
                                                setFieldValue('steps.' + i + '.templateStepSites', items);
                                              }

                                              setFieldValue('steps.' + i + '.templateStepSites.' + j + '.jobTemplateStepSiteId', value);
                                              setFieldValue('steps.' + i + '.templateStepSites.' + j + '.stepSiteName', option.stepSiteName);
                                              setFieldValue('steps.' + i + '.templateStepSites.' + j + '.address', option.stepSiteAddress);
                                              setFieldValue('steps.' + i + '.templateStepSites.' + j + '.stepSiteAddress', option.stepSiteAddress);
                                              setFieldValue('steps.' + i + '.templateStepSites.' + j + '.stepSiteLatitude', option.stepSiteLatitude);
                                              setFieldValue('steps.' + i + '.templateStepSites.' + j + '.stepSiteLongitude', option.stepSiteLongitude);
                                              setFieldValue('steps.' + i + '.templateStepSites.' + j + '.isDefault', option.isDefault);
                                            } else {
                                              setFieldValue('steps.' + i + '.templateStepSites.' + j + '.jobTemplateStepSiteId', null);
                                              setFieldValue('steps.' + i + '.templateStepSites.' + j + '.stepSiteName', '');
                                              setFieldValue('steps.' + i + '.templateStepSites.' + j + '.address', '');
                                              setFieldValue('steps.' + i + '.templateStepSites.' + j + '.stepSiteAddress', '');
                                              setFieldValue('steps.' + i + '.templateStepSites.' + j + '.stepSiteLatitude', null);
                                              setFieldValue('steps.' + i + '.templateStepSites.' + j + '.stepSiteLongitude', null);
                                              setFieldValue('steps.' + i + '.templateStepSites.' + j + '.isDefault', false);
                                            }
                                          }}
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col xs={12} md={true}>
                                      <Form.Group>
                                        <Form.Control
                                          as={GoogleSearchAddress}
                                          autoComplete='off'
                                          placeholder={'Insert address or coordinate'}
                                          isInvalid={false}
                                          value={site.address}
                                          onPlaceSelected={(place: any) => {
                                            let obj = getAddressPlace(place);
                                            
                                            setFieldValue('steps.' + i + '.templateStepSites.' + j + '.address', obj?.address);
                                            setFieldValue('steps.' + i + '.templateStepSites.' + j + '.stepSiteAddress', obj?.address);
                                            setFieldValue('steps.' + i + '.templateStepSites.' + j + '.stepSiteLatitude', obj?.lat);
                                            setFieldValue('steps.' + i + '.templateStepSites.' + j + '.stepSiteLongitude', obj?.lng);
                                          }}
                                          onChange={(e) => {
                                            setFieldValue('steps.' + i + '.templateStepSites.' + j + '.address', e.target.value);
                                          }}
                                          onBlur={(e) => {
                                            if(e.target.value != site.stepSiteAddress){
                                              setFieldValue('steps.' + i + '.templateStepSites.' + j + '.address', '');
                                              setFieldValue('steps.' + i + '.templateStepSites.' + j + '.stepSiteAddress', '');
                                              setFieldValue('steps.' + i + '.templateStepSites.' + j + '.stepSiteLatitude', null);
                                              setFieldValue('steps.' + i + '.templateStepSites.' + j + '.stepSiteLongitude', null);
                                            }
                                          }}
                                        />
                                      </Form.Group>  
                                    </Col>

                                    {(item.templateStepSites.length > 1) && <Col xs={12} md={'auto'} className='text-center'>
                                      <Button
                                        className={'px-10 py-8'}
                                        variant={'custom-secondary-danger'}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
            
                                          dispatch(confirmSlice.show(() => {
                                            let items = _.cloneDeep(item.templateStepSites);
                                            items.splice(j, 1);
                                            setFieldValue('steps.' + i + '.templateStepSites', items);
                                            
                                            dispatch(confirmSlice.setHide());
                                            success('Deleted successfully', 'Location has been successfully deleted.');
                                          }, {
                                            title: 'Delete Location',
                                            body: 'Are you sure to delete this location? This cannot be undone',
                                            size: 'sm',
                                            cancelText: 'Cancel',
                                            cancelVariant: 'custom-outline',
                                            confirmText: 'Delete',
                                            confirmVariant: 'custom-danger',
                                          }));
                                        }}
                                      >
                                        <TrashIcon />
                                      </Button>
                                    </Col>}

                                  </Row>
                                </Col>
                              })}

                            </Row>
                          </Col>

                          <Col xs={12}><Divider /></Col>

                          <Col xs={12}>
                            <Button
                              className={'px-0'}
                              size={'sm'}
                              variant={'custom-text-primary'}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                validateForm().then((err: any) => {
                                  if(!!err.contactPersons){
                                    warning('Warning', 'You must fill in the required fields');
                                  } else {
                                    let items = _.cloneDeep(item.templateStepSites);
                                    let newItem = _.cloneDeep(initStepSite);
                                    items.push(newItem);
                                    setFieldValue('steps.' + i + '.templateStepSites', items);
                                    autoFoucus('.location-name-' + i + '-site-' + (items.length-1));
                                    scrollTo('.location-name-' + i + '-site-' + (items.length-1));
                                  }
                                })
                              }}
                            >
                              Add More Location
                            </Button>
                          </Col>
                        </>}
                      </Row>
                    </StyledStepLocation>
                  }

                  <Col xs={12}><Divider /></Col>

                  <Col xs={12}>
                    <Row className='g-12'>
                      <Col xs={12} className='gray-800'>What the driver do in this location?</Col>
                      <Col xs={12}>
                        <Row className='g-8 mb-8'>
                          <Col xs={true}>
                            <Button
                              className={'w-100'}
                              size={'sm'}
                              active={(item.driverActivity === DriverActivityEnum.PickEmptyBin)}
                              disabled={(disableDriverActivity?.PickEmptyBin)}
                              variant={'custom-label-outlined'}
                              onClick={() => {
                                setFieldValue('steps.' + i + '.driverActivity', (item.driverActivity !== DriverActivityEnum.PickEmptyBin) ? DriverActivityEnum.PickEmptyBin : null);
                              }}
                            >
                              Pick Empty Bin
                            </Button>
                          </Col>
                          <Col xs={true}>
                            <Button
                              className={'w-100'}
                              size={'sm'}
                              active={(item.driverActivity === DriverActivityEnum.PickLoadedBin)}
                              disabled={(disableDriverActivity?.PickLoadedBin)}
                              variant={'custom-label-outlined'}
                              onClick={() => {
                                setFieldValue('steps.' + i + '.driverActivity', (item.driverActivity !== DriverActivityEnum.PickLoadedBin) ? DriverActivityEnum.PickLoadedBin : null);
                              }}
                            >
                              Pick Loaded Bin
                            </Button>
                          </Col>
                          <Col xs={true}>
                            <Button
                              className={'w-100'}
                              size={'sm'}
                              active={(item.driverActivity === DriverActivityEnum.LoadWaste)}
                              disabled={(disableDriverActivity?.LoadWaste)}
                              variant={'custom-label-outlined'}
                              onClick={() => {
                                setFieldValue('steps.' + i + '.driverActivity', (item.driverActivity !== DriverActivityEnum.LoadWaste) ? DriverActivityEnum.LoadWaste : null);
                              }}
                            >
                              Load Waste
                            </Button>
                          </Col>
                          <Col xs={true}>
                            <Button
                              className={'w-100'}
                              size={'sm'}
                              active={(item.driverActivity === DriverActivityEnum.WasteDisposal)}
                              disabled={(disableDriverActivity?.WasteDisposal)}
                              variant={'custom-label-outlined'}
                              onClick={() => {
                                setFieldValue('steps.' + i + '.driverActivity', (item.driverActivity !== DriverActivityEnum.WasteDisposal) ? DriverActivityEnum.WasteDisposal : null);
                              }}
                            >
                              Unload Waste
                            </Button>
                          </Col>
                        </Row>
                        <Row className='g-8'>
                          <Col xs={true}>
                            <Button
                              className={'w-100'}
                              size={'sm'}
                              active={(item.driverActivity === DriverActivityEnum.DropEmptyBin)}
                              disabled={(disableDriverActivity?.DropEmptyBin)}
                              variant={'custom-label-outlined'}
                              onClick={() => {
                                setFieldValue('steps.' + i + '.driverActivity', (item.driverActivity !== DriverActivityEnum.DropEmptyBin) ? DriverActivityEnum.DropEmptyBin : null);
                              }}
                            >
                              Drop Empty Bin
                            </Button>
                          </Col>
                          <Col xs={true}>
                            <Button
                              className={'w-100'}
                              size={'sm'}
                              active={(item.driverActivity === DriverActivityEnum.DropLoadedBin)}
                              disabled={(disableDriverActivity?.DropLoadedBin)}
                              variant={'custom-label-outlined'}
                              onClick={() => {
                                setFieldValue('steps.' + i + '.driverActivity', (item.driverActivity !== DriverActivityEnum.DropLoadedBin) ? DriverActivityEnum.DropLoadedBin : null);
                              }}
                            >
                              Drop Loaded Bin
                            </Button>
                          </Col>
                          <Col xs={true}>
                            <Button
                              className={'w-100'}
                              size={'sm'}
                              active={(item.driverActivity === DriverActivityEnum.ExchangeBin)}
                              disabled={(disableDriverActivity?.ExchangeBin)}
                              variant={'custom-label-outlined'}
                              onClick={() => {
                                setFieldValue('steps.' + i + '.driverActivity', (item.driverActivity !== DriverActivityEnum.ExchangeBin) ? DriverActivityEnum.ExchangeBin : null);
                              }}
                            >
                              Exchange
                            </Button>
                          </Col>
                          <Col xs={true}>
                            <Button
                              className={'w-100'}
                              size={'sm'}
                              active={(item.driverActivity === DriverActivityEnum.NoActivity)}
                              disabled={(disableDriverActivity?.NoActivity)}
                              variant={'custom-label-outlined'}
                              onClick={() => {
                                setFieldValue('steps.' + i + '.driverActivity', (item.driverActivity !== DriverActivityEnum.NoActivity) ? DriverActivityEnum.NoActivity : null);
                              }}
                            >
                              Other
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {(values.steps.length > 1) && <Row className='g-16 pt-0 px-0'>
                  <Col xs={12}><Divider /></Col>
                  <Col xs={12} className='px-24'>
                    <Button
                      size={'sm'}
                      variant={'custom-secondary-danger'}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        dispatch(confirmSlice.show(() => {
                          let items = _.cloneDeep(values.steps);
                          items.splice(i, 1);
                          setFieldValue('steps', items);
                          
                          dispatch(confirmSlice.setHide());
                          success('Deleted successfully', 'Step has been successfully deleted.');
                        }, {
                          title: 'Delete Step',
                          body: 'Are you sure to delete this step? This cannot be undone',
                          size: 'sm',
                          cancelText: 'Cancel',
                          cancelVariant: 'custom-outline',
                          confirmText: 'Delete',
                          confirmVariant: 'custom-danger',
                        }));
                      }}
                    >
                      <TrashIcon /> Delete Step
                    </Button>
                  </Col>
                </Row>}
              </StyledStep>

              {vehicleBinStatus && <StyledStepStatus xs={12}>
                <Row className='g-16'>
                  {vehicleBinStatus?.vehicleStatus && <Col xs={12} md={'auto'} className='gray-900'>Vehicle status: <StyledVehicleBinStatus className={vehicleBinStatus?.vehicleStatus || ''}>{vehicleBinStatus?.vehicleStatus}</StyledVehicleBinStatus></Col>}
                  {(vehicleBinStatus?.vehicleStatus && vehicleBinStatus?.binStatus) && <Col xs={12} md={'auto'}><Divider type="vertical" /></Col>}
                  {vehicleBinStatus?.binStatus && <Col xs={12} md={'auto'} className='gray-900'>Bin status: <StyledVehicleBinStatus className={vehicleBinStatus?.binStatus || ''}>{vehicleBinStatus?.binStatus}</StyledVehicleBinStatus></Col>}
                  {vehicleBinStatus?.info && <Col xs={12} md={true} className='gray-600 text-end'><StyledInfoIcon /><span>{vehicleBinStatus?.info}</span></Col>}
                </Row>
              </StyledStepStatus>}
            </React.Fragment>
          })}
        </Row>
      </Col>

      <Col xs={12}>
        <Button
          size={'sm'}
          variant={'custom-outlined'}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            validateForm().then((err: any) => {
              if(!!err.steps){
                warning('Warning', 'You must fill in the required fields');
              } else {
                let items = _.cloneDeep(values.steps);
                let newItem = _.cloneDeep(initStep);
                items.push(newItem);
                setFieldValue('steps', items);
                scrollTo('.step-' + (items.length-1));
              }
            })
          }}
        >
          <PlusIcon /> Add Step
        </Button>
      </Col>

    </Row>
  }


  return <Row className='g-24 mb-100'>
    <Col xs={12}>
      <Row className='g-8'>
        <Col xs={12}>
          <span className="large medium gray-900">Job Steps</span>
        </Col>
        <Col xs={12}>
          <span className="gray-800">Determine how many steps for this job type, specific location on each step, and what the driver do in each location.</span>
        </Col>
      </Row>
    </Col>

    <Col xs={12} md={12} lg={10}>
      {setFormElements()}
    </Col>
  </Row>
}

export default Tab2;
