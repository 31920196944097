import React from 'react';

import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


const options = {
    options: ['inline', 'textAlign'],
    inline: {
        inDropdown: false,
        options: ['bold', 'italic', 'underline'],
    },
    textAlign: {
        options: ['left', 'center', 'right', 'justify'],
    }
};


const RTextEditor = ({ toolbar, disabled = false, ...props }) => {
    const [toolbars] = React.useState(toolbar ? toolbar : options)

      
    const onEditorStateChange = (editorState) => {
        let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        if (props.onChangeValue) {
            let plainText = editorState.getCurrentContent().getPlainText();
            props.onChangeValue(plainText, html, editorState);
        }
    }

    
    return <div className={"rich-text-editor-component " + (props.className ? props.className : '')}>
        <Editor
            {...props}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            stripPastedStyles={true}
            readOnly={disabled}
            onEditorStateChange={onEditorStateChange}
            toolbar={toolbars}
        />
    </div>
}


export default RTextEditor;