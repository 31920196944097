import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
} from 'react-bootstrap';
import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Switch, Divider } from "antd";

// Auth
// import { useAuthContext } from '../../../../../auth/use-auth-context.js';
// import { useRouter } from '../../../../../auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../../utils/store.tsx';
import slice, { useFormikContext, fields, getDriverAppOptionsStepName, getDriverAppOptionsStepInfo } from '../slice.tsx';

// enums
// import {  } from '../../../../../utils/utils.tsx';
// import {  } from '../../../../../utils/enums.tsx';

// components
// import MainContainer from '../../../../../components/container/MainContainer.jsx';

// import { ReactComponent as XIcon } from "../../../../../../v5/assets/icons/smallX.svg";

// ----------------------------------------------------------------------

const StyledStep = styled(Col)`
  border-radius: 6px;
  border: 1px solid var(--bs-gray-100);
  padding: 16px 24px;
  
  .ant-divider {
    margin-top: 4px;
    margin-bottom: 4px;
    border-color: var(--bs-gray-100);
  }
`;

const Tab4 = () => {
  // const location = useLocation();
  // const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  // const { isLoading } = useTypedSelector((state: RootState) => state.manageJobTypesSlice);
  // const dispatch = useDispatch<AppDispatch>();


  const setFormElements = () => {
    return <Row className='g-16'>

      {values.steps.map((item: any, i: number) => {
        let stepName: any = getDriverAppOptionsStepName(i, item, values.steps);
        let stepInfo: any = getDriverAppOptionsStepInfo(i, item, values.steps);
        
        return <StyledStep key={'step_tab4_' + i} xs={12}>
          <Row className='g-12'>
            <Col xs={12} className='semibold'>Step {i+1}: {stepName}</Col>
            
            {stepInfo?.ChangeBin && <Col xs={12} className='mt-16'>
              <Row className='align-items-center'>
                <Col xs={true}>The driver can change bin type on driver app</Col>
                <Col xs={'auto'}>
                  <Switch
                    size='small'
                    checked={true}
                    onChange={(checked) => {
                      console.info(`checked = ${checked}`);
                    }}
                  />
                </Col>
              </Row>
            </Col>}

            {stepInfo?.ChangeWaste && <>
              {(stepInfo?.ChangeBin) && <Col xs={12}><Divider /></Col>}
              <Col xs={12}>
                <Row className='align-items-center'>
                  <Col xs={true}>The driver can change waste type on driver app</Col>
                  <Col xs={'auto'}>
                    <Switch
                      size='small'
                      checked={true}
                      onChange={(checked) => {
                        console.info(`checked = ${checked}`);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </>}

            {stepInfo?.NewBinID && <>
              {(stepInfo?.ChangeBin || stepInfo?.ChangeWaste) && <Col xs={12}><Divider /></Col>}
              <Col xs={12} className='mt-16'>
                <Row className='align-items-center'>
                  <Col xs={true}>The driver can add new Bin ID driver app</Col>
                  <Col xs={'auto'}>
                    <Switch
                      size='small'
                      checked={true}
                      onChange={(checked) => {
                        console.info(`checked = ${checked}`);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </>}
            
          </Row>
        </StyledStep>
      })}

    </Row>
  }


  return <Row className='g-24 mb-100'>
    <Col xs={12}>
      <Row className='g-8'>
        <Col xs={12}>
          <span className="large medium gray-900">Driver Permission</span>
        </Col>
        <Col xs={12}>
          <span className="gray-800">Control how the driver will interact with their app.</span>
        </Col>
      </Row>
    </Col>

    <Col xs={12} md={10} lg={8} xl={6} xxl={5} xxxl={4}>
      {setFormElements()}
    </Col>
  </Row>
}

export default Tab4;
