/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import { axios } from '../../../../utils/axios';
import { successAPI, errorAPI } from '../../../../utils/utils';
import Yup from '../../../../utils/yup';
import { CustomerSiteAvailability } from '../../../../utils/enums';

import { fields } from '../slice';


export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    zoneId: Yup.number().nullable().label(fields.zoneId.label),
    remarks: Yup.string().label(fields.remarks.label),
    instructions: Yup.string().label(fields.instructions.label),
    contactPersonName: Yup.string().label(fields.contactPersonName.label),
    contactPersonPhoneNumber: Yup.string().nullable().label(fields.contactPersonPhoneNumber.label),
    contactPersonNameTwo: Yup.string().label(fields.contactPersonNameTwo.label),
    contactPersonPhoneNumberTwo: Yup.string().nullable().label(fields.contactPersonPhoneNumberTwo.label),
    weekdayId: Yup.array().nullable().label(fields.weekdayId.label),
    weekendId: Yup.array().nullable().label(fields.weekendId.label),
    driverId: Yup.array().nullable().label(fields.driverId.label),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(initialValues);
  
  if(data && form){
    let zone = (form && form.zone) ? form.zone : null;
    let zoneId = (zone && zone.zoneId) ? zone.zoneId : null;
    let zoneName = (zone && zone.zoneName) ? zone.zoneName : '';

    let driverId: any = [];
    let driverName: any = [];
    let defaultDrivers = (form && form.defaultDrivers) ? form.defaultDrivers : [];
    if(defaultDrivers && defaultDrivers.length > 0){
      defaultDrivers.forEach((item: any, i: number) => {
        let defaultVehicle = (item && item.defaultVehicle) ? item.defaultVehicle : null;
        let vehicleName = (defaultVehicle && defaultVehicle.vehicleName) ? defaultVehicle.vehicleName : null;
        let defDriverName = [item.driverName, vehicleName].join(' / ');

        driverId.push(item.driverId);
        driverName.push(defDriverName);
      });
    }

    let weekdayId: any = [];
    let weekdayName: any = [];
    let customerSiteWeekdayJobWorkingTimes = (form && form.customerSiteWeekdayJobWorkingTimes) ? form.customerSiteWeekdayJobWorkingTimes : [];
    if(customerSiteWeekdayJobWorkingTimes && customerSiteWeekdayJobWorkingTimes.length > 0){
      customerSiteWeekdayJobWorkingTimes.forEach((item: any, i: number) => {
        let accountJobWorkingTime = (item && item.accountJobWorkingTime) ? item.accountJobWorkingTime : null;
        let accountJobWorkingTimeId = (accountJobWorkingTime && accountJobWorkingTime.accountJobWorkingTimeId) ? accountJobWorkingTime.accountJobWorkingTimeId : null;
        let workingTimeName = (accountJobWorkingTime && accountJobWorkingTime.workingTimeName) ? accountJobWorkingTime.workingTimeName : '';
        
        weekdayId.push(accountJobWorkingTimeId);
        weekdayName.push(workingTimeName);
      });
    }
    let weekdaysAvailability = (form && form.weekdaysAvailability) ? form.weekdaysAvailability : CustomerSiteAvailability.All;

    let weekendId: any = [];
    let weekendName: any = [];
    let customerSiteWeekendJobWorkingTimes = (form && form.customerSiteWeekendJobWorkingTimes) ? form.customerSiteWeekendJobWorkingTimes : [];
    if(customerSiteWeekendJobWorkingTimes && customerSiteWeekendJobWorkingTimes.length > 0){
      customerSiteWeekendJobWorkingTimes.forEach((item: any, i: number) => {
        let accountJobWorkingTime = (item && item.accountJobWorkingTime) ? item.accountJobWorkingTime : null;
        let accountJobWorkingTimeId = (accountJobWorkingTime && accountJobWorkingTime.accountJobWorkingTimeId) ? accountJobWorkingTime.accountJobWorkingTimeId : null;
        let workingTimeName = (accountJobWorkingTime && accountJobWorkingTime.workingTimeName) ? accountJobWorkingTime.workingTimeName : '';
        
        weekendId.push(accountJobWorkingTimeId);
        weekendName.push(workingTimeName);
      });
    }
    let weekendAvailability = (form && form.weekendAvailability) ? form.weekendAvailability : CustomerSiteAvailability.All;

    let remarks = (form.remarks && form.remarks !== '') ? form.remarks : '';
    let instructions = (form.instructions && form.instructions !== '') ? form.instructions : '';
    let contactPersonName = (form.contactPersonName && form.contactPersonName !== '') ? form.contactPersonName : '';
    let contactPersonPhoneNumber = (form.contactPersonPhoneNumber && form.contactPersonPhoneNumber !== '') ? form.contactPersonPhoneNumber : '';
    let contactPersonNameTwo = (form.contactPersonNameTwo && form.contactPersonNameTwo !== '') ? form.contactPersonNameTwo : '';
    let contactPersonPhoneNumberTwo = (form.contactPersonPhoneNumberTwo && form.contactPersonPhoneNumberTwo !== '') ? form.contactPersonPhoneNumberTwo : '';


    data['zoneId'] = zoneId;
    data['zoneName'] = zoneName;
    data['driverId'] = driverId;
    data['driverName'] = driverName;
    data['weekdayId'] = weekdayId;
    data['weekdayName'] = weekdayName;
    data['weekendId'] = weekendId;
    data['weekendName'] = weekendName;
    data['weekdaysAvailability'] = weekdaysAvailability;
    data['weekendAvailability'] = weekendAvailability;

    data['remarks'] = remarks;
    data['instructions'] = instructions;
    data['contactPersonName'] = contactPersonName;
    data['contactPersonPhoneNumber'] = contactPersonPhoneNumber;
    data['contactPersonNameTwo'] = contactPersonNameTwo;
    data['contactPersonPhoneNumberTwo'] = contactPersonPhoneNumberTwo;
  }
  
  return data;
};
export const prepareData = (values: any = null, ids: any = null) => {
  let data: any = {};

  let form = _.cloneDeep(values);

  if(form){
    if(ids && ids != ''){
      data['ids'] = ids;
    }
    
    let driverIds = (form && form.driverId && form.driverId.length > 0) ? form.driverId.join(',') : '';
    let accountWeekdayJobWorkingTimeIds = (form && form.weekdayId && form.weekdayId.length > 0) ? form.weekdayId.join(',') : '';
    let accountWeekendJobWorkingTimeIds = (form && form.weekendId && form.weekendId.length > 0) ? form.weekendId.join(',') : '';

    let weekdaysAvailability = (form && form.weekdaysAvailability) ? form.weekdaysAvailability : CustomerSiteAvailability.All;
    let weekendAvailability = (form && form.weekendAvailability) ? form.weekendAvailability : CustomerSiteAvailability.All;

    data['remarks'] = form.remarks;
    data['instructions'] = form.instructions;
    data['contactPersonName'] = form.contactPersonName;
    data['contactPersonPhoneNumber'] = form.contactPersonPhoneNumber;
    data['contactPersonNameTwo'] = form.contactPersonNameTwo;
    data['contactPersonPhoneNumberTwo'] = form.contactPersonPhoneNumberTwo;

    data['driverIds'] = driverIds;
    data['accountWeekdayJobWorkingTimeIds'] = (weekdaysAvailability === CustomerSiteAvailability.SelectedHours) ? accountWeekdayJobWorkingTimeIds : (weekdaysAvailability === CustomerSiteAvailability.All) ? '' : null;
    data['accountWeekendJobWorkingTimeIds'] = (weekendAvailability === CustomerSiteAvailability.SelectedHours) ? accountWeekendJobWorkingTimeIds : (weekendAvailability === CustomerSiteAvailability.All) ? '' : null;
    data['weekdaysAvailability'] = weekdaysAvailability;
    data['weekendAvailability'] = weekendAvailability;

    if(form.zoneId !== 0){
      data['zoneId'] = form.zoneId;
    }
  }

  return data;
};


export interface initialValuesStruct {
  remarks: string,
  instructions: string,
  contactPersonName: string,
  contactPersonPhoneNumber: string,
  contactPersonNameTwo: string,
  contactPersonPhoneNumberTwo: string,
  
  zoneId: any,
  zoneName: string,

  driverId: Array<any>,
  driverName: Array<any>,
  
  weekdayId: Array<any>,
  weekdayName: Array<any>,

  weekendId: Array<any>,
  weekendName: Array<any>,

  weekdaysAvailability: any,
  weekendAvailability: any,
};
export const initialValues: initialValuesStruct = {
  remarks: '',
  instructions: '',
  contactPersonName: '',
  contactPersonPhoneNumber: '',
  contactPersonNameTwo: '',
  contactPersonPhoneNumberTwo: '',
  
  zoneId: null,
  zoneName: '',

  driverId: [],
  driverName: [],
  
  weekdayId: [],
  weekdayName: [],

  weekendId: [],
  weekendName: [],

  weekdaysAvailability: CustomerSiteAvailability.All,
  weekendAvailability: CustomerSiteAvailability.All,
};


interface InitState {
  isLoading: boolean,
  show: boolean,
  ids: any|null,
  details: any,
}

function NewReducer() {
  const name = 'customersSiteBulkEditSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    ids: null,
    details: initialValues,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, ids: any|null}>) => {
      state.ids = action.payload.ids;
      state.show = action.payload.show;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startUpdate: (state: InitState) => {
      state.isLoading = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },
  };


  const apis = {
    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startUpdate());

      await axios.put('customerSite/bulk', params).then(result => {
        let data = result.data;
        
        successAPI(data);

        let obj = (data && data.data) ? data.data : null;
        callback(true, obj);
        dispatch(actions.finishUpdate(obj));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishUpdate(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();