import React from 'react';

import { 
  Checkbox,
  Spin,
} from 'antd';


const DataTableVisibility = ({ dataTable, onResetColumns, isSavedCookie, isLoadingSaveCookie }: any) => {
  
    return <div style={{ minWidth: '200px' }}>
      <Spin spinning={isLoadingSaveCookie} wrapperClassName={'no-height'}>
        
        {/* <div className='px-3 pt-2'>
          <div className={'xsmall semibold'}>Fixed columns</div>
          <label>
            <input
              {...{
                type: 'checkbox',
                checked: dataTable?.table.getIsAllColumnsVisible(),
                onChange: dataTable?.table.getToggleAllColumnsVisibilityHandler(),
              }}
            />{' '}
            Toggle All
          </label>
        </div>
    
        <div><hr /></div> */}

        {(isSavedCookie) && 
          <div className='px-3 pt-2 text-end'>
            <a href={"/"} 
              className={'medium hover ' + (isLoadingSaveCookie ? 'text-secondary' : 'text-danger')}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                
                if(onResetColumns){
                  onResetColumns();
                }
              }}
            >Reset Columns</a>
          </div>}
        
        {(isSavedCookie) && <div><hr className='mt-2' /></div>}

        <div className='px-3 pt-2'>
          <div className={'xsmall semibold mb-1'} style={{ color: 'var(--bs-gray-800)' }}>Fixed columns</div>
          {dataTable?.table.getVisibleLeafColumns().map((column: any) => {
            if(((column.id != 'mrt-row-select') && (column.id != 'mrt-row-expand') && (column.columnDef.header != '') && (column.id != 'actions')) && !column.getCanHide()){
              return <div key={'non_sortable_cols_' + column.id}>
                <label style={{ color: 'var(--bs-gray-600)' }}>
                  {column.columnDef.header}
                </label>
              </div>
            }
          })}
        </div>
    
        <div><hr /></div>
    
        <div className='px-3'>
          <div className={'xsmall semibold mb-1'} style={{ color: 'var(--bs-gray-800)' }}>Active columns</div>
          <div style={{ position: 'relative', maxHeight: '150px', overflow: 'auto' }}>
            {dataTable?.table.getVisibleLeafColumns().map((column: any) => {
              if(((column.id != 'mrt-row-select') && (column.id != 'mrt-row-expand') && (column.id != 'actions')) && column.getCanHide()){
                return <div key={'visible_' + column.id} className='py-1'>
                  <Checkbox
                    disabled={!column.getCanHide()}
                    checked={column.getIsVisible()}
                    onChange={column.getToggleVisibilityHandler()}
                  >{column.columnDef.header}</Checkbox>
                </div>
              }
            })}
            {!dataTable?.table.getVisibleLeafColumns().some((x: any) => x.getCanHide() && x.getIsVisible()) && <>No active columns</>}
          </div>
        </div>
    
        <div><hr /></div>
    
        <div className='px-3 pb-2'>
          <div className={'xsmall semibold mb-1'} style={{ color: 'var(--bs-gray-800)' }}>Available columns</div>
          <div style={{ position: 'relative', maxHeight: '150px', overflow: 'auto' }}>
            {dataTable?.table.getAllFlatColumns().map((column: any) => {
              if(((column.id != 'mrt-row-select') && (column.id != 'mrt-row-expand') && (column.id != 'actions')) && !column.getIsVisible()) {
                return <div key={'all_col_' + column.id} className='py-1'>
                  <Checkbox
                    disabled={!column.getCanHide()}
                    checked={column.getIsVisible()}
                    onChange={column.getToggleVisibilityHandler()}
                  >{column.columnDef.header}</Checkbox>
                </div>
              }
            })}
            {dataTable?.table.getIsAllColumnsVisible() && <>No available columns</>}
          </div>
        </div>

      </Spin>
    </div>
  };


  export default DataTableVisibility