/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';
import h2p from 'html2plaintext';

import { axios } from '../../../../utils/axios';
import { successAPI, errorAPI, placeholderRows, createEditorState } from '../../../../utils/utils';
import Yup from '../../../../utils/yup';
// import {  } from '../../../../utils/enums';


export const fields = {
  creditLimit: {
    id: 'creditLimit',
    label: 'Credit limit',
    placeholder: 'Placeholder',
  },
  isActive: {
    id: 'isActive',
    label: 'Is active',
    placeholder: '',
  },
  customerSites: {
    id: 'customerSites',
    label: 'Mailing list',
    placeholder: 'Placeholder',
  },
  isAutoSendDoDoc: {
    id: 'isAutoSendDoDoc',
    label: 'Select all',
    placeholder: '',
  },
  subject: {
    id: 'subject',
    label: 'Subject',
    placeholder: '',
  },
  fromName: {
    id: 'fromName',
    label: 'From',
    placeholder: '',
  },
  toName: {
    id: 'toName',
    label: 'To',
    placeholder: '',
  },
  recipientCc: {
    id: 'recipientCc',
    label: 'CC copy',
    placeholder: '',
  },
  message: {
    id: 'message',
    label: 'Message',
    placeholder: placeholderRows(5, '', ''),
    rows: 5,
  },
};


export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    subject: Yup.string().required().label(fields.subject.label),
    fromName: Yup.string().label(fields.fromName.label),
    toName: Yup.array().of(Yup.string().min(1).label(fields.toName.label)),
    recipientCc: Yup.string().label(fields.recipientCc.label),
    message: Yup.string().required().label(fields.message.label),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null, obj: any = null) => {
  let form = _.cloneDeep(values);
  let data: any = _.cloneDeep(initialValues);
  
  if(data && form){
    let toNameItems: any = [];
    let customerSiteItems: any = [];
    let selectedCustomerSites: any = [];
    let customerSitesIsSelectAll = false;

    let includePdfAttachmentDeliveryOrder = false;
    let includePdfAttachmentPhoto = false;
    let includePdfAttachmentWeightChit = false;

    let subject = '';
    let fromName = '';
    let toValue: any = [];
    let toName: any = [];
    let recipientCc = '';
    let message = '';

    let customerSites = (obj && obj.customerSites) ? obj.customerSites : [];
    if(customerSites && customerSites.length > 0){
      customerSites.forEach((item: any) => {
        customerSiteItems.push({
          value: item.customerSiteId,
          label: item.siteNameDisplay,
          isAutoSendDoDoc: item.isAutoSendDoDoc,
          item: item,
        });

        if(item.isAutoSendDoDoc){
          selectedCustomerSites.push(item.customerSiteId);
        }
      })
    }
    customerSitesIsSelectAll = (customerSiteItems.length === selectedCustomerSites.length);

    if(obj && obj.emailAddresses && obj.emailAddresses != ''){
      toNameItems.push({
        value: obj.emailAddresses,
        label: obj.emailAddresses,
        item: { emailAddress: obj.emailAddresses },
      });
    }

    let contactPersons = (obj && obj.contactPersons) ? obj.contactPersons : [];
    if(contactPersons && contactPersons.length > 0){
      contactPersons.forEach((item: any) => {
        if(item.emailAddress != '')
          toNameItems.push({
            value: item.customerContactPersonId,
            label: item.emailAddress,
            item: item,
          });
      })
    }
    toValue = (toNameItems && toNameItems.length > 0) ? toNameItems.filter((x: any) => x.label == obj.emailAddresses).map((x: any) => x.value) : [];
    toName = (toNameItems && toNameItems.length > 0) ? toNameItems.filter((x: any) => x.label == obj.emailAddresses).map((x: any) => x.label) : [];

    let emailTemplate = (obj && obj.emailTemplate) ? obj.emailTemplate : null;
    
    if(emailTemplate){
      includePdfAttachmentDeliveryOrder = ((emailTemplate.includePdfAttachmentDeliveryOrder === false) || (emailTemplate.includePdfAttachmentDeliveryOrder === true)) ? emailTemplate.includePdfAttachmentDeliveryOrder : false;
      includePdfAttachmentPhoto = ((emailTemplate.includePdfAttachmentPhoto === false) || (emailTemplate.includePdfAttachmentPhoto === true)) ? emailTemplate.includePdfAttachmentPhoto : false;
      includePdfAttachmentWeightChit = ((emailTemplate.includePdfAttachmentWeightChit === false) || (emailTemplate.includePdfAttachmentWeightChit === true)) ? emailTemplate.includePdfAttachmentWeightChit : false;
    }

    subject = (emailTemplate && emailTemplate.subject && emailTemplate.subject != '') ? emailTemplate.subject : '';
    fromName = (emailTemplate && emailTemplate.fromName && emailTemplate.fromName != '') ? emailTemplate.fromName : '';
    recipientCc = (emailTemplate && emailTemplate.recipientCc && emailTemplate.recipientCc != '') ? emailTemplate.recipientCc : '';
    message = (emailTemplate && emailTemplate.message && emailTemplate.message != '') ? h2p(emailTemplate.message) : '';

    let messageHTML = message;
    let messagePlainText = h2p(message);
    let messageEditorState = createEditorState(message);


    data['isLoadingSingleCustomerSite'] = false;
    data['includePdfAttachmentDeliveryOrder'] = includePdfAttachmentDeliveryOrder;
    data['includePdfAttachmentPhoto'] = includePdfAttachmentPhoto;
    data['includePdfAttachmentWeightChit'] = includePdfAttachmentWeightChit;
    data['isAutoSendDoDoc'] = customerSitesIsSelectAll;
    data['autoSendDoDocType'] = 0;
    data['autoSendDoDocHoursLater'] = null;
    data['customerSiteItems'] = customerSiteItems;
    data['selectedCustomerSites'] = selectedCustomerSites;
    data['customerSitesIsSelectAll'] = customerSitesIsSelectAll;
    data['toNameItems'] = toNameItems;

    data['subject'] = subject;
    data['fromName'] = fromName;
    data['toValue'] = toValue;
    data['toName'] = toName;
    data['recipientCc'] = recipientCc;
    data['message'] = message;
    data['messageHTML'] = messageHTML;
    data['messagePlainText'] = messagePlainText;
    data['messageEditorState'] = messageEditorState;
  }
  
  return data;
};
export const prepareData = (values: any = null, ids: any = null) => {
  let data: any = {};

  let form = _.cloneDeep(values);

  if(form){
    if(ids && ids != ''){
      data['ids'] = ids;
    }
    let isAutoSendDoDoc = ((form.isAutoSendDoDoc === false) || (form.isAutoSendDoDoc === true)) ? form.isAutoSendDoDoc : false;
    let autoSendDoDocType = (form && form.autoSendDoDocType && form.autoSendDoDocType != '') ? form.autoSendDoDocType : 0;
    let autoSendDoDocHoursLater = (form && form.autoSendDoDocHoursLater && form.autoSendDoDocHoursLater != '') ? form.autoSendDoDocHoursLater : null;

    let toName = (form.toName && form.toName.length > 0) ? form.toName : [];
    let contactEmails = (toName && toName.length > 0) ? toName.map((x: any) => {
      if(typeof x == 'string'){
        let itm = null;
        let filteredItem = form.toNameItems.filter((item: any) => item.value == x);
        if(filteredItem && filteredItem.length > 0){
          itm = filteredItem[0];
        }

        if(itm && itm.item){
          //Email from customer site
          return { 
            emailAddress: x,
          }
        } else {
          //New email address
          return { 
            customerContactPerson: {
              emailAddress: x
            }
          }
        }
      } else {
        //Email from contact persons
        let emailAddress = '';
        let filteredItem = form.toNameItems.filter((item: any) => item.value == x);
        if(filteredItem && filteredItem.length > 0){
          let itm = filteredItem[0];
          emailAddress = itm.label;
        }
        return { 
          customerContactPerson: {
            emailAddress: emailAddress
          }
        }
        // return { customerContactPersonId: x }
      }
    }) : [];

    data['contactEmails'] = contactEmails;
    data['isAutoSendDoDoc'] = isAutoSendDoDoc;
    data['autoSendDoDocType'] = autoSendDoDocType;
    data['autoSendDoDocHoursLater'] = autoSendDoDocHoursLater;
  }

  return data;
};


export interface initialValuesStruct {
  isLoadingSingleCustomerSite: boolean,
  includePdfAttachmentDeliveryOrder: boolean,
  includePdfAttachmentPhoto: boolean,
  includePdfAttachmentWeightChit: boolean,
  isAutoSendDoDoc: boolean,
  customerSitesIsSelectAll: boolean,
  autoSendDoDocType: any,
  autoSendDoDocHoursLater: any,
  selectedCustomerSites: Array<any>,
  
  subject: string,
  fromName: string,
  toName: Array<any>,
  recipientCc: string,
  message: any,
  messageHTML: any,
  messagePlainText: any,
  messageEditorState: any,
};
export const initialValues: initialValuesStruct = {
  isLoadingSingleCustomerSite: false,
        
  includePdfAttachmentDeliveryOrder: false,
  includePdfAttachmentPhoto: false,
  includePdfAttachmentWeightChit: false,

  isAutoSendDoDoc: false,
  autoSendDoDocType: 0,
  autoSendDoDocHoursLater: null,
  
  selectedCustomerSites: [],
  customerSitesIsSelectAll: false,

  subject: '',
  fromName: '',
  toName: [],
  recipientCc: '',
  message: '',
  messageHTML: '',
  messagePlainText: '',
  messageEditorState: '',
};


interface InitState {
  isLoading: boolean,
  show: boolean,
  id: any|null,
  obj: any|null,
  details: any,
}

function NewReducer() {
  const name = 'customersCreditLimitSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    id: null,
    obj: null,
    details: initialValues,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, id: any|null, obj: any|null }>) => {
      state.id = action.payload.id;
      state.obj = action.payload.obj;
      state.show = action.payload.show;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startUpdate: (state: InitState) => {
      state.isLoading = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },

    startDetails: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
      state.isLoading = false;
    },
  };


  const apis = {
    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startUpdate());

      await axios.put('customerSite/bulk', params).then(result => {
        let data = result.data;
        
        successAPI(data);

        let obj = (data && data.data) ? data.data : null;
        callback(true, obj);
        dispatch(actions.finishUpdate(obj));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishUpdate(null));
      });
    },

    callDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetails());
  
        await axios.get('customerSite/' + id).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDetails(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDetails(null));
        });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();