import _ from 'lodash';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';

// Convert ----------------------------------------------------------------------

export const convertMinToHours = (min: any = null) => {
  let numb = 0;

  if(min){
    try {
      numb = (parseInt(min, 10) / 60);
    } catch (e) {
      numb = 0;
    }
  } else {
    numb = 0;
  }

  return numb;
}
export const convertHoursToMin = (h: any = null) => {
  let numb = 0;

  if(h){
    try {
      numb = (parseFloat(h) * 60);
    } catch (e) {
      numb = 0;
    }
  } else {
    numb = 0;
  }

  return numb;
}

export const convertJsonFromString = (data: any = null) => {
  let item = [];

  if (data && data !== '') {
    try {
      item = JSON.parse(data);
    } catch (e) { }
  }

  return item;
}

export const createEditorState = (html: any) => {
  if (html && html !== '') {
      const contentBlock = htmlToDraft(html);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
  } else {
      return EditorState.createEmpty();
  }
}