import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';

import liveSlice from './slices/liveSlice';

import confirmSlice from '../components/modal/confirmSlice';
import downloadSlice from '../components/download/downloadSlice';
import createGenericSlice from '../components/table/withSlice/tableSlice';


import alertsSlice from '../pages/alerts/slice';
import auditTrailsSlice from '../pages/auditTrails/slice';
import remindersSlice from '../pages/reminders/slice';

import customerLayoutSlice from '../layouts/customer/slice';
import manageLayoutSlice from '../layouts/manage/slice';

import customersSlice from '../pages/customers/customer/slice';
import customersBulkEditSlice from '../pages/customers/customer/bulkEdit/slice';
import customersCreditLimitSlice from '../pages/customers/customer/creditLimit/slice';
import customersOverviewSlice from '../pages/customers/customer/overview/slice';
import customersSiteSlice from '../pages/customers/customerSite/slice';
import customersSiteBulkEditSlice from '../pages/customers/customerSite/bulkEdit/slice';
import customersSiteChangeLocationSlice from '../pages/customers/customerSite/changeLocation/slice';
import customersSiteEmailTemplateSlice from '../pages/customers/customerSite/emailTemplate/slice';
import customersSiteCreateNewEmailSlice from '../pages/customers/customerSite/createNewEmail/slice';

import manageJobTypesSlice from '../pages/manage/jobs/jobTypes/slice';


export const Tables = {
  Customers: 'tableCustomers',
  CustomersSite: 'tableCustomersSite',
  CustomersEmail: 'tableCustomersEmail',
  Alerts: 'tableAlerts',
  AuditTrails: 'tableAuditTrails',
  Reminders: 'tableReminders',
  JobTypes: 'tableJobTypes',
}


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;


const store = configureStore({
  reducer: {
    liveSlice: liveSlice.reducer,

    confirmSlice: confirmSlice.reducer,
    downloadSlice: downloadSlice.reducer,

    tableAlerts: createGenericSlice<any>(Tables.Alerts).reducer,
    tableAuditTrails: createGenericSlice<any>(Tables.AuditTrails).reducer,
    tableReminders: createGenericSlice<any>(Tables.Reminders).reducer,
    tableCustomers: createGenericSlice<any>(Tables.Customers).reducer,
    tableCustomersSite: createGenericSlice<any>(Tables.CustomersSite).reducer,
    tableCustomersEmail: createGenericSlice<any>(Tables.CustomersEmail).reducer,
    tableJobTypes: createGenericSlice<any>(Tables.JobTypes).reducer,
    
    alertsSlice: alertsSlice.reducer,
    auditTrailsSlice: auditTrailsSlice.reducer,
    remindersSlice: remindersSlice.reducer,
  
    customerLayoutSlice: customerLayoutSlice.reducer,
    manageLayoutSlice: manageLayoutSlice.reducer,
    
    customersSlice: customersSlice.reducer,
    customersBulkEditSlice: customersBulkEditSlice.reducer,
    customersCreditLimitSlice: customersCreditLimitSlice.reducer,
    customersOverviewSlice: customersOverviewSlice.reducer,
    customersSiteSlice: customersSiteSlice.reducer,
    customersSiteBulkEditSlice: customersSiteBulkEditSlice.reducer,
    customersSiteChangeLocationSlice: customersSiteChangeLocationSlice.reducer,
    customersSiteEmailTemplateSlice: customersSiteEmailTemplateSlice.reducer,
    customersSiteCreateNewEmailSlice: customersSiteCreateNewEmailSlice.reducer,

    manageJobTypesSlice: manageJobTypesSlice.reducer,
  },
});

export { store };

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppDispatch = () => useDispatch<AppDispatch>();
