import React from "react";
import _ from 'lodash';

import { 
    Row,
    Col,
    Form,
    Spinner,
    Button,
} from "react-bootstrap-v5";

import { DrawerContentLayout } from '../../../components/drawer/DrawerLayout'
import Utils from "../../../utils/utils";
// import { PagesPermissions, RolesPermissions } from "../../../utils/enums";

import RSuiteCheckTreePicker from '../../../components/OLD/Tree/RSuiteCheckTreePicker';

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { commonProfileApiCallGet } from '../../../../setup/redux/commonProfile/actions'


class RolesForm extends React.Component {
  
    constructor(props) {
        super(props);
    
        this.pageName = 'roles_form';

        this.bodyRef = React.createRef();

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        
        this.state = {
            id: id,
            row: row,
            isLoading: false,

            pages: [],

            // pages: [
            //     {
            //         label: "Dashboard",
            //         value: RolesPermissions.Dashboard,
            //         main: null,
            //         items: [
            //             PagesPermissions.Dashboard.read,
            //         ],
            //     },
            //     {
            //         label: "Map",
            //         value: RolesPermissions.Map,
            //         main: null,
            //         items: [
            //             PagesPermissions.Map.read,
            //         ],
            //     },
            //     {
            //         label: "Customers",
            //         value: RolesPermissions.Customer,
            //         main: null,
            //         items: [
            //             PagesPermissions.Customers.read,
            //             PagesPermissions.CustomerSites.read,
            //             PagesPermissions.CustomerServices.read,
            //             PagesPermissions.CustomersOverview.read,
            //         ],
            //         children: [
            //             {
            //                 label: "Customer table",
            //                 value: RolesPermissions.Customers,
            //                 main: null,
            //                 items: [
            //                     PagesPermissions.Customers.read,
            //                 ],
            //             },
            //             {
            //                 label: "Overview",
            //                 value: RolesPermissions.CustomersOverview,
            //                 main: null,
            //                 items: [
            //                     PagesPermissions.CustomersOverview.read,
            //                 ],
            //             },
            //             {
            //                 label: "Local service",
            //                 value: RolesPermissions.CustomersServices,
            //                 main: null,
            //                 items: [
            //                     PagesPermissions.CustomerServices.read,
            //                 ],
            //             },
            //             {
            //                 label: "Sites",
            //                 value: RolesPermissions.CustomersSites,
            //                 main: null,
            //                 items: [
            //                     PagesPermissions.CustomerSites.read,
            //                 ],
            //             },
            //         ],
            //     },
            //     {
            //         label: "Jobs table",
            //         value: RolesPermissions.Jobs,
            //         main: PagesPermissions.Jobs.read,
            //         items: [
            //             PagesPermissions.JobDetails.read,
            //             PagesPermissions.JobProofOfDelivery.read,
            //             PagesPermissions.JobAuditTrail.read,
            //             PagesPermissions.JobBilling.read,
            //         ],
            //       children: [
            //         {
            //             label: "Job form",
            //             value: RolesPermissions.JobsJobForm,
            //             main: PagesPermissions.Jobs.read,
            //             items: [
            //                 PagesPermissions.JobDetails.read,
            //             ],
            //         },
            //         {
            //             label: "Proof of delivery",
            //             value: RolesPermissions.JobsProofOfDelivery,
            //             main: PagesPermissions.Jobs.read,
            //             items: [
            //                 PagesPermissions.JobProofOfDelivery.read,
            //             ],
            //         },
            //         {
            //             label: "Audit trails",
            //             value: RolesPermissions.JobsAuditTrails,
            //             main: PagesPermissions.Jobs.read,
            //             items: [
            //                 PagesPermissions.JobAuditTrail.read,
            //             ],
            //         },
            //         {
            //             label: "Job billing",
            //             value: RolesPermissions.JobsJobBilling,
            //             main: PagesPermissions.Jobs.read,
            //             items: [
            //                 PagesPermissions.JobBilling.read,
            //             ],
            //         },
            //       ],
            //     },
            //     {
            //         label: "Billing table",
            //         value: RolesPermissions.Billing,
            //         main: null,
            //         items: [
            //           PagesPermissions.BillingInvoice.read,
            //           PagesPermissions.BillingPurchase.read,
            //           PagesPermissions.BillingCategory.read,
            //         ],
            //         children: [
            //             {
            //                 label: "Billing Invoice",
            //                 value: RolesPermissions.BillingInvoice,
            //                 main: null,
            //                 items: [
            //                   PagesPermissions.BillingInvoice.read,
            //                 ],
            //             },
            //             {
            //                 label: "Billing Purchase",
            //                 value: RolesPermissions.BillingPurchase,
            //                 main: null,
            //                 items: [
            //                   PagesPermissions.BillingPurchase.read,
            //                 ],
            //             },
            //             {
            //                 label: "Billing Category",
            //                 value: RolesPermissions.BillingCategory,
            //                 main: null,
            //                 items: [
            //                   PagesPermissions.BillingCategory.read,
            //                 ],
            //             },
            //         ],
            //     },
            //     {
            //         label: "Global Service",
            //         value: RolesPermissions.GlobalService,
            //         main: null,
            //         items: [
            //             PagesPermissions.GlobalService.read,
            //         ],
            //     },
            //     {
            //         label: "Manage",
            //         value: RolesPermissions.Manage,
            //         main: PagesPermissions.Manage.read,
            //         items: [
            //             PagesPermissions.ManageDrivers.read,
            //             PagesPermissions.ManageVehicles.read,
            //             PagesPermissions.ManageWaste.read,
            //             PagesPermissions.ManageBins.read,
            //             PagesPermissions.ManageJobtemplate.read,
            //             PagesPermissions.ManageService.read,
            //             PagesPermissions.ManageTime.read,
            //             PagesPermissions.ManagePayroll.read,
            //             PagesPermissions.ManageWorkHours.read,
            //             PagesPermissions.ManageUsers.read,
            //             PagesPermissions.ManageCompany.read,
            //             PagesPermissions.ManageGeneral.read,
            //             PagesPermissions.ManageSubscription.read,
            //             PagesPermissions.ManageHoliday.read,
            //         ],
            //         children: [
            //             {
            //                 label: "Resources",
            //                 value: RolesPermissions.ManageResources,
            //                 main: PagesPermissions.Manage.read,
            //                 items: [
            //                     PagesPermissions.ManageDrivers.read,
            //                     PagesPermissions.ManageVehicles.read,
            //                     PagesPermissions.ManageWaste.read,
            //                     PagesPermissions.ManageBins.read,
            //                 ],
            //             },
            //             {
            //                 label: "Jobs",
            //                 value: RolesPermissions.ManageJobs,
            //                 main: PagesPermissions.Manage.read,
            //                 items: [
            //                     PagesPermissions.ManageJobtemplate.read,
            //                     PagesPermissions.ManageService.read,
            //                     PagesPermissions.ManageTime.read,
            //                 ],
            //             },
            //             {
            //                 label: "Payroll",
            //                 value: RolesPermissions.ManagePayroll,
            //                 main: PagesPermissions.Manage.read,
            //                 items: [
            //                     PagesPermissions.ManagePayroll.read,
            //                     PagesPermissions.ManageWorkHours.read,
            //                 ],
            //             },
            //             {
            //                 label: "Users",
            //                 value: RolesPermissions.ManageUsers,
            //                 main: PagesPermissions.Manage.read,
            //                 items: [
            //                     PagesPermissions.ManageUsers.read,
            //                 ],
            //             },
            //             {
            //                 label: "Profile",
            //                 value: RolesPermissions.ManageProfile,
            //                 main: PagesPermissions.Manage.read,
            //                 items: [
            //                     PagesPermissions.ManageCompany.read,
            //                 ],
            //             },
            //             {
            //                 label: "Settings",
            //                 value: RolesPermissions.ManageSettings,
            //                 main: PagesPermissions.Manage.read,
            //                 items: [
            //                     PagesPermissions.ManageGeneral.read,
            //                     PagesPermissions.ManageSubscription.read,
            //                     PagesPermissions.ManageHoliday.read,
            //                 ],
            //             },
            //         ],
            //     },
            //     {
            //         label: "Reports",
            //         value: RolesPermissions.Reports,
            //         main: PagesPermissions.Reports.read,
            //         items: [
            //             PagesPermissions.ReportsBins.read,
            //             PagesPermissions.ReportsBinsActivities.read,
            //             PagesPermissions.ReportsCollectionSummary.read,
            //             PagesPermissions.ReportsCollectionDetailed.read,
            //             PagesPermissions.ReportsPurchaseDetailed.read,
            //             PagesPermissions.ReportsDriverTripPay.read,
            //             PagesPermissions.ReportsCustomerServices.read,
            //         ],
            //         children: [
            //             {
            //                 label: "Bins on site",
            //                 value: RolesPermissions.ReportsBinsOnSite,
            //                 main: PagesPermissions.Reports.read,
            //                 items: [
            //                     PagesPermissions.ReportsBins.read,
            //                 ],
            //             },
            //             {
            //                 label: "Bins activities",
            //                 value: RolesPermissions.ReportsBinsActivities,
            //                 main: PagesPermissions.Reports.read,
            //                 items: [
            //                     PagesPermissions.ReportsBinsActivities.read,
            //                 ],
            //             },
            //             {
            //                 label: "Collection report (summary)",
            //                 value: RolesPermissions.ReportsCollectionSummary,
            //                 main: PagesPermissions.Reports.read,
            //                 items: [
            //                     PagesPermissions.ReportsCollectionSummary.read,
            //                 ],
            //             },
            //             {
            //                 label: "Collection report (detailed)",
            //                 value: RolesPermissions.ReportsCollectionDetailed,
            //                 main: PagesPermissions.Reports.read,
            //                 items: [
            //                     PagesPermissions.ReportsCollectionDetailed.read,
            //                 ],
            //             },
            //             {
            //                 label: "Purchase report (detailed)",
            //                 value: RolesPermissions.ReportsPurchaseDetailed,
            //                 main: PagesPermissions.Reports.read,
            //                 items: [
            //                     PagesPermissions.ReportsPurchaseDetailed.read,
            //                 ],
            //             },
            //             {
            //                 label: "Driver trip pay",
            //                 value: RolesPermissions.ReportsDriverTripPay,
            //                 main: PagesPermissions.Reports.read,
            //                 items: [
            //                     PagesPermissions.ReportsDriverTripPay.read,
            //                 ],
            //             },
            //             {
            //                 label: "Customer services",
            //                 value: RolesPermissions.ReportsCustomerServices,
            //                 main: PagesPermissions.Reports.read,
            //                 items: [
            //                     PagesPermissions.ReportsCustomerServices.read,
            //                 ],
            //             },
            //         ],
            //     },
            //     {
            //         label: "Audit trail",
            //         value: RolesPermissions.AuditTrail,
            //         main: null,
            //         items: [
            //             PagesPermissions.AuditTrail.read,
            //         ],
            //     },
            // ],
        };
    }


    componentDidMount() {
        this.callGetPermissionsApi();
    }
    
    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }
    

    /* API */
    reduxProps = nextProps => {
		Utils.reduxProps(nextProps,
            this.pageName + '-get_permissions', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let permissions = (data && data.length > 0) ? data : [];
                let pages = Utils.permissionsToPageTree(permissions);

                this.setState({
                    pages: pages,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if(callback){
                        callback();
                    }
                });
            }
        );
    }

    callGetPermissionsApi = (callback = null) => {
        this.setState({
            isLoading: true,
        });

		this.props.commonProfileApiCallGet(null, this.pageName + '-get_permissions', 'account/get-permissions', null, callback, () => {});
	}
    /* END API */


    /* FUNCTIONS */
    onTreeChange = (activeNode = null) => {
        let {
            values,
        } = this.props.formOptions;

        let permissions = values.selectedPermissions;

        if(activeNode.check){
            if(activeNode.items && activeNode.items.length > 0){
                activeNode.items.forEach((x) => {
                    permissions = Utils.addToArr(permissions, x);
                });
            }

            if(activeNode.main){
                permissions = Utils.addToArr(permissions, activeNode.main);
            }
        } else {
            if(activeNode.items && activeNode.items.length > 0){
                activeNode.items.forEach((x) => {
                    permissions = Utils.removeFromArr(permissions, x);
                });
            }

            if(activeNode.main){
                if(activeNode.parent){
                    if(activeNode.parent.items && activeNode.parent.items.length > 0){
                        let state = false;

                        for(let i = 0; i < activeNode.parent.items.length; i++){
                            if(permissions.indexOf(activeNode.parent.items[i]) > -1){
                                state = true;
                                break;
                            }
                        }
                        
                        if(!state){
                            permissions = Utils.removeFromArr(permissions, activeNode.main);
                        }
                    }
                } else {
                    permissions = Utils.removeFromArr(permissions, activeNode.main);
                }
            }
        }
        
        return permissions;
    }
    /* END FUNCTIONS */


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
        return <Row>
            
            <Col xs={12}>
                <Form.Group className={'mb-5'}>
                    <Form.Label>{this.props.fields.roleName.label}</Form.Label>
                    <Form.Control
                        type="text"
                        id={this.props.fields.roleName.id}
                        placeholder={this.props.fields.roleName.placeholder}
                        value={values.roleName}
                        onChange={handleChange}
                        disabled={values.isDefault}
                        isInvalid={!!errors.roleName}
                    />
                    <Form.Control.Feedback type="invalid">{errors.roleName}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col xs={12}>
                <Form.Group className={'mb-5'}>
                    <Form.Label>{this.props.fields.roleDescription.label}</Form.Label>
                    <Form.Control
                        type="text"
                        id={this.props.fields.roleDescription.id}
                        placeholder={this.props.fields.roleDescription.placeholder}
                        value={values.roleDescription}
                        onChange={handleChange}
                        disabled={values.isDefault}
                        isInvalid={!!errors.roleDescription}
                    />
                    <Form.Control.Feedback type="invalid">{errors.roleDescription}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            {!this.state.isLoading && <Col xs={12}>
                <Form.Group className={'mb-5'}>
                    <Form.Label>{this.props.fields.permissions.label}</Form.Label>
                    <Form.Control
                        as = {RSuiteCheckTreePicker}
                        isInvalid={!!errors.permissions}

                        className={'custom-roles-tree'}
                        id={this.props.fields.permissions.id}
                        placeholder={this.props.fields.permissions.placeholder}
                        items={this.state.pages}
                        value={values.permissions}
                        isLoading={false}
                        uncheckableItemValues={[ -1 ]}
                        cascade={true}
                        isDefault={true}
                        onChange={async (values) => {
                            await setFieldValue('permissions', values);
                        }}
                        onSelect={async (activeNode) => {
                            let permissions = this.onTreeChange(activeNode);
                            await setFieldValue('selectedPermissions', permissions);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.permissions}</Form.Control.Feedback>
                </Form.Group>
            </Col>}
            
        </Row>
    }
    
    footer = () => {
        let {
            validateForm,
            values,
        } = this.props.formOptions;

        return <Row>
            <Col xs={'auto'}>
                <Button 
                    type={'submit'}
                    disabled={this.props.isLoading}
                >
                    SAVE
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            <Col xs={'auto'}>
                <Button 
                    type={'button'}
                    variant={'secondary'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        Utils.showDrawer(this.props.drawer, false);
                    }}
                >
                    CANCEL
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            
            
            {values.isEdit && <>
                <Col xs={12} className={'d-flex d-sm-none pb-3'}></Col>
                <Col xs={'auto'} sm={true} className={'text-end'}>
                    {!values.isDefault && <Button 
                        type={'button'}
                        variant={'danger'}
                        disabled={this.props.isLoading}
                        onClick={() => {
                            if(this.props.onDelete){
                                this.props.onDelete(values);
                            }
                        }}
                    >
                        REMOVE
                        {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>}
                </Col>
                <Col xs={'auto'} md={'auto'}>
                    <Button 
                        type={'button'}
                        variant={'outline-secondary'}
                        disabled={this.props.isLoading}
                        onClick={() => {
                            validateForm().then((err) => {
                                if(_.isEmpty(err)){
                                    if(this.props.onCopy){
                                        this.props.onCopy(values);
                                    }
                                } else {
                                    Utils.toast('You must fill in the required fields', 'error');
                                }
                            })
                        }}
                    >
                        <i className={'material-icons'}>content_copy</i>
                        COPY
                        {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>
            </>}

        </Row>
    }


    render() {
        return <DrawerContentLayout
            ref={this.bodyRef}
            isBlocking={this.props.isBlocking}
            body={this.body()}
            footer={this.footer()}
        />
    }
}

const mapDispatchToProps = {
    commonProfileApiCallGet,
    clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(RolesForm);
