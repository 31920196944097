import _ from 'lodash';
import isValidCoordinates from "is-valid-coordinates";

// import { DEFAULT_ZOOM, DEFAULT_CENTER } from '../../components/googleMap/GoogleMap.jsx';

// Google Map ----------------------------------------------------------------------

export const convertCoordToLatLng = (coordinates: any, defaultCoordinates: any = null) => {
  let coord = null;

  try {
    if(coordinates && coordinates != ''){
      let coordAr = coordinates.trim().split(',');

      if (coordAr && coordAr.length == 2) {
        let lat = parseFloat(coordAr[0].replaceAll(' ', '').trim());
        let lng = parseFloat(coordAr[1].replaceAll(' ', '').trim());

        coord = { lat, lng };
      }
    }
  } catch (e) {}

  return (coord) ? coord : defaultCoordinates;
}
export const convertLatLngToCoord = (lat: any, lng: any, separator: any = ',') => {
  let coord = [];

  try {
    if (lat != "") {
      coord.push(lat);
    }

    if (lng != "") {
      coord.push(lng);
    }
  } catch (e) {}

  return (coord.length > 1 && coord[0] && coord[1]) ? coord.join(separator) : '';
}
export const convertPositionToLatLng = (position: any) => {
  let coord = null;

  try {
    coord = { lat: position.lat(), lng: position.lng() };
  }catch(e){
    try {
      coord = { lat: position.lat, lng: position.lng };
    }catch(e){}
  }

  return coord;
}

export const validateCoordinates = (coordinates: any) => {
  try {
    let args = (coordinates && coordinates != '') ? coordinates.split(',') : [];

    let latitude = (args && args.length > 0) ? (/^-?\d*\.?\d*$/.test(args[0].toString().trim())) ? parseFloat(args[0].toString().trim()) : '' : '';
    let longitude = (args && args.length > 1) ? (/^-?\d*\.?\d*$/.test(args[1].toString().trim())) ? parseFloat(args[1].toString().trim()) : '' : '';

    return (isValidCoordinates.latitude(latitude) && isValidCoordinates.longitude(longitude))
  } catch (e) {
    return false;
  }
}
export const validateLatLng = (lat: any, lng: any) => {
  try {
    let latitude = (/^-?\d*\.?\d*$/.test(lat.toString().trim())) ? parseFloat(lat.toString().trim()) : '';
    let longitude = (/^-?\d*\.?\d*$/.test(lng.toString().trim())) ? parseFloat(lng.toString().trim()) : '';

    return (isValidCoordinates.latitude(latitude) && isValidCoordinates.longitude(longitude))
  } catch (e) {
    return false;
  }
}
export const dinamicZoom = (map: any, lat: any, lng: any, extendBy = 0.0010) => {
  try {
      let bounds = new window.google.maps.LatLngBounds();
      let centerCoordinate = new window.google.maps.LatLng(lat, lng);
      bounds.extend(centerCoordinate);
      
      bounds.extend(new window.google.maps.LatLng(centerCoordinate.lat() + extendBy, centerCoordinate.lng() + extendBy));
      bounds.extend(new window.google.maps.LatLng(centerCoordinate.lat() - extendBy, centerCoordinate.lng() - extendBy));
      
      map.fitBounds(bounds);
  } catch(e){}
}
export const dinamicMultiZoom = (map: any, positions: any) => {
  try {
    let bounds = new window.google.maps.LatLngBounds();
    positions.forEach((marker: any) => {
      bounds.extend(marker.getPosition());
    });
    map.fitBounds(bounds);
  } catch(e){}
}
export const getAddressPlace = (place: any) => {
  let obj = {
    address: '',
    lat: null,
    lng: null,
    coord: '',
  }

  try {
    if(place){
      if(place.formatted_address){
        obj.address = place.formatted_address;
      } else if(place.name){
        obj.address = place.name;
      }
      
      if(place.geometry && place.geometry.location){
        obj.lat = place.geometry.location.lat();
        obj.lng = place.geometry.location.lng();
        obj.coord = convertLatLngToCoord(obj.lat, obj.lng);
      }
    }
  } catch(e){}

  return obj;
}
export const getFromAddress = (components: any, type: any) => {
  return components.filter((component: any) => component.types.indexOf(type) === 0).map((item: any) => item.long_name).pop() || null;
}
export const getFullAddressPlace = (place: any) => {
  let obj = {
    street: '',
    postalCode: '',
    blockNo: '',
    siteName: '',
    customSiteName: '',
    lat: null,
    lng: null,
    coord: '',
  }

  try {
    if(place){
      let address_components = place["address_components"] || [];
          
      let route = getFromAddress(address_components, "route");
      let postal_code = getFromAddress(address_components, "postal_code");
      let premise = getFromAddress(address_components, "premise");

      obj.street = route ? route : '';
      obj.postalCode = postal_code ? postal_code : '';
      obj.blockNo = premise ? premise : '';
      obj.customSiteName = place.name ? place.name : '';
      obj.siteName = (place.formatted_address) ? place.formatted_address : place.name;
      
      if (place.geometry && place.geometry.location) {
        obj.lat = place.geometry.location.lat();
        obj.lng = place.geometry.location.lng();
        obj.coord = convertLatLngToCoord(obj.lat, obj.lng);
      }
    }
  } catch(e){}

  return obj;
}
export const getPolygonCenterPosition = (positions: any) => {
  try {
    let bounds = new window.google.maps.LatLngBounds();
    positions.forEach((pos: any) => {
      bounds.extend(new window.google.maps.LatLng(pos.lat, pos.lng));
    });

    var lat = bounds.getCenter().lat();
    var lng = bounds.getCenter().lng();

    return { lat: lat, lng: lng };
  } catch(e){}

  return null
}
export const getPolygonCenterCoordinate = (positions: any) => {
  let coord = '';
  
  try {
    let obj: any = getPolygonCenterPosition(positions);
    return convertLatLngToCoord(obj.lat, obj.lng);
  } catch(e){}

  return coord
}
export const isMarkerExist = (array: any, position: any) => {
  try {
    if(position && array && array.length > 0){
      for (var i = 0, l = array.length; i < l; i++) {
        let lat = array[i].position.lat();
        let lng = array[i].position.lng();
        if (lat === position.lat && lng === position.lng) {
          return true;
        }
      }
    }
  } catch(e){}
  return false;
}
export const isInPolygon = (coordinates: any, position: any) => {
  let isInPoly = false;
  try {
    if(position && coordinates && coordinates.length > 0){
      let point = new window.google.maps.LatLng(position.lat, position.lng);
      let poly = new window.google.maps.Polygon({ paths: coordinates });
      isInPoly = window.google.maps.geometry.poly.containsLocation(point, poly);
    }
  } catch(e){}
  return isInPoly;
}
export const getLocationInGoogleMapURL = (lat: any, lng: any) => {
  return 'https://www.google.com/maps?q=' + lat + ',' + lng;
}