import React from 'react';
import { useLocation } from 'react-router-dom'

import {
  Badge,
} from 'react-bootstrap';
// import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

// import { Tooltip, Spin } from "antd";

// Auth
// import { useAuthContext } from '../../../auth/use-auth-context.js';
import { useRouter } from '../../../auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../utils/store.tsx';
// import confirmSlice from '../../../components/modal/confirmSlice.tsx';

// enums
// import {  } from '../../../utils/utils.tsx';
import { Permissions } from '../../../utils/enums.tsx';

// layouts
import ManageLayout from '../../../layouts/manage/Layout.tsx';

// components
import MainContainer from '../../../components/container/MainContainer.jsx';

import ListJobTypes from './jobTypes/List.tsx';

// import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { ReactComponent as MagicIcon } from "../../../assets/icons/magic.svg";

// ----------------------------------------------------------------------


const PageComponent = () => {
  const location = useLocation();
  const router = useRouter();


  // const { user }: any = useAuthContext();
  // const { userAccount } = user ?? {};
  // const someField = userAccount?.someField;

  // const { isLoading } = useTypedSelector((state: RootState) => state.manageJobTypesSlice);
  // const dispatch = useDispatch<AppDispatch>();


  const [isJobTypes, setIsJobType] = React.useState<any>(false);
  const [isTime, setIsTime] = React.useState<any>(false);
  const [permission, setPermission] = React.useState<any>([-1]);

  React.useEffect(() => {
    let isJobTypes = location.pathname.includes('job/job-types');
    let isTime = location.pathname.includes('job/time');

    setIsJobType(isJobTypes);
    setIsTime(isTime);

    if(isJobTypes){
      setPermission([Permissions.ManageJobs[0]]);
    } else if(isTime){
      setPermission([Permissions.ManageJobs[2]]);
    } else {
      setPermission([-1]);
    }
  }, []);


  return <MainContainer className={'p-0'} title={'Manage'} isLoading={false} roles={Permissions.ManageJobs}>
    <ManageLayout
      listName={'Jobs'}
      backLink={null}
      isList={true}

      permission={permission}
      pages={[
        {
          text: 'Job',
          variant: 'custom-outlined',
          active: true,
          onClick: () => {
            
          }
        },
        {
          text: 'Time',
          variant: 'custom-outlined',
          active: false,
          onClick: () => {
            router.push('/manage/job/time');
          }
        },
      ]}

      buttons={[
        {
          text: <>
            <span><MagicIcon /></span>
            <span className='px-2'>Create with Wizard</span>
            <span><Badge pill bg="white" text="primary">BETA</Badge></span>
          </>,
          variant: 'custom-primary',
          active: false,
          onClick: () => {
            router.push('/v5/manage/job/job-types-form');
          }
        },
      ]}

      defaultTab={'tab1'}
      tabs={[
        {
          text: 'Job types',
          eventKey: 'tab1',
          disabled: false,
          onClick: () => {
            
          }
        },
        {
          text: 'Recurring jobs',
          eventKey: 'tab2',
          disabled: false,
          onClick: () => {
            router.push('/manage/job/job-recurring');
          }
        },
      ]}

      footerButtons={[]}
    >
      {isJobTypes && <ListJobTypes />}
      {/* {isTime && <ListTime />} */}
    </ManageLayout>
  </MainContainer>
}

export default PageComponent;
