import React from 'react';
import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';

import { 
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  Offcanvas,
  Tabs,
  Tab,
} from 'react-bootstrap';

// import { Spin } from 'antd';

import { useRouter } from '../../auth/auth-guard.js';
import PageRoleBasedGuard from '../../auth/page-role-based-guard';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../utils/store.tsx';
import slice from './slice.tsx';

// import {  } from '../../utils/utils';
import { MAIN_BREAKPOINT } from '../../utils/enums';

import Menu from '../../sections/Menu';

import { ReactComponent as XIcon } from "../../assets/icons/smallX.svg";
import { ReactComponent as MenuIcon } from "../../../v5/assets/icons/menu.svg";

// ----------------------------------------------------------------------

const StyledLayout = styled.div`
  display: flex;
`;

const StyledMenu = styled.div`
  display: inline-flex;
  width: 250px;
  min-height: calc(100vh - var(--header-height, 64px));
  border-right: 1px solid var(--bs-gray-100);
  z-index: 1;
  background-color: white;

  position: sticky;
  top: 0px;

  ${up(MAIN_BREAKPOINT)} {
    display: inline-block;
  }

  ${down(MAIN_BREAKPOINT)} {
    display: none;
  }
`;

const StyledContent = styled.div<any>`
  width: 100%;
  display: inline-block;
  padding-top: 16px;
  padding-left: 15px;
  padding-right: 15px;

  
  ${up(MAIN_BREAKPOINT)} {
    height: calc(100vh - var(--header-height, 64px));
    overflow-x: hidden;
    overflow-y: auto;
  }

  ${down(MAIN_BREAKPOINT)} {
    width: 100%;
  }
`;

const StyledH6MenuHead = styled.h6`
  color: var(--bs-gray-700);
`;

const StyledListGroup = styled(ListGroup)`
  overflow: auto;
  user-select: none;
  padding: 12px;

  ${up(MAIN_BREAKPOINT)} {
    height: calc(100vh - var(--header-height, 64px) - var(--manage-menu-header-height, 68px) - 1px);
  }

  ${down(MAIN_BREAKPOINT)} {
    height: calc(100vh - var(--manage-offcanvas-header-height, 82px) - var(--manage-menu-header-height, 68px) - 1px);
  }

  .list-group-item {
    padding: 6px 8px;
    border: 0px;
    border-radius: 6px;
    cursor: pointer;
    color: var(--bs-gray-600);

    &:hover, &.active {
      background-color: var(--bs-blue-focus1);
      color: var(--bs-blue-500);
      font-weight: 500;
    }

    &.active {
      &:hover {
        background-color: var(--bs-blue-focus1);
        color: var(--bs-gray-600);
        font-weight: 500;
      }
    }
  }
`;

const StyledOffcanvas = styled(Offcanvas)`
  width: 250px !important;

  ${up(MAIN_BREAKPOINT)} {
    display: none;
  }

  ${down(MAIN_BREAKPOINT)} {
    display: block;
  }
`;

const StyledMenuWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  overflow: auto;
`;

const StyledHeader = styled.div`
  align-content: center;
  height: 64px !important;
  border-bottom: 1px solid var(--bs-gray-100);
`;

const StyledTabsCol = styled(Col)`
  .nav-tabs {
    margin-left: 0px;
    margin-right: 0px;

    .nav-item {
      button {
        > div {
          svg {
            position: relative;
            top: -2px;
          }

          &.valid {
            svg {
              rect {
                stroke: var(--bs-blue-500);
              }

              path {
                stroke: var(--bs-blue-500);
              }
            }
          }
          
          &.invalid {
            svg {
              rect {
                stroke: var(--bs-red-500);
              }

              path {
                stroke: var(--bs-red-500);
              }
            }
          }

          &.invalid-current {
            svg {
              rect {
                stroke: var(--bs-red-500);
                fill: var(--bs-red-500);
              }

              path {
                stroke: var(--bs-red-500);
                fill: var(--bs-red-500);
              }
            }
          }
        }

        &.disabled {
          > div {
            svg {
              rect {
                stroke: var(--bs-gray-600);
              }

              path {
                stroke: var(--bs-gray-600);
              }
            }

            span {
              color: #7F818D;
            }
          }
        }
      }
    }
  }
`;

const StyledFooter = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 20px 32px;
  background-color: white;
  border-top: 1px solid var(--bs-gray-100);

  > div {
    > .row {
      ${down('lg')} {
        position: relative;
        left: 0px;
        width: 100%;
      }
      
      ${up('lg')} {
        position: relative;
        left: 265px;
        width: calc(100% - 265px);
      }

      ${up('xxxl')} {
        position: relative;
        left: 300px;
        width: calc(100% - 300px);
      }
    }
  }
`;


const Layout = ({ listName, isList = false, backLink, permission, pages, buttons, tabs, footerButtons, defaultTab, children }: any) => {
  const manageMenuHeaderHeight = document.getElementById('manage_menu_header_ID')?.clientHeight || 56;
  const manageOffcanvasHeaderHeight = document.getElementById('manage_offcanvas_header_ID')?.clientHeight || 64;
  document.documentElement.style.setProperty('--manage-menu-header-height', `${(manageMenuHeaderHeight)}px`);
  document.documentElement.style.setProperty('--manage-offcanvas-header-height', `${(manageOffcanvasHeaderHeight)}px`);

  const router = useRouter();

  const { show } = useTypedSelector((state: RootState) => state.manageLayoutSlice);
  const dispatch = useDispatch<AppDispatch>();


  const topSection = () => {
    return <Row className='g-8 align-items-center'>
      {/* <Col xs={'auto'}>
        <Button
          className={'d-block d-lg-none'}
          variant={'custom-none-primary'}
          onClick={() => {
            dispatch(slice.setShow(!show));
          }}
        >
          <MenuIcon />
        </Button>
      </Col> */}
      <Col xs={'auto'}>
        <h6 className="bold mb-0">{listName}</h6>
      </Col>
      <Col xs={true}></Col>
      <Col xs={'auto'}>
        <Row className='g-8 align-items-center'>
          {/* <Col xs={'auto'}>
            <Button
              className={'d-block d-lg-none'}
              variant={'custom-none-primary'}
              onClick={() => {
                dispatch(slice.setShow(!show));
              }}
            >
              <MenuIcon />
            </Button>
          </Col> */}
          {
          isList
          ?
          (buttons && buttons.length > 0) && <Col xs={'auto'}>
            {buttons.map((obj: any, i: number) => {
              return <Button
                key={'pageButtons_' + i}
                variant={obj.variant}
                active={obj.active}
                size={'sm'}
                onClick={(e: any) => {
                  if(obj.onClick){
                    obj.onClick(e)
                  }
                }}
              >{obj.text}</Button>
            })}
          </Col>
          :
          <Col xs={'auto'}>
            <Button
              size={'sm'}
              variant={'custom-outlined'}
              onClick={() => {
                if(typeof backLink === 'function') {
                  backLink();
                } else if(backLink){
                  router.push(backLink);
                } else {
                  router.back();
                }
              }}
            >
              <XIcon /> Cancel
            </Button>
          </Col>
          }
        </Row>
      </Col>
      {(pages && pages.length > 0) && <Col xs={12}>
        <Row className='g-6'>
          {pages.map((obj: any, i: number) => {
            return <Col key={'pagePages_' + i} xs={'auto'}>
              <Button
                style={{ minWidth: '81px' }}
                variant={obj.variant}
                active={obj.active}
                size={'sm'}
                onClick={(e: any) => {
                  if(obj.onClick){
                    obj.onClick(e)
                  }
                }}
              >{obj.text}</Button>
            </Col>
          })}
        </Row>
      </Col>}
    </Row>
  }

  const sideMenu = () => {
    return <Row className='g-0'>
      <Col id={'manage_menu_header_ID'} xs={12} className={'pt-20 ps-12 pe-12 pb-12'}>
        <StyledH6MenuHead className='medium mb-0'>Manage</StyledH6MenuHead>
      </Col>
      <Col xs={12}>
        <StyledListGroup variant="flush" className='side-menu-list'>

          <ListGroup.Item
            active={false}
            onClick={() => {
              router.push('/manage/resources');
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();

              if(e.button === 1) {
                router.open('/manage/resources');
              }
            }}
          >
            Resource
          </ListGroup.Item>

          <ListGroup.Item
            active={true}
            onClick={() => {
              router.push('/v5/manage/job/job-types');
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();

              if(e.button === 1) {
                router.open('/v5/manage/job/job-types');
              }
            }}
          >
            Jobs
          </ListGroup.Item>

          <ListGroup.Item
            active={false}
            onClick={() => {
              router.push('/manage/payroll/payroll');
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();

              if(e.button === 1) {
                router.open('/manage/payroll/payroll');
              }
            }}
          >
            Payroll
          </ListGroup.Item>
          
          <ListGroup.Item
            active={false}
            onClick={() => {
              router.push('/manage/geofence');
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();

              if(e.button === 1) {
                router.open('/manage/geofence');
              }
            }}
          >
            Geofence
          </ListGroup.Item>

          <ListGroup.Item
            active={false}
            onClick={() => {
              router.push('/manage/user/users');
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();

              if(e.button === 1) {
                router.open('/manage/user/users');
              }
            }}
          >
            Users
          </ListGroup.Item>

          <ListGroup.Item
            active={false}
            onClick={() => {
              router.push('/manage/profile/company');
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();

              if(e.button === 1) {
                router.open('/manage/profile/company');
              }
            }}
          >
            Company
          </ListGroup.Item>

          <ListGroup.Item
            active={false}
            onClick={() => {
              router.push('/manage/setting/general');
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();

              if(e.button === 1) {
                router.open('/manage/setting/general');
              }
            }}
          >
            Settings
          </ListGroup.Item>

        </StyledListGroup>
      </Col>
    </Row>
  }


  return <>
    <StyledLayout>
      <StyledMenu>
        {sideMenu()}
      </StyledMenu>
      
      <StyledContent>
        <Row className='gy-24 align-items-center'>
          <Col xs={12} className='border-bottom ps-32 pe-32 pt-20 pb-16 mt-2'>
            {topSection()}
          </Col>
          <Col xs={12} className='px-32'>
            <Row className='g-24'>
              {(tabs && tabs.length > 0) && <StyledTabsCol xs={12}>
                <Row className='align-items-center px-12'>
                  <Col xs={12} className='p-0'>
                    <Tabs
                      activeKey={defaultTab}
                      onSelect={(eventKey: any) => {
                        let item = tabs.find((x: any) => x.eventKey === eventKey);
                        if(item && item.onClick){
                          item.onClick();
                        }
                      }}
                    >
                      {tabs.map((obj: any, i: number) => {
                        return <Tab key={'pageTabs_' + i} className={obj.eventKey} eventKey={obj.eventKey} title={(typeof obj.text === 'function') ? obj.text() : obj.text} disabled={obj.disabled} />
                      })}
                    </Tabs>
                  </Col>
                </Row>
              </StyledTabsCol>}
              
              <Col xs={12}>
                <PageRoleBasedGuard roles={permission}>{children}</PageRoleBasedGuard>
              </Col>
            </Row>
          </Col>
        </Row>
      </StyledContent>
    </StyledLayout>

    {(footerButtons && footerButtons.length > 0) && <StyledFooter>
      <Container fluid={'xxxl'}>
        <Row className='g-8'>
          {footerButtons.map((obj: any, i: number) => {
            return <Col key={'pageFooterButtons_' + i} xs={'auto'} className={(obj.invisible ? 'd-none' : '')}>
              <Button
                className={obj.className}
                variant={obj.variant}
                active={obj.active}
                size={'sm'}
                onClick={(e: any) => {
                  if(obj.onClick){
                    obj.onClick(e)
                  }
                }}
              >{obj.text}</Button>
            </Col>
          })}
        </Row>
      </Container>
    </StyledFooter>}

    <StyledOffcanvas
      backdrop={true}
      scroll={false}
      placement={'start'}
      show={show}
      onHide={() => {
        dispatch(slice.setShow(false));
      }}
    >
      <>
        <StyledHeader id={'manage_offcanvas_header_ID'}>
          <Button
            className={'px-28'}
            size={'sm'}
            variant={'custom-text'}
            onClick={() => {
              dispatch(slice.setShow(false));
            }}
          >
            <XIcon />
          </Button>
        </StyledHeader>

        <StyledMenuWrapper>
          <Menu isResponsive={false} />
          {sideMenu()}
        </StyledMenuWrapper>
      </>
    </StyledOffcanvas>
  </>
}

export default Layout;