import React from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {useIntl} from 'react-intl'

import { 
  Badge,
} from "react-bootstrap-v5";

import Utils from '../../../../app/utils/utils';
import { PagesPermissions, UserCookieSettingType, PageCookieSettingType } from '../../../../app/utils/enums';

import {RootState} from '../../../../setup'


export function MenuInner() {
  const intl = useIntl()

  const auth: any = useSelector<RootState>(({auth}) => auth, shallowEqual)
  const user = (auth && auth.user) ? auth.user : null;
  const userCookieSettings = (auth && auth.userCookieSettings) ? auth.userCookieSettings : null;
  const permissions = (user && user.permissions && user.permissions.length > 0) ? user.permissions : [];


  let pageName = 'billingDefault';
  let settingsType = UserCookieSettingType.BillingByJobDates;
  let settingsItem = null;
  let isDefaultGroupByDate = true;
  if(userCookieSettings && userCookieSettings.length > 0){
    let index = -1;
    if((userCookieSettings && userCookieSettings.length > 0) && (userCookieSettings !== null)){
      if(pageName !== null){
        index = userCookieSettings.findIndex((x: { type: number; name: string }) => (x.type === settingsType) && (x.name === pageName));
      } else {
        index = userCookieSettings.findIndex((x: { type: number; name: string }) => x.type === settingsType);
      }
    }
    if(index !== -1 && userCookieSettings[index] && userCookieSettings[index].value && userCookieSettings[index].value !== ''){
      settingsItem = Utils.getJsonFromString(userCookieSettings[index].value);
    }
  }
  if(settingsItem){
    isDefaultGroupByDate = ((settingsItem[PageCookieSettingType.isDefaultGroupByDate] == false) || (settingsItem[PageCookieSettingType.isDefaultGroupByDate] == true)) ? settingsItem[PageCookieSettingType.isDefaultGroupByDate] : isDefaultGroupByDate;
  }




  
  let currentValue = Utils.getCookie();
  let isV4 = (currentValue == null || currentValue == 'v4');





  return (
    <>
      {(Utils.hasPermission(permissions, PagesPermissions.Dashboard.read)) && <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/Dashboard' parent={'/Dashboard'} />}
      {(Utils.hasPermission(permissions, PagesPermissions.Map.read)) && <MenuItem title={intl.formatMessage({id: 'MENU.MAP'})} to='/Map' parent={'/Map'} />}
      {(Utils.hasPermission(permissions, PagesPermissions.Customers.read)) && <MenuItem title={intl.formatMessage({id: 'MENU.CUSTOMERS'})} to='/Customers' parent={'/Customers'} />}
      {/* {(Utils.hasPermission(permissions, PagesPermissions.Customers.read)) && <MenuItem title={intl.formatMessage({id: 'MENU.CUSTOMERS'})} to='/Customers' parent={'/Customers'} isV4={isV4} />} */}
      
      {(
        Utils.hasPermission(permissions, PagesPermissions.Jobs.read)
      ) && <MenuInnerWithSub
        title={intl.formatMessage({id: 'MENU.JOBS'})}
        to='/Jobs'
        hasArrow={true}
        menuPlacement='bottom-start'
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        {(Utils.hasPermission(permissions, PagesPermissions.Jobs.read)) && <MenuItem title={intl.formatMessage({id: 'MENU.JOBS'})} to='/Jobs' parent={'/Jobs'} />}
        {(Utils.hasPermission(permissions, PagesPermissions.Jobs.read)) && <MenuItem title={intl.formatMessage({id: 'MENU.GANTT'})} to='/Gantt-chart' parent={'/Gantt-chart'} />}
      </MenuInnerWithSub>}

      {(Utils.hasPermission(permissions, PagesPermissions.GlobalService.read)) && <MenuItem title={intl.formatMessage({id: 'MENU.SERVICES'})} to='/services' parent={'/services'} />}
      
      {(
        Utils.hasPermission(permissions, PagesPermissions.BillingInvoice.read) || 
        Utils.hasPermission(permissions, PagesPermissions.BillingPurchase.read) || 
        Utils.hasPermission(permissions, PagesPermissions.BillingExpense.read) || 
        Utils.hasPermission(permissions, PagesPermissions.BillingCategory.read)
      ) && <MenuInnerWithSub
        title={intl.formatMessage({id: 'MENU.BILLING'})}
        to='/billing/invoice-table'
        hasArrow={true}
        menuPlacement='bottom-start'
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        {(Utils.hasPermission(permissions, PagesPermissions.BillingInvoice.read)) && <MenuItem to={isDefaultGroupByDate ? '/billing/invoice-table/list-by-dates' : '/billing/invoice-table/group-by-customer'} parent='/billing/invoice-table' title={intl.formatMessage({id: 'MENU.BILLING.INVOICE_TABLE'})} />}
        {(Utils.hasPermission(permissions, PagesPermissions.BillingPurchase.read)) && <MenuItem to={isDefaultGroupByDate ? '/billing/purchase-table/list-by-dates' : '/billing/purchase-table/group-by-customer'} parent='/billing/purchase-table' title={intl.formatMessage({id: 'MENU.BILLING.PURCHASE_TABLE'})} />}
        {(Utils.hasPermission(permissions, PagesPermissions.BillingExpense.read)) && <MenuItem to={isDefaultGroupByDate ? '/billing/expense-table/list-by-dates' : '/billing/expense-table/group-by-customer'} parent='/billing/expense-table' title={intl.formatMessage({id: 'MENU.BILLING.EXPENSE_TABLE'})} />}
        {(Utils.hasPermission(permissions, PagesPermissions.BillingCategory.read)) && <MenuItem to='/billing/category' parent='/billing/category' title={intl.formatMessage({id: 'MENU.BILLING.CATEGORY_TABLE'})} />}
      </MenuInnerWithSub>}
      
      {(Utils.hasPermission(permissions, PagesPermissions.Manage.read)) && <MenuInnerWithSub
        title={intl.formatMessage({id: 'MENU.MANAGE'})}
        to='/manage/resources'
        hasArrow={true}
        menuPlacement='bottom-start'
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        {(
          Utils.hasPermission(permissions, PagesPermissions.ManageDrivers.read) ||
          Utils.hasPermission(permissions, PagesPermissions.ManageVehicles.read) ||
          Utils.hasPermission(permissions, PagesPermissions.ManageWaste.read) ||
          Utils.hasPermission(permissions, PagesPermissions.ManageBins.read)
        ) && <MenuItem to='/manage/resources' parent='/manage/resource' title={intl.formatMessage({id: 'MENU.MANAGE.RESOURCES'})} />}

        {(
          Utils.hasPermission(permissions, PagesPermissions.ManageJobtemplate.read) ||
          // Utils.hasPermission(permissions, PagesPermissions.ManageService.read) ||
          Utils.hasPermission(permissions, PagesPermissions.ManageTime.read)
        ) && <MenuItem to='/manage/jobs' parent='/manage/job' title={intl.formatMessage({id: 'MENU.MANAGE.JOBS'})} />}
        
        {(
          Utils.hasPermission(permissions, PagesPermissions.ManagePayroll.read) ||
          Utils.hasPermission(permissions, PagesPermissions.ManageWorkHours.read)
        ) && <MenuItem to='/manage/payrolls' parent='/manage/payroll' title={intl.formatMessage({id: 'MENU.MANAGE.PAYROLL'})} />}

        {(
          Utils.hasPermission(permissions, PagesPermissions.ManageGeofence.read)
        ) && <MenuItem to='/manage/geofence' parent='/manage/geofence' title={intl.formatMessage({id: 'MENU.MANAGE.GEOFENCE'})} />}

        {(
          Utils.hasPermission(permissions, PagesPermissions.ManageUsers.read)
        ) && <MenuItem to='/manage/users' parent='/manage/user' title={intl.formatMessage({id: 'MENU.MANAGE.USERS'})} />}

        {(
          Utils.hasPermission(permissions, PagesPermissions.ManageCompany.read)
        ) && <MenuItem to='/manage/profiles' parent='/manage/profile' title={intl.formatMessage({id: 'MENU.MANAGE.PROFILE'})} />}

        {(
          Utils.hasPermission(permissions, PagesPermissions.ManageGeneral.read) ||
          Utils.hasPermission(permissions, PagesPermissions.ManageSubscription.read)
          // Utils.hasPermission(permissions, PagesPermissions.ManageHoliday.read)
        ) && <MenuItem to='/manage/settings' parent='/manage/setting' title={intl.formatMessage({id: 'MENU.MANAGE.SETTINGS'})} />}
      </MenuInnerWithSub>}

      {(Utils.hasPermission(permissions, PagesPermissions.BinCenter.read)) && <MenuItem title={<>{intl.formatMessage({id: 'MENU.BIN_CENTER'})} <Badge pill bg="primary" className={'label-beta ms-2'} style={{ padding: '4px 6px 4px 6px' }}>Beta</Badge></>} to='/bin-center' parent={'/bin-center'} />}

      {(Utils.hasPermission(permissions, PagesPermissions.Reports.read)) && <MenuItem title={intl.formatMessage({id: 'MENU.REPORTS'})} to='/Reports' parent={'/Reports'} />}
    </>
  )
}
