import React from 'react';

import {
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap';
import styled from 'styled-components';

import { 
  Popover,
} from 'antd';

import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg";

// ----------------------------------------------------------------------

export default function TableFilter({ disabled, onUpdate, onOpen, children }: any) {
  const [open, setOpen] = React.useState(false);

  const hide = () => {
    setOpen(false);
  };

  const update = (state: boolean) => {
    if(onUpdate){
      onUpdate(state);
    }
    
    hide();
  };

  React.useEffect(() => {
    if(open && onOpen){
      onOpen();
    }
  }, [open]);

  return <Popover
    open={open}
    onOpenChange={(newOpen: boolean) => {
      setOpen(newOpen);
    }}

    content={<div style={{ minWidth: '280px' }}>
      <Row className='align-items-center g-0 p-16 pt-18'>
        <Col xs={12}>{children}</Col>
      </Row>
      
      <Row className='align-items-center g-0 p-16' style={{ backgroundColor: 'var(--bs-blue-focus1)' }}>
        <Col xs={6}>
          <Button
            variant={'custom-outlined'}
            disabled={disabled}
            onClick={() => {
              update(false);
            }}
          >Clear</Button>
        </Col>
        <Col xs={6} className='text-end'>
          <Button
            variant={'custom-primary'}
            disabled={disabled}
            onClick={() => {
              update(true);
            }}
          >Done</Button>
        </Col>
      </Row>
    </div>}
    arrow={false}
    placement="bottomRight"
    trigger="click"
  >
    <Button
      variant={'custom-outlined'}
      size='sm'
      disabled={disabled}
    >
      <FilterIcon /><span className='ps-2'>Filters</span>
    </Button>
  </Popover>
}
