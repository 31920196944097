import React from 'react';

import {
  Row,
  Col,
  Button,
  Form,
  InputGroup,
} from 'react-bootstrap';

import Autocomplete from "./Autocomplete.tsx";

import { ReadAPIParams } from '../../utils/utils.tsx';

import { ReactComponent as Edit2Icon } from "../../assets/icons/edit-2.svg";
import { ReactComponent as Trash2Icon } from "../../assets/icons/trash-2.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/cancel.svg";

// ----------------------------------------------------------------------

const SmartAutocomplete = ({apiPath, fieldNames, addText, hasOptionRender, hasFooterRender, optionRender, labelRender, footerRender, ...props}: any) => {
  const [isCreate, setIsCreate] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isRemove, setIsRemove] = React.useState(false);
  const [isInvalid, setIsInvalid] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [value, setValue] = React.useState('');


  const clear = () => {
    setIsCreate(false);
    setIsEdit(false);
    setIsRemove(false);
    setIsInvalid(false);
    setId(null);
    setValue('');
  }

  const save = (ref: any) => {
    if(isEdit){
      let params: any = {
        [fieldNames.value]: id,
        [fieldNames.label]: value,
      }
      ref.callUpdateApi(apiPath, params, (state: boolean, data: any) => {
        if(state){
          clear();
        }
      })

    } else {
      let params: any = {
        [fieldNames.label]: value,
      }
      ref.callCreateApi(apiPath, params, (state: boolean, data: any) => {
        if(state){
          clear();
        }
      })
    }
  }
  
  const remove = (ref: any) => {
    let params: any = {
      [fieldNames.value]: id,
      [fieldNames.label]: value,
    }
    ref.callDeleteApi(apiPath, params, (state: boolean, data: any) => {
      if(state){
        clear();
      }
    })
  }


  return <Autocomplete
    disableActions={isCreate || isEdit}
    onDropdownVisibleChange={(open: any, ref: any) => {
      clear();

      if(open){
        ref.clearSearch();
        
        let params: ReadAPIParams = {
          currentPage: null,
          pageSize: null,
          sortColumn: null,
          sortDir: null,
          searchQuery: null
        }
        ref.callReadApi(apiPath, params, (state: boolean, data: any) => {})
      }
    }}
    onSearch={(e: any, ref: any) => {
      let params: ReadAPIParams = {
        currentPage: null,
        pageSize: null,
        sortColumn: null,
        sortDir: null,
        searchQuery: e.target.value
      }
      ref.callReadApi(apiPath, params, (state: boolean, data: any) => {
        setTimeout(() => {
          ref.focusSearch();
        }, 200);
      })
    }}

    optionRender={hasOptionRender ? (option: any, ref: any) => {
      if(typeof optionRender === 'function'){
        return optionRender(option, ref)
      } else {
        return <Row className={'g-2'}>
          <Col xs={true}>{((typeof labelRender === 'function')) ? labelRender(option) : option.label}</Col>
          <Col xs={'auto'}>
            <Button
              className={'ps-1'}
              variant={'custom-icon-primary'}
              disabled={option.data.disabled || isCreate || isEdit || isRemove}
              onClick={(e) => { 
                e.preventDefault();
                e.stopPropagation();
  
                clear();
                setId(option.value);
                setValue(option.label);
                setIsEdit(true);
              }}
            >
              <Edit2Icon />
            </Button>
          </Col>
          <Col xs={'auto'}>
            <Button
              className={'ps-1'}
              variant={'custom-icon-danger'}
              disabled={option.data.disabled || isCreate || isEdit || isRemove}
              onClick={(e) => { 
                e.preventDefault();
                e.stopPropagation();
  
                clear();
                setId(option.value);
                setValue(option.label);
                setIsRemove(true);
              }}
            >
              <Trash2Icon />
            </Button>
          </Col>
        </Row>
      }
    } : null}
    
    footer={hasFooterRender ? (isLoading: boolean, ref: any) => {
      if(typeof footerRender === 'function'){
        return footerRender(isLoading, ref)
      } else {
        return <Row>
          {
            isCreate || isEdit || isRemove
            ?
              isRemove
              ?
              <Col xs={12}>
                <Row className='g-8 align-items-center'>
                  <Col xs={true} className={isLoading ? 'text-muted' : ''}>{isLoading ? 'Please wait ...' : <b>Are you sure?</b>}</Col>
                  <Col xs={'auto'}>
                    <Button
                      variant={'custom-none-primary'}
                      disabled={isLoading}
                      onClick={(e) => { 
                        e.preventDefault();
                        e.stopPropagation();

                        clear();
                      }}
                    ><CancelIcon /></Button>
                  </Col>
                  <Col xs={'auto'}>
                    <Button
                      variant={'custom-icon-primary'}
                      disabled={isLoading || (value == '')}
                      onClick={(e) => { 
                        e.preventDefault();
                        e.stopPropagation();

                        remove(ref);
                      }}
                    ><CheckIcon /></Button>
                  </Col>
                  <Col xs={'auto'} className='me-8'></Col>
                </Row>
              </Col>
              :
              <Col xs={12}>
                <Row className='g-8 align-items-center'>
                  <Col xs={true}>
                    <InputGroup>
                      <Form.Control
                        type={'text'}
                        autoComplete='off'
                        placeholder={'Enter a value'}
                        autoFocus
                        disabled={isLoading}
                        isInvalid={isInvalid}
                        value={value}
                        onChange={(e: any) => {
                          setValue(e.target.value);
                          setIsInvalid((e.target.value == '') ? true : false);
                        }}
                        onKeyDown={(e) => {
                          if ((e.charCode || e.keyCode) === 13) {
                            e.stopPropagation();
                            e.preventDefault();
    
                            save(ref);
                          }
                        }}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs={'auto'}>
                    <Button
                      variant={'custom-none-primary'}
                      disabled={isLoading}
                      onClick={(e) => { 
                        e.preventDefault();
                        e.stopPropagation();

                        clear();
                      }}
                    ><CancelIcon /></Button>
                  </Col>
                  <Col xs={'auto'}>
                    <Button
                      variant={'custom-icon-primary'}
                      disabled={isLoading || (value == '')}
                      onClick={(e) => { 
                        e.preventDefault();
                        e.stopPropagation();

                        save(ref);
                      }}
                    ><CheckIcon /></Button>
                  </Col>
                  <Col xs={'auto'} className='me-8'></Col>
                  {isInvalid && <Col xs={12} className='mt-0'>
                    <Form.Control.Feedback type="invalid" className='d-block'>Field cannot be empty</Form.Control.Feedback>
                  </Col>}
                </Row>
              </Col>
            :
            <Col xs={12}>
              <Button
                variant={'custom-text'}
                size={'sm'}
                disabled={isLoading || isEdit}
                onClick={(e) => { 
                  e.preventDefault();
                  e.stopPropagation();

                  clear();
                  setIsCreate(true);
                }}
              ><PlusIcon />{(addText && addText != '') ? ' ' + addText : ''}</Button>
            </Col>
          }
        </Row>
      }
    } : null}

    fieldNames={fieldNames}
    {...props}
  />
};

export default SmartAutocomplete;