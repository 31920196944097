import { 
    Row,
    Col,
} from 'react-bootstrap';
import styled from 'styled-components';

import { Spin } from 'antd';

import LoadingScreen from '../../components/loading/LoadingScreen.tsx';

// ----------------------------------------------------------------------

const StyledContainer = styled.div`
  position: relative;
  align-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 6px;
  border: 1px solid var(--bs-gray-300);
`;

const StyledTitle = styled.div`
  color: var(--bs-gray-900);
`;

const StyledDesc = styled.div`
  color: var(--bs-gray-600);
`;

// ----------------------------------------------------------------------

export default function NoResults({ isFirstLoad = false, isLoading = false, total = 0, searchQuery = null, title = 'No results found', desc = 'There aren\'t any results.', loading = 'Loading...', button = null, children }: any) {
  if(isFirstLoad){
    if(isLoading){
      return <StyledContainer>
        <Row className={'align-items-center justify-content-center text-center'}>
          <Col xs={12}>{loading}</Col>
        </Row>
      </StyledContainer>

    } else {
      if(!(total > 0)){
        if(!(searchQuery != null)){
          return <StyledContainer>
            <Row className={'align-items-center justify-content-center text-center g-8'}>
            {(title && title != '') && <Col xs={12}>
                <StyledTitle className="large medium">{title}</StyledTitle>
            </Col>}
            {(desc && desc != '') && <Col xs={12}>
                <StyledDesc className="fs-16">{desc}</StyledDesc>
            </Col>}
            {button && <Col xs={12} className='mt-30'>{button}</Col>}
            </Row>
          </StyledContainer>
        
        } else {
          return children
        }
      } else {
        return children
      }
    }
  } else {
    return children
  }
}
  