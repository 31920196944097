import _ from 'lodash';
import { jsPDF } from "jspdf";
import "jspdf-autotable";

// Generate PDF ----------------------------------------------------------------------

export const generatePDF = (name: string = 'Document', rows: any, columns: any, columnOrder: any, columnsRender: any) => {
  let orientation: any = 'portrait';
  let unit: any = 'pt';
  let size: any = 'A4';
  let margin: any = 40;

  const doc = new jsPDF(orientation, unit, size);
  doc.setProperties({ title: name });
  
  const exportData = getExportData(rows, columns, columnOrder, columnsRender);
  const header = exportData.header;
  const body = exportData.body;

  doc.text(name, margin, margin);
  sliceData(20, header, body, doc)

  doc.save(name + ".pdf");
}
export const sliceData = (maxContentRow: any, header: any, bodySlice: any, doc: any) => {
  let count = 0
  let bodyTemplete: any = [] 
  let bodyReady: any = []
  let margin  = 10 

  bodySlice.map((data: any, i: any)=>{
    if (count <= maxContentRow  ) {
      bodyTemplete.push(bodySlice[i]);
      if (count === maxContentRow) {
        bodyTemplete.push(bodySlice[i+1]);
        bodyReady.push(bodyTemplete);
        count = count +1;
      } else if (i === bodySlice.length - 1) {
        bodyReady.push(bodyTemplete);
        count = count +1;
      } else {
        count = count +1;
      }
    } else {
      bodyTemplete = [];
      count = 0;
      count = count +1 ;
    }
  })
  
  return (
    bodyReady.map((resultBody: any, i: any)=>{
      if (i !== 0){
        margin = margin + 900
      } else {
        margin = 10
      }

      return managePrintLayout( header, resultBody, doc, margin)
    })
  )
}
export const managePrintLayout = (header: any, body: any, doc: any, thisMargin: any) => {
  let sliceValue = 6;

  let count = 1;

  let dataHolding: any = [];
  let RealData: any = [];

  let mainData: any = [];
  let mainDataHolding: any = [];

  let valueY = 0;
  let valueX = 6;

  let margin: any = 40;

  header.map((rowId: any, i: any) => {
    if (count < sliceValue  ) {
      dataHolding.push(header[i]);
      count = count +1 ;
      if (count === sliceValue) {
        dataHolding.push(header[i+1]);
        RealData.push(dataHolding);
      } else if (i === header.length - 1) {
        RealData.push(dataHolding);
      }
    } else {
      dataHolding = [];
      count = 0;
      count = count +1 ;
    }
  });

  for (let index = 0; index < RealData.length; index++) {
    body.map((jobsData: any, i: any) => {
      if(jobsData){
        let bodySlcie = jobsData.slice((valueY ), valueX);
        mainDataHolding.push(bodySlcie);
        if (i === (body.length - 1)) {
          mainData.push(mainDataHolding);
          mainDataHolding = [];
          valueY = valueY + (sliceValue );
          valueX = valueX + (sliceValue  +1);
        }
      }
    });
  }

  return RealData.map((rowId: any, i: any) => {
    return (
      doc.autoPrint(),
      doc.autoTable(RealData[i], mainData[i], {
        startY: i === 0 ? margin + thisMargin : margin + 900,
        styles: {
          fontSize: 8,
          minCellWidth: 60,
          minCellHeight: 10,
          margin: 40,
        },
      })
    )
  });
}
export const getExportData = (rows = [], columns = [], columnOrder: any, columnsRender: any) => {
  const header = [];
  const headerCSV = [];
  let body = [];
  let groups: any = [];

  if (columnOrder && columnOrder.length > 0) {
    for (let c = 0; c < columnOrder.length; c++) {
      let index = columns.findIndex((x: any) => x.id === columnOrder[c]);

      if(index > -1){
        let coll: any = columns[index];
  
        if (coll?.getIsVisible() && (coll.id != 'mrt-row-select') && (coll.id != 'mrt-row-expand')) {
          let colName = (coll.columnDef && coll.columnDef.header) ? coll.columnDef.header : coll.id ? coll.id : "";
          header.push(colName);
          headerCSV.push(colName);
        }
      }
    }
  }

  body = putExportData(rows, columns, columnOrder, columnsRender);

  return {
    header: header,
    headerCSV: headerCSV,
    body: body,
    groups: groups,
  };
}
export const putExportData = (array: any, columns: any, columnOrder: any, columnsRender: any) => {
  let body = [];

  if (array && array.length > 0) {
    for (let r = 0; r < array.length; r++) {
      let row = array[r];

      let rowArr = [];
      if (columnOrder && columnOrder.length > 0) {
        for (let c = 0; c < columnOrder.length; c++) {
          let indexColumns = columns.findIndex((x: any) => x.id === columnOrder[c]);
          if(indexColumns > -1){
            let coll = columns[indexColumns];
  
            if (coll?.getIsVisible() && coll.id != 'mrt-row-select') {
              if(columnsRender && columnsRender.length > 0){
                let index = columnsRender.findIndex((x: any) => x.id === coll.id);
  
                if(coll && coll.columnDef && index > -1){
                  if(coll.columnDef.PDF){
                    let value = coll?.columnDef?.PDF(row);
                    rowArr.push(value);
                  } else {
                    let value = row[coll.id];
                    rowArr.push(value);
                  }
                } else {
                  let value = row[coll.id];
                  rowArr.push(value);
                }
              } else {
                let value = row[coll.id];
                rowArr.push(value);
              }
            }
          }
        }
      }

      if (rowArr.length > 0) {
        body.push(rowArr);
      }
    }
  }

  return body;
}
