import React from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

// import { } from 'react-bootstrap';
// import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Spin } from "antd";

// Auth
// import { useAuthContext } from '../../../../auth/use-auth-context.js';
import { useRouter } from '../../../../auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector, Tables } from '../../../../utils/store.tsx';
import slice, { FormikContext, fields, initialValues, formSchema, prepareForm, prepareData } from './slice.tsx';

// enums
import { getIntProps, updateRow, createRow } from '../../../../utils/utils.tsx';
import { Permissions } from '../../../../utils/enums.tsx';

// layouts
import ManageLayout from '../../../../layouts/manage/Layout.tsx';

// components
import MainContainer from '../../../../components/container/MainContainer.jsx';
import createGenericSlice from '../../../../components/table/withSlice/tableSlice.tsx';

import Tab1 from './form/Tab1.tsx';
import Tab2 from './form/Tab2.tsx';
import Tab3 from './form/Tab3.tsx';
import Tab4 from './form/Tab4.tsx';
import Tab5 from './form/Tab5.tsx';

import { ReactComponent as ActiveTabIcon } from "../../../../../v5/assets/icons/active-tab.svg";
import { ReactComponent as InactiveTabIcon } from "../../../../../v5/assets/icons/inactive-tab.svg";
import { ReactComponent as PrevIcon } from "../../../../../v5/assets/icons/arrow-left.svg";
import { ReactComponent as NextIcon } from "../../../../../v5/assets/icons/arrow-right.svg";
import { ReactComponent as XIcon } from "../../../../../v5/assets/icons/smallX.svg";
import { ReactComponent as CheckIcon } from "../../../../../v5/assets/icons/check.svg";

// ----------------------------------------------------------------------

const table = createGenericSlice<any>(Tables.JobTypes);

const PageComponent = () => {
  // const location = useLocation();
  const router = useRouter();


  // const { user }: any = useAuthContext();
  // const { userAccount } = user ?? {};
  // const someField = userAccount?.someField;

  const { tab, isLoading, id, details } = useTypedSelector((state: RootState) => state.manageJobTypesSlice);
  const { tableData, tableTotal } = useTypedSelector((state: RootState) => state.tableJobTypes);
  const dispatch = useDispatch<AppDispatch>();

  const [backLink, _setBackLink] = React.useState<any>('/v5/manage/job/job-types');
  const [permission, _setPermission] = React.useState<any>([Permissions.ManageJobs[0]]);
  
  const [tabs, _setTabs] = React.useState<any>([
    {
      text: 'Job Type Info',
      eventKey: 'tab1',
      disabled: false,
    },
    {
      text: 'Job Steps',
      eventKey: 'tab2',
      disabled: false,
    },
    {
      text: 'Driver App Options',
      eventKey: 'tab3',
      disabled: false,
    },
    {
      text: 'Driver Permission',
      eventKey: 'tab4',
      disabled: false,
    },
    {
      text: 'Other',
      eventKey: 'tab5',
      disabled: false,
    },
  ]);


  React.useEffect(() => {
    let id = getIntProps();
    dispatch(slice.setId(id));
  }, []);

  React.useEffect(() => {
    if(id && id > 0){
      dispatch(slice.callDetailsApi(id, (state: boolean, data: any) => {}));
    }
  }, [id]);
    
  React.useEffect(() => {
    dispatch(slice.setLoading(true));
    let form = prepareForm(details, initialValues);
    setValues(form);
    dispatch(slice.setLoading(false));
  }, [details]);


  const formik = useFormik({
    initialValues: initialValues,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: formSchema(null),
    onSubmit: values => {
      onSubmit(values, (data: any) => {
        onCancel();
      });
    },
  });
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = formik;


  const onSubmit = (values: any, callback: (data: any) => void) => {
    let data = prepareData(values, id);
    if(id && id > 0){
      dispatch(slice.callUpdateApi(data, (state: boolean, data: any) => {
        if(state){
          let rows = updateRow(tableData, 'jobTemplateId', data);
          dispatch(table.callSetData({ data: rows, total: tableTotal}));
          callback(data);
        }
      }));
    } else {
      dispatch(slice.callCreateApi(data, (state: boolean, data: any) => {
        if(state){
          let rows = createRow(tableData, 'jobTemplateId', data);
          dispatch(table.callSetData({ data: rows, total: (tableTotal+1)}));
          callback(data);
        }
      }));
    }
  }
  const onCancel = () => {
    let form = prepareForm(null, initialValues);
    setValues(form);
    dispatch(slice.setValues(form));
    dispatch(slice.setId(null));
    dispatch(slice.resetSlice());
    router.push(backLink);
  }


  const getTabIndex = (x: string) => {
    return tabs.findIndex((y: any) => y.eventKey === x);
  }
  const getTabItem = (x: string) => {
    return tabs.find((y: any) => y.eventKey === x);
  }
  const getPrevTab = () => {
    let index = getTabIndex(tab);
    
    if(tabs && tabs.length > 0){
      try {
        let item = tabs[index - 1];

        if(item){
          return item.eventKey;
        } else {
          return tab;
        }
      } catch(e){
        return tab;
      }
    } else {
      return tab;
    }
  }
  const getNextTab = () => {
    let index = getTabIndex(tab);
    
    if(tabs && tabs.length > 0){
      try {
        let item = tabs[index + 1];

        if(item){
          return item.eventKey;
        } else {
          return tab;
        }
      } catch(e){
        return tab;
      }
    } else {
      return tab;
    }
  }
  const getFirstTab = () => {
    if(tabs && tabs.length > 0){
      try {
        let item = tabs[0];

        if(item){
          return item.eventKey;
        } else {
          return tab;
        }
      } catch(e){
        return tab;
      }
    } else {
      return tab;
    }
  }
  const getLastTab = () => {
    if(tabs && tabs.length > 0){
      try {
        let item = tabs[tabs.length-1];

        if(item){
          return item.eventKey;
        } else {
          return tab;
        }
      } catch(e){
        return tab;
      }
    } else {
      return tab;
    }
  }
  const isCurrentTab = (x: string) => {
    try {
      let tabItem = getTabItem(x);

      if(tabItem){
        return (tabItem.eventKey === tab);
      } else {
        return false;
      }
    } catch(e){
      return false;
    }
  }
  const isValidTab = (x: string) => {
    let tabIndex = getTabIndex(x);
    let currentTabIndex = getTabIndex(tab);

    if(tabIndex < currentTabIndex){
      return 'valid';
    } else {
      return '';
    }
  }
  const isInvalidTab = (x: string) => {
    let tabIndex = getTabIndex(x);
    let currentTabIndex = getTabIndex(tab);

    let cls = '';
    let invalidCls = 'invalid';
    let invalidCurrentCls = 'invalid-current';

    if(Object.entries(errors).length === 0){
      cls = '';
    } else {
      if(tabIndex < currentTabIndex){
        // cls = invalidCls;
        cls = '';
      } else if(tabIndex === currentTabIndex){
        cls = invalidCurrentCls;
      } else {
        cls = '';
      }
    }

    return cls;
  }

  const setContent = () => {
    let item = getTabItem(tab);

    if(item.eventKey === 'tab1'){
      return <Tab1 />
    } else if(item.eventKey === 'tab2'){
      return <Tab2 />
    } else if(item.eventKey === 'tab3'){
      return <Tab3 />
    } else if(item.eventKey === 'tab4'){
      return <Tab4 />
    } else if(item.eventKey === 'tab5'){
      return <Tab5 />
    } else {
      return <></>;
    }
  }


  return <MainContainer className={'p-0'} title={'Manage'} isLoading={false} roles={Permissions.ManageJobs}>
    <FormikContext.Provider value={formik}>
      <ManageLayout
        listName={'Create Job Type'}
        backLink={onCancel}
        isList={false}

        permission={permission}
        pages={[]}

        buttons={[]}

        defaultTab={tab}
        tabs={tabs.map((x: any) => {
          return {
            eventKey: x.eventKey,
            disabled: x.disabled,
            text: () => {
              if(x.eventKey === 'tab1'){
                return <div className={isValidTab(x.eventKey) + ' ' + isInvalidTab(x.eventKey)}>
                  {isCurrentTab(x.eventKey) ? <ActiveTabIcon /> : <InactiveTabIcon />}
                  <span className='ps-8'>{x.text}</span>
                </div>
              } else if(x.eventKey === 'tab2'){
                return <div className={isValidTab(x.eventKey) + ' ' + isInvalidTab(x.eventKey)}>
                  {isCurrentTab(x.eventKey) ? <ActiveTabIcon /> : <InactiveTabIcon />}
                  <span className='ps-8'>{x.text}</span>
                </div>
              } else if(x.eventKey === 'tab3'){
                return <div className={isValidTab(x.eventKey) + ' ' + isInvalidTab(x.eventKey)}>
                  {isCurrentTab(x.eventKey) ? <ActiveTabIcon /> : <InactiveTabIcon />}
                  <span className='ps-8'>{x.text}</span>
                </div>
              } else if(x.eventKey === 'tab4'){
                return <div className={isValidTab(x.eventKey) + ' ' + isInvalidTab(x.eventKey)}>
                  {isCurrentTab(x.eventKey) ? <ActiveTabIcon /> : <InactiveTabIcon />}
                  <span className='ps-8'>{x.text}</span>
                </div>
              } else if(x.eventKey === 'tab5'){
                return <div className={isValidTab(x.eventKey) + ' ' + isInvalidTab(x.eventKey)}>
                  {isCurrentTab(x.eventKey) ? <ActiveTabIcon /> : <InactiveTabIcon />}
                  <span className='ps-8'>{x.text}</span>
                </div>
              }
            },
            onClick: () => {
              validateForm().then((err: any) => {
                if(Object.entries(err).length === 0){
                  dispatch(slice.setTab(x.eventKey));
                }
              })
            }
          }
        })}

        footerButtons={[
          {
            text: <>
              <XIcon /><span className='px-2'>Cancel</span>
            </>,
            variant: 'custom-outlined',
            invisible: (tab !== getFirstTab()),
            active: false,
            onClick: () => {
              onCancel();
            }
          },
          {
            text: <>
              <PrevIcon /><span className='px-2'>Prev</span>
            </>,
            variant: 'custom-outlined',
            invisible: (tab === getFirstTab()),
            active: false,
            onClick: () => {
              validateForm()

              let tab = getPrevTab();
              dispatch(slice.setTab(tab));
            }
          },
          {
            text: <>
              <span className='px-2'>Next</span><NextIcon />
            </>,
            variant: 'custom-primary',
            invisible: (tab === getLastTab()),
            active: false,
            onClick: () => {
              validateForm().then((err: any) => {
                if(Object.entries(err).length === 0){
                  let tab = getNextTab();
                  dispatch(slice.setTab(tab));
                }
              })
            }
          },
          {
            text: <>
              <CheckIcon /><span className='px-2'>Done</span>
            </>,
            variant: 'custom-primary',
            invisible: (tab !== getLastTab()),
            active: false,
            onClick: () => {
              handleSubmit()
            }
          },
        ]}
      >
        <Spin spinning={isLoading} wrapperClassName={'no-height'}>{setContent()}</Spin>
      </ManageLayout>
    </FormikContext.Provider>
  </MainContainer>
}

export default PageComponent;
