import React from 'react';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import { Form, InputGroup } from 'react-bootstrap';
import { Select as SelectField, Spin } from 'antd';

import { axios } from '../../utils/axios';
import { successAPI, errorAPI } from '../../utils/utils';

import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";

// ----------------------------------------------------------------------

type Ref = {
  callReadApi: (apiPath: string, apiParam: any, apiCallback: (state: boolean, data: any) => void) => void,
  callCreateApi: (apiPath: string, apiParam: any, apiCallback: (state: boolean, data: any) => void) => void,
  callUpdateApi: (apiPath: string, apiParam: any, apiCallback: (state: boolean, data: any) => void) => void,
  callDeleteApi: (apiPath: string, apiParam: any, apiCallback: (state: boolean, data: any) => void) => void,
  getAllData: () => any,
};

const Select = ({ fieldNames, readResponseFunc = null, createResponseFunc = null, updateResponseFunc = null, searchable, onSearch, selectedIcon, onChangeValue, onDropdownVisibleChange, optionRender, disableActions, loading, options, selected, value, footer, ...props }: any) => {
  let selectedValue = (selected && selected[fieldNames.value] && selected[fieldNames.label]) ? selected : [];
  
  const searchRef = React.useRef<any>(null);

  const [isLoading, setIsLoading] = React.useState(loading || false);
  const [optionsData, setOptionsData] = React.useState(options || selectedValue);


  React.useEffect(() => {
    setIsLoading(loading)
  }, [loading]);

  React.useEffect(() => {
    setOptionsData(options)
  }, [options]);
  
  React.useEffect(() => {
    let selectedValue: any = (selected && selected[fieldNames.value] && selected[fieldNames.label]) ? selected : null;
    let newOptions: any = (optionsData && optionsData.length > 0) ? _.cloneDeep(optionsData) : [];

    if(props && props.mode && props.mode === 'multiple'){
      if(selectedValue){
        let selectedValues = selected[fieldNames.value];
        let selectedLabels = selected[fieldNames.label];

        for(let i = 0; i < selectedValues.length; i++){
          let index = (newOptions && newOptions.length > 0) ? newOptions.findIndex((x: any) => x[fieldNames.value] === selectedValues[i]) : -1;
          if(index > -1){
            newOptions[index][fieldNames.label] = selectedLabels[i];
          } else {
            newOptions.push({
              [fieldNames.value]: selectedValues[i],
              [fieldNames.label]: selectedLabels[i],
            });
          }
          setOptionsData(newOptions);
        }
      }

    } else {
      if(selectedValue){
        let index = (newOptions && newOptions.length > 0) ? newOptions.findIndex((x: any) => x[fieldNames.value] === selected[fieldNames.value]) : -1;
        if(index > -1){
          newOptions[index][fieldNames.label] = selected[fieldNames.label];
        } else {
          newOptions.push(selected);
        }
        setOptionsData(newOptions);
      }
    }
  }, [selected]);


  const ref = React.useRef<Ref>(null);
  React.useImperativeHandle(ref, () => ({
    callReadApi: async (apiPath: string, apiParam: any, apiCallback: (state: boolean, data: any) => void) => {
      setIsLoading(true);

      await axios.get(apiPath, { params: apiParam }).then(result => {
        let data = result.data;
        
        let options = [];
        if(readResponseFunc){
          options = readResponseFunc(data);
        } else {
          options = (data && data.data && data.data.length > 0) ? data.data : [];
        }

        setOptionsData(options);

        successAPI(data);

        if(apiCallback){
          apiCallback(true, data);
        }

        setIsLoading(false);
      }).catch(error => {
        errorAPI(error);
        
        if(apiCallback){
          apiCallback(false, null);
        }
        
        setIsLoading(false);
      });
    },
    callCreateApi: async (apiPath: string, apiParam: any, apiCallback: (state: boolean, data: any) => void) => {
      setIsLoading(true);

      await axios.post(apiPath, apiParam).then(result => {
        let data = result.data;
        
        let obj = null;
        if(createResponseFunc){
          obj = createResponseFunc(data);
        } else {
          obj = (data && data.data) ? data.data : null;
        }

        let options = _.cloneDeep(optionsData);
        options.unshift(obj);
        setOptionsData(options);
        
        successAPI(data);

        if(apiCallback){
          apiCallback(true, obj);
        }

        setIsLoading(false);
      }).catch(error => {
        errorAPI(error);
        
        if(apiCallback){
          apiCallback(false, null);
        }
        
        setIsLoading(false);
      });
    },
    callUpdateApi: async (apiPath: string, apiParam: any, apiCallback: (state: boolean, data: any) => void) => {
      setIsLoading(true);

      await axios.put(apiPath, apiParam).then(result => {
        let data = result.data;
        
        let obj = null;
        if(updateResponseFunc){
          obj = updateResponseFunc(data);
        } else {
          obj = (data && data.data) ? data.data : null;
        }

        let options = _.cloneDeep(optionsData);
        let index = options.findIndex((x: any) => x[fieldNames.value] === apiParam[fieldNames.value]);
        if(index > -1){
          options[index] = obj;
        }
        setOptionsData(options);

        successAPI(data);

        if(apiCallback){
          apiCallback(true, obj);
        }

        setIsLoading(false);
      }).catch(error => {
        errorAPI(error);
        
        if(apiCallback){
          apiCallback(false, null);
        }
        
        setIsLoading(false);
      });
    },
    callDeleteApi: async (apiPath: string, apiParam: any, apiCallback: (state: boolean, data: any) => void) => {
      setIsLoading(true);

      await axios.delete(apiPath, { data: apiParam }).then(result => {
        let data = result.data;
        
        let options = optionsData.filter((x: any) => x[fieldNames.value] !== apiParam[fieldNames.value]);

        setOptionsData(options);

        successAPI(data);

        if(apiCallback){
          apiCallback(true, data);
        }

        setIsLoading(false);
      }).catch(error => {
        errorAPI(error);
        
        if(apiCallback){
          apiCallback(false, null);
        }
        
        setIsLoading(false);
      });
    },
    clearSearch: () => {
      if(searchRef && searchRef.current){
        searchRef.current.value = '';
      }
    },
    focusSearch: () => {
      if(searchRef && searchRef.current){
        searchRef.current.focus();
      }
    },
    getAllData: () => {
      return (optionsData && optionsData.length > 0) ? optionsData : [];
    },
  }));


  const handleOnDropdownVisibleChange = (open: any) => {
    if(onDropdownVisibleChange){
      onDropdownVisibleChange(open, ref.current);
    }
  }

  const handleOnChangeValue = (value: any, option: any) => {
    if(onChangeValue){
      onChangeValue(value, option, ref.current);
    }
  }

  const handleOptionRender = (option: any): React.ReactNode => {
    if(optionRender){
      return optionRender(option, ref.current);
    }
  }

  const handleOnSearch = debounce((e: any) => {
    if(onSearch){
      onSearch(e, ref.current);
    }
  }, 800)

  
  return <SelectField
    className={'w-100'}
    variant={'borderless'}
    defaultActiveFirstOption={false}
    showSearch={false}
    value={value}
    loading={isLoading}
    options={optionsData}
    onDropdownVisibleChange={handleOnDropdownVisibleChange}
    onChange={handleOnChangeValue}
    optionRender={optionRender ? handleOptionRender : null}
    menuItemSelectedIcon={selectedIcon ? <CheckIcon /> : null}
    notFoundContent={<div className={'text-center'}>No Results!</div>}
    dropdownRender={(menu: any) => (
      <>
        {searchable && <div className='custom-select-search'>
          <InputGroup>
            <InputGroup.Text>
              <SearchIcon />
            </InputGroup.Text>
            <Form.Control
              ref={searchRef}
              type={'text'}
              autoComplete='off'
              placeholder={'Search'}
              disabled={isLoading || disableActions}
              isInvalid={false}
              onChange={(e: any) => handleOnSearch(e)}
            />
          </InputGroup>
        </div>}
        {searchable && <hr className='m-1' />}
        {isLoading ? <div className='text-center mt-3 mb-3'><Spin /></div> : <div className='custom-select-menu'>{menu}</div>}
        {footer && <hr className='m-1' />}
        {footer && <div className='custom-select-footer'>{(typeof footer === 'function') ? footer(isLoading, ref.current) : footer}</div>}
      </>
    )}
    fieldNames={fieldNames}
    {...props}
  />
};

export default Select;