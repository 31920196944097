import React from 'react';

import {
  Button,
  Dropdown,
} from 'react-bootstrap';
import styled from 'styled-components';

import { CustomMenu } from '../dropdown/Dropdown.jsx';

import { 
  Popover,
} from 'antd';

import { ReactComponent as DotsIcon } from "../../assets/icons/dots.svg";

// ----------------------------------------------------------------------

const StyledDiv = styled.div<any>`
  ${(props: any) => {
    if(props.width){
      return 'min-width: ' + props.width + ';'
    } else {
      return 'min-width: 150px;'
    } 
  }}

  .list-unstyled {
    .dropdown-item {
      align-items: center;
      display: inline-flex;

      svg {
        position: relative;
        width: 18px;
        height: 18px;
        top: -2px;
      }
    }
  }
`;

const TableActions = React.forwardRef(({ disabled, children, width }: any, ref: any) => {
  const [open, setOpen] = React.useState(false);

  React.useImperativeHandle(
    ref,
    () => ({
      hide() {
        setOpen(false);
      },
    }),
  );

  return <Popover
    open={open}
    onOpenChange={(newOpen: boolean) => {
      setOpen(newOpen);
    }}

    content={<StyledDiv width={width}>
      <Dropdown.Menu show as={CustomMenu} style={{ minWidth: width }}>{children}</Dropdown.Menu>
    </StyledDiv>}
    arrow={false}
    placement="bottomRight"
    trigger="click"
  >
    <Button
      variant={'custom-none-secondary'}
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <DotsIcon />
    </Button>
  </Popover>
})

export default TableActions