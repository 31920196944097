import { Helmet } from 'react-helmet-async';
// ----------------------------------------------------------------------

export default function PermissionPage() {
  return (
    <>
      {/* <Helmet>
        <title>Permission Denied!</title>
      </Helmet> */}

      <>
        <h2>Oops, permission denied!</h2>
        <p>You do not have permission to access this page.</p>
        <a href={'/'} className="btn btn-primary">Go back</a>
      </>
    </>
  );
}
