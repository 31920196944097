/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import { axios } from '../../../../utils/axios';
import { successAPI, errorAPI, convertLatLngToCoord } from '../../../../utils/utils';
import Yup from '../../../../utils/yup';
// import {  } from '../../../../utils/enums';

import { fields } from '../slice';


export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    siteName: Yup.string().required().label(fields.siteName.label),
    street: Yup.string().label(fields.street.label),
    blockNo: Yup.string().label(fields.blockNo.label),
    postalCode: Yup.string().label(fields.postalCode.label),
    coordinates: Yup.string().required().label(fields.coordinates.label),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null, defValues: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    let latitude = (form && form.latitude && form.latitude !== '') ? form.latitude : '';
    let longitude = (form && form.longitude && form.longitude !== '') ? form.longitude : '';
    let coordinates = convertLatLngToCoord(latitude, longitude);

    let customSiteName = (form.customSiteName && form.customSiteName !== '') ? form.customSiteName : '';
    let siteName = (form.siteName && form.siteName !== '') ? form.siteName : '';
    let street = (form.street && form.street !== '') ? form.street : '';
    let blockNo = (form.blockNo && form.blockNo !== '') ? form.blockNo : '';
    let postalCode = (form.postalCode && form.postalCode !== '') ? form.postalCode : '';
    

    data['latitude'] = latitude;
    data['longitude'] = longitude;
    data['coordinates'] = coordinates;

    data['customSiteName'] = customSiteName;
    data['siteName'] = siteName;
    data['street'] = street;
    data['blockNo'] = blockNo;
    data['postalCode'] = postalCode;
  }
  
  return data;
};
export const prepareData = (values: any = null, id: any = null) => {
  let data: any = {};

  let form = _.cloneDeep(values);

  if(form){
    let latitude = form.latitude;
    let longitude = form.longitude;
    let coordinates = (form && form.coordinates && form.coordinates != '') ? form.coordinates.split(',') : [];
    latitude = (coordinates && coordinates.length > 0 && coordinates[0]) ? coordinates[0] : form.latitude;
    longitude = (coordinates && coordinates.length > 0 && coordinates[1]) ? coordinates[1] : form.longitude;


    data['latitude'] = latitude;
    data['longitude'] = longitude;
    data['coordinates'] = form.coordinates;

    data['customSiteName'] = form.customSiteName;
    data['siteName'] = form.siteName;
    data['street'] = form.street;
    data['blockNo'] = form.blockNo;
    data['postalCode'] = form.postalCode;
  }

  return data;
};


export interface initialValuesStruct {
  customSiteName: string,
  siteName: string,
  street: string,
  blockNo: string,
  postalCode: string,
  latitude: string,
  longitude: string,
  coordinates: string,
};
export const initialValues: initialValuesStruct = {
  customSiteName: '',
  siteName: '',
  street: '',
  blockNo: '',
  postalCode: '',
  latitude: '',
  longitude: '',
  coordinates: '',
};


interface InitState {
  isLoading: boolean,
  show: boolean,
  details: any,
}

function NewReducer() {
  const name = 'customersSiteChangeLocationSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    details: initialValues,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },
  };


  const apis = {
    
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();