import _ from 'lodash';

import { danger, success } from '../utils';

// API ----------------------------------------------------------------------

export interface ReadAPIParams {
  currentPage?: number|null,
  pageSize?: number|null,
  sortColumn?: any|null,
  sortDir?: string|null,
  searchQuery?: string|null,
  isIncludeInactive?: boolean|null,
  isInactive?: boolean|null,
}

export const getMaxPageSize = () => {
  return 99999;
};
export const getDefaultPageSize = (x: number = 10) => {
  return x;
};
export const getMinDate = () => {
  return '0001-01-01T00:00:00';
};
export const getMaxDate = () => {
  return '9999-12-31T23:59:59';
};

export const successAPI = (data: any) => {
  if(typeof data === 'object'){
    let message = (data && data.message && data.message != '') ? data.message : null;
    if(typeof message === 'string'){
      success('Success', message);
    }
  }
};
export const errorAPI = (error: any) => {
  if(typeof error === 'object'){
    let err = (error && error.error) ? error.error : null;
    danger('Error', err);
  } else if(typeof error === 'string'){
    danger('Error', error);
  }
};
