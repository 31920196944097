import { useMemo } from 'react';
// import { useNavigate, useLocation, useSearchParams as _useSearchParams } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuthContext } from './use-auth-context';

import PropTypes from 'prop-types';
import { useEffect, useCallback, useState } from 'react';

import { LOGIN_PATH } from '../utils/enums';

// ----------------------------------------------------------------------

export function useRouter() {
  // const navigate = useNavigate();
  const history = useHistory();
  const location = useLocation();

  const router = useMemo(
    () => ({
      back: () => history.goBack(),
      forward: () => history.goForward(),
      reload: () => window.location.reload(),
      push: (href) => history.push(href),
      pushArgs: (href, params) => history.push(href, { state: params }),
      getArgs: () => (location && location?.state) ? location?.state : null,
      replace: (href) => history.replace(href),
      open: (href) => window.open(href, '_blank'),
    }),
    [history, location]
  );

  return router;
}

export function useSearchParams() {
  // const [searchParams] = _useSearchParams();
  const location = useLocation();

  // return useMemo(() => searchParams, [searchParams]);
  return useMemo(() => new URLSearchParams(location.search), [location.search]);
}

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
  const router = useRouter();

  const { authenticated } = useAuthContext();

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!authenticated) {
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString();

      const href = `${LOGIN_PATH}?${searchParams}`;

      router.replace(href);
    } else {
      setChecked(true);
    }
  }, [authenticated, router]);

  useEffect(() => {
    check();
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};
