import React from 'react';

import styled from 'styled-components';

import { ColorPicker as Picker } from 'antd';
import type { ColorPickerProps, GetProp } from 'antd';

// ----------------------------------------------------------------------

const StyledCircleWrapper = styled.span`
  position: relative;
`;
const StyledCircle = styled.span`
  position: relative;
  top: 3px;
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
`;


type Color = GetProp<ColorPickerProps, 'value'>;

export default function ColorPicker({value, onChange, ...props}: any) {
  const [color, setColor] = React.useState(value);


  React.useEffect(() => {
    setColor(value);
  }, [value]);


  return <Picker
    arrow={false}
    value={color}
    onChangeComplete={(val: Color) => {
      let hexColor = (typeof val === 'string' ? val : val!.toHexString());
      setColor(hexColor);

      if(onChange){
        onChange(hexColor);
      }
    }}
    {...props}
  >
    <StyledCircleWrapper>
      <StyledCircle style={{ backgroundColor: color }}></StyledCircle>
    </StyledCircleWrapper>
  </Picker>
}
