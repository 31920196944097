/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import { axios } from '../../../../utils/axios';
import { ReadAPIParams, successAPI, errorAPI, placeholderRows } from '../../../../utils/utils';
import Yup from '../../../../utils/yup';
import { XeroPaymentTermType } from '../../../../utils/enums';

import { fields } from '../slice';


export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    isActive: Yup.bool().oneOf([true, false]),
    accountBillingProfileId: Yup.number().nullable().required().label(fields.accountBillingProfileId.label),
    paymentTermsId: Yup.number().nullable().label(fields.paymentTermsId.label),

    isConnectXero: Yup.bool().oneOf([true, false]),
    xeroPaymentTerm: Yup.number().when(['isConnectXero', 'xeroPaymentTermType'], ([isConnectXero, xeroPaymentTermType]: any) => {
      if(isConnectXero){
        if((xeroPaymentTermType == XeroPaymentTermType.OF_CURRENT_MONTH) || (xeroPaymentTermType == XeroPaymentTermType.OF_FOLLOWING_MONTH)){
              return Yup.number().nullable().required().positive().min(0).max(31, fields.xeroPaymentTermType.error).label(fields.xeroPaymentTerm.label);
          } else {
              return Yup.number().nullable().required().positive().min(0).label(fields.xeroPaymentTerm.label);
          }
      } else {
        return Yup.number().nullable().positive().min(0).label(fields.xeroPaymentTerm.label);
      }
    }),
    xeroPaymentTermType: Yup.number().when(['isConnectXero'], ([isConnectXero]: any) => {
      if(isConnectXero){
        return Yup.number().nullable().required().positive().min(0).label(fields.xeroPaymentTermType.label);
      } else {
        return Yup.number().nullable().label(fields.xeroPaymentTermType.label);
      }
    }),

    creditLimit: Yup.number().nullable().min(0).label(fields.creditLimit.label),
    isRequirePaymentCollection: Yup.bool().oneOf([true, false]),
    customerCategoryId: Yup.number().nullable().label(fields.customerCategoryId.label),
    note: Yup.string().label(fields.note.label),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (item: any = null) => {
  if(item){
    let initValues = _.cloneDeep(initialValues);

    let quickBookApp = (item && item.quickBookApp) ? item.quickBookApp : null;
    let isConnectQuickbook = (item && item.isConnectQuickbook) ? item.isConnectQuickbook : false;
    let isConnectXero = (item && item.isConnectXero) ? item.isConnectXero : false;
    let quickBookAppId = (quickBookApp && quickBookApp.quickBookAppId) ? quickBookApp.quickBookAppId : 0;

    initValues.accountBillingProfileId = item.accountBillingProfileId;
    initValues.billingProfileName = item.billingProfileName;
    initValues.quickBookAppId = quickBookAppId;
    initValues.isConnectQuickbook = isConnectQuickbook;
    initValues.isConnectXero = isConnectXero;

    if(item.isConnectXero){
      let xeroPaymentTermType = (item && item.xeroPaymentTermType) ? item.xeroPaymentTermType.toString() : '0';
      let xeroPaymentTerm = (item && item.xeroPaymentTerm) ? item.xeroPaymentTerm.toString() : '0';

      initValues.xeroPaymentTermType = xeroPaymentTermType;
      initValues.xeroPaymentTerm = xeroPaymentTerm;
    }
    
    if(item.isConnectQuickbook){
      initValues.paymentTermsId = item.paymentTermsId;
      initValues.paymentTermsName = item.paymentTermsName;
    }

    return initValues;
  } else {
    return initialValues;
  }
};
export const prepareData = (values: any = null, ids: string = '') => {
  let data: any = {};

  if(values){
    data['ids'] = ids;

    data['isActive'] = values.isActive;

    data['accountBillingProfileId'] = values.accountBillingProfileId;

    let category = null;
    if(values.customerCategoryId){
      category = {
        customerCategoryName: values.customerCategoryName,
      }
      data['category'] = category;
    }

    data['quickBookDefaultTermId'] = values.paymentTermsId;
    data['creditLimit'] = values.creditLimit;
    data['isRequirePaymentCollection'] = values.isRequirePaymentCollection;
    data['customerCategoryId'] = values.customerCategoryId;
    data['note'] = values.note;

    
    if(values.accountBillingProfileId){
      data['xeroPaymentTerm'] = values.xeroPaymentTerm;
      data['xeroPaymentTermType'] = values.xeroPaymentTermType;
    } else {
        data['xeroPaymentTerm'] = null;
    }

    if(values.isConnectQuickbook){
      delete data['xeroPaymentTerm'];
      delete data['xeroPaymentTermType'];
    }
  }

  return data;
};


export interface initialValuesStruct {
  isActive: boolean,

  accountBillingProfileId: any,
  billingProfileName: string,

  paymentTermsId: any,
  paymentTermsName: string,

  xeroPaymentTerm: any,
  xeroPaymentTermType: any,

  customerCategoryId: any,
  customerCategoryName: string,
  
  creditLimit: any,
  isRequirePaymentCollection: boolean,
  note: string,

  isConnectQuickbook: boolean,
  isConnectXero: boolean,
  quickBookAppId: any,
};
export const initialValues: initialValuesStruct = {
  isActive: true, 

  accountBillingProfileId: null,
  billingProfileName: '',

  paymentTermsId: null,
  paymentTermsName: '',

  xeroPaymentTerm: '0',
  xeroPaymentTermType: XeroPaymentTermType.DAYS_AFTER_BILL_DATE,

  customerCategoryId: null,
  customerCategoryName: '',
  
  creditLimit: null,
  isRequirePaymentCollection: false,
  note: '',

  isConnectQuickbook: false,
  isConnectXero: false,
  quickBookAppId: 0,
};


interface InitState {
  isLoading: boolean,
  show: boolean,
  ids: any|null,
  details: any,
  billingProfiles: any,

  isLoadingQuickbookCreditTerms: boolean,
  quickbookCreditTerms: Array<any>,
}

function NewReducer() {
  const name = 'customersBulkEditSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    ids: null,
    details: initialValues,
    billingProfiles: [],

    isLoadingQuickbookCreditTerms: false,
    quickbookCreditTerms: [],
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, ids: any|null}>) => {
      state.ids = action.payload.ids;
      state.show = action.payload.show;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startUpdate: (state: InitState) => {
      state.isLoading = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },
    
    startReadBillingProfiles: (state: InitState) => {
      state.isLoading = true;
      state.billingProfiles = [];
    },
    finishReadBillingProfiles: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      state.billingProfiles = data;
    },

    startReadQuickbookCreditTerms: (state: InitState) => {
      state.isLoadingQuickbookCreditTerms = true;
      state.quickbookCreditTerms = [];
    },
    finishReadQuickbookCreditTerms: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingQuickbookCreditTerms = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      state.quickbookCreditTerms = data;
    },
  };


  const apis = {
    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdate());
  
        await axios.put('customer/bulk', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishUpdate(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdate(null));
        });
    },
    
    callReadBillingProfilesApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadBillingProfiles());

      await axios.get('AccountBillingProfile', { params: params }).then(result => {
          let data = result.data;
          
          successAPI(data);

          callback(true, data);
          dispatch(actions.finishReadBillingProfiles(data));
      }).catch(error => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishReadBillingProfiles(null));
      });
    },

    callReadQuickbookCreditTermsApi: (quickBookAppId: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startReadQuickbookCreditTerms());
  
        let params = {
          quickBookAppId: quickBookAppId,
        };

        await axios.get('quickbookapp/term', { params: params }).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishReadQuickbookCreditTerms(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishReadQuickbookCreditTerms(null));
        });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();