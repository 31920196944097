import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  Row,
  Col,
  Button,
  Form,
  Modal,
} from 'react-bootstrap';
import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';

import { Spin, Radio } from 'antd';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../utils/store.tsx';
import slice, { DownloadDocument, DownloadDateRange, defaultRange, today, currentMonth, last7Days, lastMonth } from './downloadSlice.tsx';
import downloadSlice from './downloadSlice.tsx';

// Auth
// import { useAuthContext } from '../../auth/use-auth-context.js';

// components
// import Select from '../../components/select/Select.tsx';
// import SmartSelect from '../../components/select/SmartSelect.tsx';
import RangePicker from "../../components/dateRangePicker/DateRangePicker.jsx";

import { apiDateFormat, getSelectedRangeDate, downloadEXCEL, generatePDF } from '../../utils/utils.tsx';
import { MAIN_BREAKPOINT } from '../../utils/enums.tsx';

import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";

// ----------------------------------------------------------------------

const StyledModal = styled(Modal)`
  .modal-dialog {
    ${up(MAIN_BREAKPOINT)} {
      min-width: 448px;
    }

    ${down(MAIN_BREAKPOINT)} {
      min-width: none;
    }
  }
`;

const StyledRadio = styled(Radio)`
  width: 100%;

  span:nth-child(1) {
    width: 17px;
  }
  span:nth-child(2) {
    width: 100%;
  }
`;


const DownloadModal = (props: any) => {
  const { show, document, dateRange, isLoading, statusFilter, fileName } = useTypedSelector((state: RootState) => state.downloadSlice);
  const dispatch = useDispatch<AppDispatch>();

  const refRange = React.useRef<any>(null);
  const [ranges, setRanges] = React.useState<any>([defaultRange]);


  // React.useEffect(() => {
  //   if(show){
      
  //   }
  // }, [show]);


  const onCancel = () => {
    dispatch(slice.setShow({ show: false, statusFilter: null, fileName: '' }));
  }

  const onDownload = () => {
    let fromDate = null;
    let toDate = null;

    if(dateRange === DownloadDateRange.All){
      fromDate = null;
      toDate = null;

    } else if(dateRange === DownloadDateRange.Today){
      fromDate = moment(today.startDate).format(apiDateFormat());
      toDate = moment(today.endDate).format(apiDateFormat());
      
    } else if(dateRange === DownloadDateRange.CurrentMonth){
      fromDate = moment(currentMonth.startDate).format(apiDateFormat());
      toDate = moment(currentMonth.endDate).format(apiDateFormat());
      
    } else if(dateRange === DownloadDateRange.Last7Days){
      fromDate = moment(last7Days.startDate).format(apiDateFormat());
      toDate = moment(last7Days.endDate).format(apiDateFormat());
      
    } else if(dateRange === DownloadDateRange.LastMonth){
      fromDate = moment(lastMonth.startDate).format(apiDateFormat());
      toDate = moment(lastMonth.endDate).format(apiDateFormat());
      
    } else if(dateRange === DownloadDateRange.Custom){
      let selectedRange = getSelectedRangeDate(ranges);
      
      fromDate = (selectedRange && selectedRange.fromDate) ? selectedRange.fromDate : null;
      toDate = (selectedRange && selectedRange.toDate) ? selectedRange.toDate : null;
    }

    let methodExcel = (props && props.methodExcel && props.methodExcel != '') ? props.methodExcel : '';
    let pathExcel = (props && props.pathExcel && props.pathExcel != '') ? props.pathExcel : '';
    let paramsExcel = (props && props.paramsExcel) ? props.paramsExcel : null;

    let methodPdf = (props && props.methodPdf && props.methodPdf != '') ? props.methodPdf : '';
    let pathPdf = (props && props.pathPdf && props.pathPdf != '') ? props.pathPdf : '';
    let paramsPdf = (props && props.paramsPdf) ? props.paramsPdf : null;

    let columns = (props && props.columns) ? props.columns : null;
    let columnOrder = (props && props.columnOrder) ? props.columnOrder : null;
    let columnsRender = (props && props.columnsRender) ? props.columnsRender : null;
    
    let obj = {
      fromDate: fromDate,
      toDate: toDate,
      statusFilter: statusFilter,
    }

    if(document === DownloadDocument.Excel){
      let extraParams = Object.assign({}, paramsExcel, obj);

      dispatch(slice.callDownloadApi(methodExcel, pathExcel, extraParams, (state: boolean, data: any) => {
        if(state){
          downloadEXCEL(fileName, data);
          onCancel();
        }
      }));

    } else if(document === DownloadDocument.Pdf){
      let extraParams = Object.assign({}, paramsPdf, obj);

      dispatch(slice.callDownloadApi(methodPdf, pathPdf, extraParams, (state: boolean, data: any) => {
        if(state){
          let rows = (data && data.data && data.data.length > 0) ? data.data : [];
          generatePDF(fileName, rows, columns, columnOrder, columnsRender);
          onCancel();
        }
      }));

    } else {
      onCancel();
    }
  }


  const setForm = () => {
    return <Row className='g-28'>
      <Col xs={12}>
        <Row className='g-8'>
          <Col xs={12}>
            <Form.Label className={'m-0'}>Document</Form.Label>
          </Col>
          <Col xs={12}>
            <Radio
              className='pe-48'
              name={'document'}
              checked={(document === DownloadDocument.Excel) ? true : false}
              onChange={(e) => {
                dispatch(downloadSlice.setDocument(DownloadDocument.Excel));
              }}
            >Excel</Radio>
            <Radio
              name={'document'}
              checked={(document === DownloadDocument.Pdf) ? true : false}
              onChange={(e) => {
                dispatch(downloadSlice.setDocument(DownloadDocument.Pdf));
              }}
            >PDF</Radio>
          </Col>
        </Row>
      </Col>

      <Col xs={12}>
        <Row className='g-8'>
          <Col xs={12}>
            <Form.Label className={'m-0'}>Date range</Form.Label>
          </Col>
          <Col xs={12}>
            <Radio
              name={'dateRange'}
              checked={(dateRange === DownloadDateRange.All) ? true : false}
              onChange={(e) => {
                dispatch(downloadSlice.setDateRange(DownloadDateRange.All));
              }}
            >All</Radio>
          </Col>
          <Col xs={12}>
            <StyledRadio
              name={'dateRange'}
              checked={(dateRange === DownloadDateRange.Today) ? true : false}
              onChange={(e) => {
                dispatch(downloadSlice.setDateRange(DownloadDateRange.Today));
              }}
            >
              <Row>
                <Col xs={6}>Today</Col>
                <Col xs={6} className='text-muted'>{today.startDate.format('MMM D')}</Col>
              </Row>
            </StyledRadio>
          </Col>
          <Col xs={12}>
            <StyledRadio
              name={'dateRange'}
              checked={(dateRange === DownloadDateRange.CurrentMonth) ? true : false}
              onChange={(e) => {
                dispatch(downloadSlice.setDateRange(DownloadDateRange.CurrentMonth));
              }}
            >
              <Row>
                <Col xs={6}>Current month</Col>
                <Col xs={6} className='text-muted'>{currentMonth.startDate.format('MMM D')} - {currentMonth.endDate.format('MMM D')}</Col>
              </Row>
            </StyledRadio>
          </Col>
          <Col xs={12}>
            <StyledRadio
              name={'dateRange'}
              checked={(dateRange === DownloadDateRange.Last7Days) ? true : false}
              onChange={(e) => {
                dispatch(downloadSlice.setDateRange(DownloadDateRange.Last7Days));
              }}
            >
              <Row>
                <Col xs={6}>Last 7 days</Col>
                <Col xs={6} className='text-muted'>{last7Days.startDate.format('MMM D')} - {last7Days.endDate.format('MMM D')}</Col>
              </Row>
            </StyledRadio>
          </Col>
          <Col xs={12}>
            <StyledRadio
              name={'dateRange'}
              checked={(dateRange === DownloadDateRange.LastMonth) ? true : false}
              onChange={(e) => {
                dispatch(downloadSlice.setDateRange(DownloadDateRange.LastMonth));
              }}
            >
              <Row>
                <Col xs={6}>Last month</Col>
                <Col xs={6} className='text-muted'>{lastMonth.startDate.format('MMM D')} - {lastMonth.endDate.format('MMM D')}</Col>
              </Row>
            </StyledRadio>
          </Col>
          <Col xs={12}>
            <Radio
              name={'dateRange'}
              checked={(dateRange === DownloadDateRange.Custom) ? true : false}
              onChange={(e) => {
                dispatch(downloadSlice.setDateRange(DownloadDateRange.Custom));
              }}
            >Custom</Radio>
          </Col>
          {(dateRange === DownloadDateRange.Custom) && <Col xs={12}>
            <RangePicker
              ref={refRange}
              icon={<CalendarIcon />}
              placement={'bottom'}
              ranges={ranges}
              onChange={(item: any) => {
                setRanges([item.selection]);

                // if(refRange && refRange.current){
                //   refRange.current.hide();
                // }
              }}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              showDateDisplay={false}
              months={1}
              direction="horizontal"
            />
          </Col>}
        </Row>
      </Col>
    </Row>
  }

  
  return <StyledModal
    show={show}
    onHide={() => {
      onCancel();
    }}
    backdrop={'static'}
    keyboard={false}
    enforceFocus={false}
    centered={true}
    size={'sm'}
  >
    <Modal.Header closeButton>
      <h6 className='fw-medium'>{(props && props.title && props.title != '') ? props.title : 'Export'}</h6>
    </Modal.Header>

    <Spin spinning={isLoading} wrapperClassName={'no-height'}>
      <Modal.Body>
        <div className='card-modal'>{setForm()}</div>
      </Modal.Body>
    </Spin>

    <Modal.Footer>
      <Row className='gx-12'>
        <Col xs={true}>
          <Button
            variant={'custom-text'}
            disabled={isLoading}
            onClick={() => {
              onCancel();
            }}
          >Cancel</Button>
        </Col>
        <Col xs={'auto'}>
          <Button
            variant={'custom-primary'}
            disabled={isLoading}
            onClick={() => {
              onDownload();
            }}
          >Download</Button>
        </Col>
      </Row>
    </Modal.Footer>
  </StyledModal>
}

export default DownloadModal;
