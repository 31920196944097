import React from 'react';

import {
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap';
// import styled from 'styled-components';

import { 
  Popover,
} from 'antd';

// import { useTimezoneSelect, allTimezones } from 'react-timezone-select'

import Select from '../../components/select/Select.tsx';

import { ReactComponent as XIcon } from "../../assets/icons/x.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";

// ----------------------------------------------------------------------

export default function EditTimeZone({ value, required, onUpdate }: any) {
  // const { options, parseTimezone } = useTimezoneSelect({ labelStyle: 'original', timezones: allTimezones })
  let options: any = [];

  const [open, setOpen] = React.useState(false);
  const [val, setVal] = React.useState(value);
  const [data, setData] = React.useState(options);
  const inputRef = React.useRef<HTMLInputElement>(null);


  React.useEffect(() => {
    setVal(value);

    if(open){
      setTimeout(() => {
        if(inputRef && inputRef.current){
          inputRef.current.focus();
        }
      }, 200);
    }
  }, [open]);


  const hide = () => {
    setOpen(false);
  };

  const update = () => {
    if(required === true){
      if(val != ''){
        if(onUpdate){
          // let opt = parseTimezone(val);
          // onUpdate(val, opt);
        }

        hide();
      }
    } else {
      if(onUpdate){
        // let opt = parseTimezone(val);
        // onUpdate(val, opt);
      }
      
      hide();
    }
  };

  return <Popover
    open={open}
    onOpenChange={(newOpen: boolean) => {
      setOpen(newOpen);
    }}

    content={<div style={{ minWidth: '300px', maxWidth: '300px' }}>
      <Row className='align-items-center g-0 p-16 pb-0'>
        <Col xs={true} className='fw-medium text-center'>Edit time zone</Col>
        <Col xs={'auto'}>
          <Button
            className='mb-1'
            size={'sm'}
            variant={'custom-none-primary'}
            onClick={(e) => { 
              e.preventDefault();
              e.stopPropagation();

              hide();
            }}
          >
            <XIcon />
          </Button>
        </Col>
      </Row>
      
      <Row className='align-items-center g-0 p-16 pt-18'>
        <Col xs={12}>
          <Form.Group>
            <Form.Label className='xsmall fw-bold'>Time zone</Form.Label>
            <Form.Control
              as={Select}
              isInvalid={(required === true) ? !(val != '') : false}
              value={val}
              placeholder={'Select time zone'}
              searchable={true}
              allowClear={false}
              selectedIcon={true}
              loading={false}
              onChangeValue={(value: any, option: any, ref: any) => {
                setVal(value)
              }}
              onDropdownVisibleChange={(open: any, ref: any) => {
                if(open){
                  // ref.clearSearch();
                  // setTimeout(() => {
                  //   ref.focusSearch();
                  // }, 200);
                  // setData(options);
                }
              }}
              onSearch={(e: any, ref: any) => {
                // let newOptions = options.filter((x: any) => x.label.toLowerCase().trim().includes(e.target.value.toLowerCase().trim()));
                // setData(newOptions);
              }}
              options={data}
              fieldNames={{ value: 'value', label: 'label' }}
            />
            <Form.Control.Feedback type="invalid">Time zone is required</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      
      <Row className='align-items-center g-0 p-16' style={{ backgroundColor: 'var(--bs-blue-focus1)' }}>
        <Col xs={12}>
          <Button
            variant={'custom-primary'}
            disabled={(required === true) ? !(val != '') : false}
            onClick={() => {
              update();
            }}
          >Update</Button>
        </Col>
      </Row>
    </div>}
    arrow={false}
    placement="rightTop"
    trigger="click"
  >
    <Button
      className={'ps-1'}
      size={'sm'}
      variant={'custom-none-primary'}
    >
      <EditIcon />
    </Button>
  </Popover>
}
