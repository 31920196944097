import _ from 'lodash';

// Validation ----------------------------------------------------------------------

export const twoDecimalValidation = (value: any) => {
  if (!value) return true;
  return /^\d+(\.\d{1,2})?$/.test(value);
}

export const isNumeric = (value: any) => {
  return !isNaN(parseFloat(value)) && isFinite(value)
}
