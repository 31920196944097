import Autocomplete from "react-google-autocomplete";

export const {
    REACT_APP_GOOGLE_MAP_API_KEY
} = process.env;


const GoogleSearchAddress = ({ onPlaceSelected, ...props}: any) => {
    return <Autocomplete
        apiKey={REACT_APP_GOOGLE_MAP_API_KEY}
        onPlaceSelected={onPlaceSelected}
        options={{
            types: ["geocode"],
        }}
        libraries={['places']}
        {...props}
    />
};

export default GoogleSearchAddress;
