import _ from 'lodash';

import { getUserCookies, convertJsonFromString } from '../utils';

// Table ----------------------------------------------------------------------

export const flashById = (id: string, anim = 'flash-update') => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      try {
        let el = document.getElementById("row_" + id);
        el?.classList.add(anim);
        setTimeout(() => {
          try {
            el?.classList.remove(anim);
          } catch (err) {}

          resolve();
        }, 1500);
      } catch (err) {}
    }, 500);
  });
};
export const flash = (item: any, id: string, anim = 'flash-update') => {
  return new Promise<void>((resolve) => {
    if(item instanceof Array){
      item.forEach((itm, i) => {
        setTimeout(() => {
          try {
            let el = document.getElementById("row_" + itm[id]);
            el?.classList.add(anim);
            setTimeout(() => {
              try {
                el?.classList.remove(anim);
              } catch (err) {}

              resolve();
            }, 1500);
          } catch (err) {}
        }, 500);
      });
    } else {
      setTimeout(() => {
        try {
          let el = document.getElementById("row_" + item[id]);
          el?.classList.add(anim);
          setTimeout(() => {
            try {
              el?.classList.remove(anim);
            } catch (err) {}

            resolve();
          }, 1500);
        } catch (err) {}
      }, 500);
    }
  });
};
export const removeRow = async (rows: Array<any>, id: string, item: any) => {
  let newRows = _.cloneDeep(rows);

  if(item instanceof Array){
    newRows = newRows.filter((row: any) => !item.some((x) => x[id] === row[id]));
  } else {
    newRows = newRows.filter((x: any) => x[id] !== item[id]);
  }

  await flash(item, id, 'flash-remove');
  return newRows;
}
export const createRow = (rows: Array<any>, id: string, item: any) => {
  let newRows = _.cloneDeep(rows);
  newRows.unshift(item);
  flash(item, id, 'flash-create');
  return newRows;
}
export const updateRow = (rows: Array<any>, id: string, item: any) => {
  let newRows = _.cloneDeep(rows);

  if(item instanceof Array){
    item.forEach((itm) => {
      const index = newRows.findIndex((x: any) => x[id] === itm[id]);
      if (index !== -1) {
        newRows.splice(index, 1, itm);
      }
    });

  } else {
    const index = newRows.findIndex((x: any) => x[id] === item[id]);
    if(index !== -1){
      newRows.splice(index, 1, item);
    }
  }

  flash(item, id, 'flash-update');
  return newRows;
}
export const updateField = (rows: Array<any>, id: string, item: any, field: any, value: any) => {
  let newRows = _.cloneDeep(rows);

  const index = newRows.findIndex((x: any) => x[id] === item[id]);
  if(index !== -1){
    let newItem = _.cloneDeep(item);
    newItem[field] = value;
    newRows.splice(index, 1, newItem);
  }

  return newRows;
}

export const getUserCookieSettings = (settingsType: any = null, pageName: any = null) => {
  let item = null;
  let settings = getUserCookies();

  if ((settings && settings.length > 0) && (settingsType !== null)) {
    let index = getUserCookieSettingsIndex(settings, settingsType, pageName);
    if (index !== -1 && settings[index] && settings[index].value && settings[index].value !== '') {
      item = convertJsonFromString(settings[index].value);
    }
  }

  return item;
}
export const getUserCookieSettingsIndex = (settings: any = null, settingsType: any = null, pageName: any = null) => {
  let index = -1;

  if ((settings && settings.length > 0) && (settingsType !== null)) {
    if (pageName !== null) {
      index = settings.findIndex((x: any) => (x.type === settingsType) && (x.name === pageName));
    } else {
      index = settings.findIndex((x: any) => x.type === settingsType);
    }
  }

  return index;
}

export const getIds = (rows: any = [], field = 'id') => {
  let ids = '';

  if(rows && rows.length > 0){
    ids = rows.map((x: any) => x[field]).join(',');
  }

  return ids;
}
export const cloneOriginal = (rows: any = [], fields: any) => {
  let newRows: any = [];

  if(rows && rows.length > 0){
    let rowsClone = _.cloneDeep(rows);
    newRows = rowsClone.map((x: any) => {
      Object.keys(fields).forEach(key => {
        x.original[key] = fields[key];
      });
      return x.original;
    });
  }

  return newRows;
}
