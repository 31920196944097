/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import { axios } from '../../utils/axios';
import { ReadAPIParams, success, successAPI, errorAPI, placeholderRows, isNumeric, removeEmptyRowsWithArrays, removeEmptyRows } from '../../utils/utils';

export const fields = {
  created: {
    id: 'created',
    label: 'Timestamp',
  },
  notificationMessage: {
    id: 'notificationMessage',
    label: 'Message',
  },
  type: {
    id: 'type',
    label: 'Type',
  },
  isRead: {
    id: 'isRead',
    label: 'Status',
  },
};

export const prepareData = (values: any = null, id: any = null) => {
  let data: any = {};

  return data;
};

interface InitState {
  isLoading: boolean,
  show: boolean,
  id: any | null,

  isLoadingCreateUpdateDelete: boolean,
}

function NewReducer() {
  const name = 'alertsSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    id: null,

    isLoadingCreateUpdateDelete: false,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setId: (state: InitState, action: PayloadAction<any>) => {
      state.id = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, id: number | null }>) => {
      state.id = action.payload.id;
      state.show = action.payload.show;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      // state.alerts = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      // state.alerts = data;
    },

    startUpdate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
  };


  const apis = {
    callReadApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startRead());

      await axios.get('accountnotification', { params: params }).then(result => {
        let data = result.data;

        successAPI(data);

        callback(true, data);
        dispatch(actions.finishRead(data));
      }).catch(error => {
        errorAPI(error);

        callback(false, null);
        dispatch(actions.finishRead(null));
      });
    },

    callMarkAsReadApi: (row: any, showSuccessNotification: boolean, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startUpdate());

      let params = {
        jobId: row.jobId,
        accountNotificationId: row.accountNotificationId,
        isRead: true
      };

      await axios.post('accountnotification/mark-as-read', params).then(result => {
        let data = result.data;

        // successAPI(data);
        if (showSuccessNotification) {
          success('Mark as read successfully', 'Your data has been successfully marked as read.');
        }

        callback(true, data);
        dispatch(actions.finishUpdate(data));
      }).catch(error => {
        errorAPI(error);

        callback(false, null);
        dispatch(actions.finishUpdate(null));
      });
    },

    callBulkMarkAsReadApi: (ids: any, showSuccessNotification: boolean, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startUpdate());

      let params = {
        ids: ids,
        isRead: true
      };

      await axios.post('accountnotification/bulk-mark-as-read', params).then(result => {
        let data = result.data;

        // successAPI(data);
        if (showSuccessNotification) {
          success('Mark as read successfully', 'Your data has been successfully marked as read.');
        }

        callback(true, data);
        dispatch(actions.finishUpdate(data));
      }).catch(error => {
        errorAPI(error);

        callback(false, null);
        dispatch(actions.finishUpdate(null));
      });
    },

    callMarkAllAsReadApi: (callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startUpdate());

      let params = {
        isRead: true
      };

      await axios.post('accountnotification/mark-all-as-read', params).then(result => {
        let data = result.data;

        // successAPI(data);
        success('Mark all as read successfully', 'Your data has been successfully marked as read.');

        callback(true, data);
        dispatch(actions.finishUpdate(data));
      }).catch(error => {
        errorAPI(error);

        callback(false, null);
        dispatch(actions.finishUpdate(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();