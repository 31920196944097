import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest, select} from 'redux-saga/effects'
import {UserModel} from '../../../app/views/account/models/UserModel'
import {authenticateToken, dispatchAuthenticateToken, getAccountPermissions, userCookieSettingsApi} from '../../../app/api/auth/api'
import Utils from '../../../app/utils/utils'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',

  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',

  DispatchUserRequested: '[Request Dispatch User] Action',
  DispatchUserLoaded: '[Load Dispatch User] Auth API',
  SetDispatchUser: '[Set Dispatch User] Action',

  UserCookieSettingsRequested: '[Request UserCookieSettings] Action',
  UserCookieSettingsLoaded: '[Load UserCookieSettings] Auth API',
  SetUserCookieSettings: '[Set UserCookieSettings] Action',

  AccountPermissionsRequested: '[Request AccountPermissions] Action',
  SetAccountPermissions: '[Set AccountPermissions] Action',
}

const initialAuthState = {
  user: undefined,
  dispatchUser: undefined,
  userCookieSettings: undefined,
  accountPermissions: undefined,
  token: undefined,
}

// export interface IAuthState {
//   user?: UserModel
//   dispatchUser?: null
//   userCookieSettings?: []
//   token?: string
// }

export const reducer = persistReducer(
    {storage, key: 'auth', whitelist: ['user', 'dispatchUser', 'userCookieSettings','token']},
    (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
      switch (action.type) {
        case actionTypes.Login: {
          const token = action.payload?.token
          localStorage.setItem('token', token);
          return {token, user: undefined}
        }
        case actionTypes.Register: {
          const token = action.payload?.token
          return {token, user: undefined}
        }
        case actionTypes.Logout: {
          localStorage.clear();
          return initialAuthState
        }

        case actionTypes.UserLoaded: {
          const user = action.payload?.user
          return {...state, user}
        }
        case actionTypes.SetUser: {
          const user = action.payload?.user
          return {...state, user}
        }

        case actionTypes.DispatchUserLoaded: {
          const dispatchUser = action.payload?.dispatchUser
          return {...state, dispatchUser}
        }
        case actionTypes.SetDispatchUser: {
          const dispatchUser = action.payload?.dispatchUser
          return {...state, dispatchUser}
        }
        
        case actionTypes.UserCookieSettingsLoaded: {
          const userCookieSettings = action.payload?.userCookieSettings
          return {...state, userCookieSettings}
        }
        case actionTypes.SetUserCookieSettings: {
          const userCookieSettings = action.payload?.userCookieSettings
          return {...state, userCookieSettings}
        }
        
        case actionTypes.SetAccountPermissions: {
          let accountPermissions = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
          return {...state, accountPermissions}
        }

        default:
          return state
      }
    }
)

export const actions = {
  login: (token: string) => ({type: actionTypes.Login, payload: {token}}),
  register: (token: string) => ({
    type: actionTypes.Register,
    payload: {token},
  }),
  logout: () => ({type: actionTypes.Logout}),

  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),

  requestDispatchUser: () => ({
    type: actionTypes.DispatchUserRequested,
  }),
  fulfillDispatchUser: (dispatchUser: null) => ({type: actionTypes.DispatchUserLoaded, payload: {dispatchUser}}),
  setDispatchUser: (dispatchUser: null) => ({type: actionTypes.SetDispatchUser, payload: {dispatchUser}}),
  
  requestUserCookieSettings: () => ({
    type: actionTypes.UserCookieSettingsRequested,
  }),
  saveUserCookieSettings: (userCookieSettings: []) => ({type: actionTypes.UserCookieSettingsLoaded, payload: {userCookieSettings}}),
  setUserCookieSettings: (userCookieSettings: []) => ({type: actionTypes.SetUserCookieSettings, payload: {userCookieSettings}}),
  
  setAccountPermissions: (accountPermissions: any) => ({type: actionTypes.SetAccountPermissions, payload: accountPermissions}),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
    yield put(actions.requestDispatchUser())
    yield put(actions.requestUserCookieSettings())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
    yield put(actions.requestDispatchUser())
    yield put(actions.requestUserCookieSettings())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    // @ts-ignore
    const getToken = (state) => state.auth.token;
    // @ts-ignore
    let token = yield select(getToken)
    const {data: user} = yield authenticateToken(token)
    yield put(actions.fulfillUser(user))
  })

  yield takeLatest(actionTypes.UserRequested, function* AccountPermissionsRequested() {
    // @ts-ignore
    const getToken = (state) => state.auth.token;
    // @ts-ignore
    let token = yield select(getToken)
    const allPermissions = yield getAccountPermissions(token)
    yield put(actions.setAccountPermissions(allPermissions))
  })

  yield takeLatest(actionTypes.DispatchUserRequested, function* dispatchUserRequested() {
    // @ts-ignore
    const getToken = (state) => state.auth.token;
    // @ts-ignore
    let token = yield select(getToken)
    const {data: dispatchUser} = yield dispatchAuthenticateToken(token)
    yield put(actions.fulfillDispatchUser(dispatchUser))
  })

  yield takeLatest(actionTypes.DispatchUserRequested, function* userCookieSettingsRequested() {
    const data = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize()
    };

    const {data: userCookieSettings} = yield userCookieSettingsApi(data)
    yield put(actions.saveUserCookieSettings((userCookieSettings && userCookieSettings.data && userCookieSettings.data.length > 0) ? userCookieSettings.data : []))
  })
}
