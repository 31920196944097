import React from 'react';

// ----------------------------------------------------------------------

const ResizeScreen = ({ onResize }: { onResize: (width: number|null, height: number|null) => void}) => {
  const [windowResizing, setWindowResizing] = React.useState(false);

  React.useEffect(() => {
    let timeout: any;
    const onResizeListener = () => {
      clearTimeout(timeout);
      
      setWindowResizing(true);

      timeout = setTimeout(() => {
        setWindowResizing(false);

        if(onResize){
          onResize(window.innerWidth, window.innerHeight);
        }
      }, 200);
    }

    window.addEventListener("resize", onResizeListener);

    return () => {
      window.removeEventListener("resize", onResizeListener);
    }
  }, []);

  return null
}

export default ResizeScreen