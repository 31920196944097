import React from 'react';
import { Helmet } from 'react-helmet-async';

import {
  Container,
} from 'react-bootstrap';

import { Permissions } from '../../utils/enums.tsx';

import RoleBasedGuard from '../../auth/role-based-guard';
import LoadingPage from '../../components/loading/LoadingPage.tsx';

// ----------------------------------------------------------------------

const MainContainer = ({ title, env = 'production', roles = [Permissions.All], strictly = false, isLoading = false, children, Layout, ...rest }: any) => {
  return <>
    {/* <Helmet>
      <title>{title}</title>
    </Helmet> */}

    <RoleBasedGuard
      env={env}
      roles={roles}
      strictly={strictly}
    >
      <Container fluid {...rest}>{Layout ? <Layout>{children}</Layout> : children}</Container>
    </RoleBasedGuard>

    <LoadingPage isLoading={isLoading} />
  </>
}

export default MainContainer;
