/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import { axios } from '../../../../utils/axios';
import { successAPI, errorAPI, convertLatLngToCoord } from '../../../../utils/utils';
import Yup from '../../../../utils/yup';
// import {  } from '../../../../utils/enums';


export const fields = {
  emailAddress: {
    id: 'emailAddress',
    label: 'Contact person',
    placeholder: 'Enter email address',
  },
};

export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    emailAddress: Yup.string().required().email().label(fields.emailAddress.label),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null, defValues: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    let emailAddress = (form.emailAddress && form.emailAddress !== '') ? form.emailAddress : '';
    
    data['emailAddress'] = emailAddress;
  }
  
  return data;
};
export const prepareData = (values: any = null, id: any = null) => {
  let data: any = {};

  let form = _.cloneDeep(values);

  if(form){
    data['emailAddress'] = form.emailAddress;
  }

  return data;
};


export interface initialValuesStruct {
  emailAddress: string,
};
export const initialValues: initialValuesStruct = {
  emailAddress: '',
};


interface InitState {
  isLoading: boolean,
  show: boolean,
  details: any,
}

function NewReducer() {
  const name = 'customersSiteCreateNewEmailSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    details: initialValues,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },
  };


  const apis = {
    
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();