/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../../../../app/views/account/models/UserModel'
import {RootState} from '../../../../setup'
import * as auth from '../../../../setup/redux/auth/saga'
import {useDispatch} from 'react-redux'

import { 
  Badge,
} from "react-bootstrap-v5";

import Utils from '../../../../app/utils/utils';

import CustomDialog from '../../../../app/components/OLD/Dialog/CustomDialog.jsx';
import CreateUserDialog from "../../../../app/views/account/CreateUserDialog";

import DefaultImage from "../../../../_metronic/assets/img/default_image.jpg";

import AuditTrailDialogUser from '../../../../app/components/OLD/Pages/AuditTrail/AuditTrailDialogUser.jsx';
import AuditTrailFunctions from '../../../../app/components/OLD/Pages/AuditTrail/AuditTrailFunctions.jsx';


const { REACT_APP_VERSION, NODE_ENVIRONMENT } = process.env;


const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const dispatch = useDispatch()
  const logout = () => {
    dispatch(auth.actions.logout())
  }
  const saveUserCookieSettings = (settings: any) => {
    dispatch(auth.actions.saveUserCookieSettings(settings))
  }

  let profileImageUrl = (user.profileImageUrl) ? user.profileImageUrl : DefaultImage;
  
  const [showSettings, setShowSettings] = React.useState(false);
  const [showAuditTrail, setShowAuditTrail] = React.useState(false);
  
  


  
  
  
  let currentValue = Utils.getCookie();
  let isV4 = (currentValue == null || currentValue == 'v4');





  const setCreateNewDialog = () => {
    return <CustomDialog
      className={'max-width-1366'}
      open={showSettings}
      maxWidth={false}
      onClose={() => {
        setShowSettings(false);
      }}
    >
      <CreateUserDialog
        user={user}
        onClose={() => {
          setShowSettings(false);
        }}
        onSave={(settings: any) => {
          saveUserCookieSettings(settings);
          setShowSettings(false);
          window.location.reload();
        }}
      />
    </CustomDialog>
  }

  const setAuditTrailDialog = () => {
    return <AuditTrailDialogUser 
      isOpen={showAuditTrail}
      userEmail={(user && user.email && user.email !== '') ? user.email : ''}
      showFilter={true}
      title={"User Audit Trail"}
      onClose={() => {
        setShowAuditTrail(false);
      }}
      details={(obj: any, index: any, currentVersion: any, previousVersion: any, objectType: any) => {
        return AuditTrailFunctions.getDetails(objectType, obj, index, currentVersion, previousVersion);
      }}
      manageData={(currentVersion: any, previousVersion: any, objectType: any, callback: any) => {
        return AuditTrailFunctions.getManageData(objectType, currentVersion, previousVersion, callback);
      }}
    />
  }


  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={profileImageUrl} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5' style={{ overflowWrap: 'anywhere' }}>
              {(user && user.name && user.name !== '') ? user.name : ''}
            </div>
            <span className='fw-bold text-muted fs-7' style={{ overflowWrap: 'anywhere' }}>
              {(user && user.email && user.email !== '') ? user.email : ''}
            </span>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a href={'/'} 
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            setShowSettings(true);
          }}
          className='menu-link px-5'
        >
          Settings
        </a>
      </div>

      <div className='menu-item px-5'>
        <a href={'/'} 
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            setShowAuditTrail(true);
          }}
          className='menu-link px-5'
        >
          My Activity
        </a>
      </div>





      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <a href={'/'} 
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            const value = isV4 ? "v5" : "v4";
            Utils.setCookie('projectVersion', value, () => {
              window.location.href = '/';
            });
          }}
          className='menu-link px-5'
        >
          <b>Switch to {isV4 ? "v5" : "v4"}</b>
          {isV4 && <Badge pill bg="primary" className={'label-beta ms-2'} style={{ padding: '4px 6px 4px 6px' }}>NEW</Badge>}
        </a>
      </div>
      {!isV4 && Utils.isStaging() && <div className='menu-item px-5'>
        <a href={'/v5/design-system'} 
          target='_blank'
          className='menu-link px-5'
        >
          Design System
        </a>
      </div>}





      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
      
      <div className='separator my-2'></div>

      <div className='menu-item px-5 pt-1 text-end'>
        <span className={'badge badge-light-' + (Utils.isStaging() ? 'danger': 'primary') + ' fw-bolder fs-8 px-2 py-1 ms-2'}>v{REACT_APP_VERSION}</span>
      </div>

      {setCreateNewDialog()}
      {setAuditTrailDialog()}
    </div>
  )
}

export default HeaderUserMenu
