import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Form,
} from 'react-bootstrap';
// import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

// import { Spin } from "antd";

// Auth
// import { useAuthContext } from '../../../../../auth/use-auth-context.js';
// import { useRouter } from '../../../../../auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../../utils/store.tsx';
import slice, { useFormikContext, fields } from '../slice.tsx';

// enums
import { limitRows } from '../../../../../utils/utils.tsx';
// import {  } from '../../../../../utils/enums.tsx';

// components
// import MainContainer from '../../../../../components/container/MainContainer.jsx';

// import { ReactComponent as XIcon } from "../../../../../../v5/assets/icons/smallX.svg";

// ----------------------------------------------------------------------

const Tab1 = () => {
  // const location = useLocation();
  // const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  // const { isLoading } = useTypedSelector((state: RootState) => state.manageJobTypesSlice);
  // const dispatch = useDispatch<AppDispatch>();


  const setFormElements = () => {
    return <Row className='g-16'>

      <Col xs={12}>
        <Form.Group>
          <Form.Label>{fields.jobTemplateName.label}</Form.Label>
          <Form.Control
            type={'text'}
            autoComplete='off'
            autoFocus
            placeholder={fields.jobTemplateName.placeholder}
            disabled={false}
            value={values.jobTemplateName}
            onChange={(e) => {
              setFieldValue('jobTemplateName', e.target.value);
            }}
            isInvalid={!!(errors && errors.jobTemplateName)}
          />
          <Form.Control.Feedback type="invalid">{errors && errors.jobTemplateName as ReactNode}</Form.Control.Feedback>
        </Form.Group>
      </Col>

      <Col xs={12}>
        <Form.Group>
          <Form.Label>{fields.jobTemplateDescription.label}</Form.Label>
          <Form.Control
            as="textarea"
            rows={fields.jobTemplateDescription.rows}
            autoComplete='off'
            placeholder={fields.jobTemplateDescription.placeholder}
            disabled={false}
            value={values.jobTemplateDescription}
            onChange={(e) => {
              limitRows(e.target.value, fields.jobTemplateDescription.rows, async (value: any) => {
                await setFieldValue('jobTemplateDescription', value);
              });
            }}
            isInvalid={!!(errors && errors.jobTemplateDescription)}
          />
          <Form.Control.Feedback type="invalid">{errors && errors.jobTemplateDescription as ReactNode}</Form.Control.Feedback>
        </Form.Group>
      </Col>

    </Row>
  }


  return <Row className='g-24 mb-100'>
    <Col xs={12}>
      <Row className='g-8'>
        <Col xs={12}>
          <span className="large medium gray-900">Job Type Info</span>
        </Col>
        <Col xs={12}>
          <span className="gray-800">Assign a title and description to your job type for easy identification and searchability.</span>
        </Col>
      </Row>
    </Col>

    <Col xs={12} md={10} lg={8} xl={6} xxl={5} xxxl={4}>
      {setFormElements()}
    </Col>
  </Row>
}

export default Tab1;
