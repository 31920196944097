/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'

import {UserDispatchModel} from '../../../../app/views/account/models/UserDispatchModel'
import {RootState} from '../../../../setup'

import { useDispatch } from 'react-redux';
import sliceManage from '../../../../app/v5/layouts/manage/slice';

import logo from '../../../../_metronic/assets/img/logo.png';
import { ReactComponent as MenuIcon } from "../../../../app/v5/assets/icons/menu.svg";


export function HeaderWrapper() {
  const dispatchUser: UserDispatchModel = useSelector<RootState>(({auth}) => auth.dispatchUser, shallowEqual) as UserDispatchModel

  const dispatch = useDispatch<any>();

  const {config, classes, attributes} = useLayout()
  const {header, aside} = config

  let dispatchUserLogo = (dispatchUser && dispatchUser.logoImageUrl) ? dispatchUser.logoImageUrl : logo;
  

  const showManageMenu = () => {
    dispatch(sliceManage.setShow(true));
  }
  const hideManageMenu = () => {
    dispatch(sliceManage.setShow(false));
  }
  
  React.useEffect(() => {
    try{
      if(window.location.pathname.startsWith('/v5/')){
        hideManageMenu();
      }
    } catch(e){}
    
    window.addEventListener('resize', hideManageMenu);

    return () => {
      window.removeEventListener('resize', hideManageMenu);
    }
  });


  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      data-kt-sticky='false'
      data-kt-sticky-name='header'
      // data-kt-sticky-offset="{default: '200px', lg: '300px'}"
      data-kt-sticky-offset="{default: '65px', lg: '65px'}"
      data-kt-sticky-animation='false'
      {...attributes.headerMenu}

      style={{
        position: 'sticky',
        top: '0px',
        zIndex: '7',
        backgroundColor: 'white'
      }}
    >
      <div className={clsx(classes.headerContainer.join(' '), 'd-flex align-items-center')}>
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}

        {/* <div className='d-flex topbar align-items-center d-lg-none ms-n2 me-3' title='Show aside menu'
          onClick={() => {
            dispatch(sliceManage.setShow(true));
          }}
        >
          <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
            <MenuIcon />
          </div>
        </div> */}

        {
          window.location.pathname.startsWith('/v5/')
          ?
          <div
            className='d-flex topbar align-items-center d-lg-none ms-n2 me-3'
            title='Show aside menu'
          >
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              onClick={() => {
                showManageMenu();
              }}
            >
              {/* <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x' /> */}
              <MenuIcon />
            </div>
          </div>
          :
          <div
            className='d-flex topbar align-items-center d-lg-none ms-n2 me-3'
            title='Show aside menu'
          >
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_header_menu_mobile_toggle'
            >
              {/* <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x' /> */}
              <MenuIcon />
            </div>
          </div>
        }
        

        <div className='header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0'>
          <Link to='/'>
            <img
              alt='Logo'
              src={dispatchUserLogo}
              className='logo-default h-40px'
            />
            <img
              alt='Logo'
              src={dispatchUserLogo}
              className='logo-sticky h-40px'
            />
          </Link>
        </div>

        {/* begin::Wrapper */}
        <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
          {/* begin::Navbar */}
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <Header />
            </div>
          )}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}

          <div className='d-flex align-items-stretch flex-shrink-0'>
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}
