import _ from 'lodash';

// Download ----------------------------------------------------------------------

export const getUint8Array = (base64: any) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  return new Uint8Array(byteNumbers);
}
export const downloadPDF = (name: string, byte: any, type = "application/pdf") => {
  let byteArray = getUint8Array(byte);
  let blob = new Blob([byteArray], { type });
  let url = window.URL.createObjectURL(blob);
  let link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.remove();
}
export const viewPDF = (byte: any) => {
  let byteArray = getUint8Array(byte);
  const blob = new Blob([byteArray], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  window.open(url, '_blank');
}
export const downloadCSV = (name: string, byte: any, type = "text/csv") => {
  let blob = new Blob([byte], { type });
  let url = window.URL.createObjectURL(blob);
  let link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.remove();
}
export const downloadEXCEL = (name: string, byte: any, type = "application/vnd.ms-excel") => {
  let byteArray = getUint8Array(byte);
  let blob = new Blob([byteArray], { type });
  let url = window.URL.createObjectURL(blob);
  let link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.remove();
}
