/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import { axios } from '../../../utils/axios';
import { ReadAPIParams, success, successAPI, errorAPI, placeholderRows, isNumeric, removeEmptyRowsWithArrays, removeEmptyRows } from '../../../utils/utils';
import Yup from '../../../utils/yup';
import { XeroPaymentTermType, XeroPaymentTermTypeItems } from '../../../utils/enums';


export const fields = {
  isActive: {
    id: 'isActive',
    label: 'Client status',
    placeholder: '',
  },
  customerName: {
    id: 'customerName',
    label: 'Customer name',
    placeholder: 'Enter customer name',
  },
  billingName: {
    id: 'billingName',
    label: 'Billing name',
    placeholder: 'Enter billing name',
  },
  billingAddress: {
    id: 'billingAddress',
    label: 'Billing address',
    placeholder: placeholderRows(4, 'Enter billing address', ''),
    rows: 4,
  },
  contactNumber: {
    id: 'contactNumber',
    label: 'Phone number',
    placeholder: 'Enter phone number',
  },
  note: {
    id: 'note',
    label: 'Customer note',
    labelDetails: '(for internal use)',
    placeholder: placeholderRows(5, 'Enter customer note', ''),
    rows: 5,
  },
  emailAddresses: {
    id: 'emailAddresses',
    label: 'Company email',
    placeholder: 'You can enter only one email address',
  },
  customerCategoryId: {
    id: 'customerCategoryId',
    label: 'Category',
    placeholder: 'Choose category',
  },
  businessRegNo: {
    id: 'businessRegNo',
    label: 'Business number',
    placeholder: 'Enter business number',
  },
  accountBillingProfileId: {
    id: 'accountBillingProfileId',
    label: 'Billing by',
    placeholder: 'Choose billing customer',
  },
  billingProfileName: {
    id: 'billingProfileName',
    label: 'Billing by',
    placeholder: 'Choose billing by',
  },
  paymentTermsId: {
    id: 'paymentTermsId',
    label: 'Payment terms',
    placeholder: 'Choose payment terms',
  },
  xeroPaymentTerm: {
    id: 'xeroPaymentTerm',
    label: 'Payment terms',
    placeholder: 'Choose payment terms',
  },
  xeroPaymentTermType: {
    id: 'xeroPaymentTermType',
    label: 'Payment terms',
    placeholder: 'Choose payment terms type',
    error: 'Due date for sales invoices must be between 1 and 31 when set to the following (or current) month.',
  },
  creditLimit: {
    id: 'creditLimit',
    label: 'Credit limit',
    placeholder: 'Enter credit limit',
  },
  isRequirePaymentCollection: {
    id: 'isRequirePaymentCollection',
    label: 'Collect payment onsite',
    placeholder: '',
  },

  contactPersons: {
    id: 'contactPersons',
    label: 'Customer details',
    placeholder: '',
  },
  firstName: {
    id: 'firstName',
    label: 'First name',
    placeholder: 'Name',
  },
  emailAddress: {
    id: 'emailAddress',
    label: 'Email address',
    placeholder: 'Address',
  },
  workPhone: {
    id: 'workPhone',
    label: 'Work phone',
    placeholder: 'Phone',
  },
  mobilePhone: {
    id: 'mobilePhone',
    label: 'Mobile',
    placeholder: 'Mobile',
  },
  department: {
    id: 'department',
    label: 'Department',
    placeholder: 'Department',
  },
};

export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    isActive: Yup.bool().oneOf([true, false]),
    customerName: Yup.string().required().label(fields.customerName.label),
    billingName: Yup.string().required().label(fields.billingName.label),
    billingAddress: Yup.string().label(fields.billingAddress.label),
    contactNumber: Yup.string().label(fields.contactNumber.label),
    note: Yup.string().label(fields.note.label),
    customerCategoryId: Yup.number().nullable().label(fields.customerCategoryId.label),
    businessRegNo: Yup.string().label(fields.businessRegNo.label),
    accountBillingProfileId: Yup.number().nullable().required().label(fields.accountBillingProfileId.label),
    paymentTermsId: Yup.number().nullable().label(fields.paymentTermsId.label),
    
    isConnectXero: Yup.bool().oneOf([true, false]),
    xeroPaymentTerm: Yup.number().when(['isConnectXero', 'xeroPaymentTermType'], ([isConnectXero, xeroPaymentTermType]: any) => {
      if(isConnectXero){
        if((xeroPaymentTermType == XeroPaymentTermType.OF_CURRENT_MONTH) || (xeroPaymentTermType == XeroPaymentTermType.OF_FOLLOWING_MONTH)){
              return Yup.number().nullable().required().positive().min(0).max(31, fields.xeroPaymentTermType.error).label(fields.xeroPaymentTerm.label);
          } else {
              return Yup.number().nullable().required().positive().min(0).label(fields.xeroPaymentTerm.label);
          }
      } else {
        return Yup.number().nullable().positive().min(0).label(fields.xeroPaymentTerm.label);
      }
    }),
    xeroPaymentTermType: Yup.number().when(['isConnectXero'], ([isConnectXero]: any) => {
      if(isConnectXero){
        return Yup.number().nullable().required().positive().min(0).label(fields.xeroPaymentTermType.label);
      } else {
        return Yup.number().nullable().label(fields.xeroPaymentTermType.label);
      }
    }),
    
    creditLimit: Yup.number().nullable().min(0).label(fields.creditLimit.label),
    isRequirePaymentCollection: Yup.bool().oneOf([true, false]),
    emailAddresses: Yup.string().email('Must be a valid email').label(fields.emailAddresses.label),

    contactPersons: Yup.array().of(Yup.object().shape({
      firstName: Yup.string().nullable().label(fields.firstName.label),
      emailAddress: Yup.string().nullable().email('Must be a valid email').label(fields.emailAddress.label),
      workPhone: Yup.string().nullable().label(fields.workPhone.label),
      mobilePhone: Yup.string().nullable().label(fields.mobilePhone.label),
      department: Yup.string().nullable().label(fields.department.label),
    })),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null, defValues: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    let isActive = ((form.isActive == false) || (form.isActive == true)) ? form.isActive : false;
    let isRequirePaymentCollection = ((form.isRequirePaymentCollection == false) || (form.isRequirePaymentCollection == true)) ? form.isRequirePaymentCollection : false;

    let customerName = (form.customerName && form.customerName !== '') ? form.customerName : '';
    let billingName = (form.billingName && form.billingName !== '') ? form.billingName : '';
    let billingAddress = (form.billingAddress && form.billingAddress !== '') ? form.billingAddress : '';
    let contactNumber = (form.contactNumber && form.contactNumber !== '') ? form.contactNumber : '';
    let businessRegNo = (form.businessRegNo && form.businessRegNo !== '') ? form.businessRegNo : '';
    let note = (form.note && form.note !== '') ? form.note : '';
    let emailAddresses = (form.emailAddresses && form.emailAddresses !== '') ? form.emailAddresses : '';
    let creditLimit = (form.creditLimit && form.creditLimit !== '') ? form.creditLimit : '';
    let creditLimitAlertMessage = (form.creditLimitAlertMessage && form.creditLimitAlertMessage !== '') ? form.creditLimitAlertMessage : '';

    let category = (form && form.category) ? form.category : null;
    let customerCategoryId = (category && category.customerCategoryId && category.customerCategoryId !== '') ? category.customerCategoryId : null;
    let customerCategoryName = (category && category.customerCategoryName && category.customerCategoryName !== '') ? category.customerCategoryName : '';
    
    let billingProfile = (form && form.billingProfile) ? form.billingProfile : null;
    let accountBillingProfileId = (billingProfile && billingProfile.accountBillingProfileId && billingProfile.accountBillingProfileId !== '') ? billingProfile.accountBillingProfileId : null;
    let billingProfileName = (billingProfile && billingProfile.billingProfileName && billingProfile.billingProfileName !== '') ? billingProfile.billingProfileName : '';
    
    let paymentTermsId = (form && form.quickBookDefaultTermId) ? form.quickBookDefaultTermId : null;
    let paymentTermsName = (form && form.paymentTerms) ? form.paymentTerms : '';

    let isConnectQuickbook = (billingProfile && billingProfile.isConnectQuickbook) ? billingProfile.isConnectQuickbook : false;
    let isConnectXero = (billingProfile && billingProfile.isConnectXero) ? billingProfile.isConnectXero : false;
    
    let quickBookApp = (billingProfile && billingProfile.quickBookApp) ? billingProfile.quickBookApp : null;
    let quickBookAppId = (quickBookApp && quickBookApp.quickBookAppId) ? quickBookApp.quickBookAppId : 0;
    let xeroPaymentTerm = (form && isNumeric(form.xeroPaymentTerm)) ? form.xeroPaymentTerm.toString() : '';
    let xeroPaymentTermType = (form && form.xeroPaymentTermType) ? form.xeroPaymentTermType.toString() : '0';
    

    let customerCreditLimitTriggers = [];
    if(form && form.customerCreditLimitTriggers && form.customerCreditLimitTriggers.length > 0){
        for(let i = 0; i < form.customerCreditLimitTriggers.length; i++){
            let actions = (form.customerCreditLimitTriggers[i] && form.customerCreditLimitTriggers[i].actions && form.customerCreditLimitTriggers[i].actions.length > 0) ? form.customerCreditLimitTriggers[i].actions : [];
            let contacts = (form.customerCreditLimitTriggers[i] && form.customerCreditLimitTriggers[i].contacts && form.customerCreditLimitTriggers[i].contacts.length > 0) ? form.customerCreditLimitTriggers[i].contacts : [];
            
            form.customerCreditLimitTriggers[i]['action'] = (actions && actions.length > 0) ? actions.map((x: any) => x.action) : [];

            if(contacts && contacts.length > 0) {
              form.customerCreditLimitTriggers[i]['emailAddress'] = contacts.map((x: any) => {
                    if(x.emailAddress && x.emailAddress != ''){
                        return x.emailAddress
                    } else {
                        return x.customerContactPerson.emailAddress
                    }
                })
            }

            customerCreditLimitTriggers.push(form.customerCreditLimitTriggers[i]);
        }
    }
    if(customerCreditLimitTriggers.length == 0){
        customerCreditLimitTriggers = [initCustomerCreditLimitTriggers];
    }


    let creditLimitEmailTemplate = null;
    if((form && form.creditLimitEmailTemplate)){
        let recipientCc = (form.creditLimitEmailTemplate.recipientCc) ? form.creditLimitEmailTemplate.recipientCc : '';
        let recipientCcItems = (recipientCc != '') ? recipientCc.split(',') : [];
        form.creditLimitEmailTemplate.recipientCc = recipientCcItems;

        let message = (form.creditLimitEmailTemplate.message) ? form.creditLimitEmailTemplate.message : '';
        // let messagePlainText = h2p(message);

        creditLimitEmailTemplate = form.creditLimitEmailTemplate;
        creditLimitEmailTemplate.subject = (form.creditLimitEmailTemplate.subject) ? form.creditLimitEmailTemplate.subject : '';
        creditLimitEmailTemplate.fromName = (form.creditLimitEmailTemplate.fromName) ? form.creditLimitEmailTemplate.fromName : '';
        creditLimitEmailTemplate.message = (form.creditLimitEmailTemplate.message) ? form.creditLimitEmailTemplate.message : '';
        // creditLimitEmailTemplate.recipientCc = recipientCc;
        creditLimitEmailTemplate.recipientCc = recipientCcItems;
        
        // customFormParams.messagePlainText = messagePlainText;
        // customFormParams.messageHTML = message;
        // customFormParams.message = Utils.createEditorState(message);
    } else {
        creditLimitEmailTemplate = initCreditLimitEmailTemplate;

        // customFormParams.messagePlainText = '';
        // customFormParams.messageHTML = '';
        // customFormParams.message = Utils.createEditorState();
    }

    let contactPersons = (form.contactPersons && form.contactPersons.length > 0)
      ?
      form.contactPersons.map((item: any) => {
        let firstName = (item && item.firstName && item.firstName !== '') ? item.firstName : '';
        let emailAddress = (item && item.emailAddress && item.emailAddress !== '') ? item.emailAddress : '';
        let workPhone = (item && item.workPhone && item.workPhone !== '') ? item.workPhone : '';
        let mobilePhone = (item && item.mobilePhone && item.mobilePhone !== '') ? item.mobilePhone : '';
        let department = (item && item.department && item.department !== '') ? item.department : '';
      
        let contactPerson: ContactPersons = {
          firstName: firstName,
          emailAddress: emailAddress,
          workPhone: workPhone,
          mobilePhone: mobilePhone,
          department: department,
        };
        return contactPerson
      })
    :
    [initContactPersons];


    data['isActive'] = isActive;

    data['customerName'] = customerName;
    data['billingName'] = billingName;
    data['billingAddress'] = billingAddress;
    data['contactNumber'] = contactNumber;
    data['businessRegNo'] = businessRegNo;
    data['note'] = note;
    data['customerCategoryId'] = customerCategoryId;
    data['customerCategoryName'] = customerCategoryName;
    data['accountBillingProfileId'] = accountBillingProfileId;
    data['billingProfileName'] = billingProfileName;
    data['paymentTermsId'] = paymentTermsId;
    data['paymentTermsName'] = paymentTermsName;
    data['xeroPaymentTerm'] = xeroPaymentTerm;
    data['xeroPaymentTermType'] = xeroPaymentTermType;
    data['isRequirePaymentCollection'] = isRequirePaymentCollection;
    data['emailAddresses'] = emailAddresses;
    data['creditLimit'] = creditLimit;
    data['creditLimitAlertMessage'] = creditLimitAlertMessage;

    data['customerCreditLimitTriggers'] = customerCreditLimitTriggers;
    data['creditLimitEmailTemplate'] = creditLimitEmailTemplate;
    data['contactPersons'] = contactPersons;
    
    data['isConnectQuickbook'] = isConnectQuickbook;
    data['isConnectXero'] = isConnectXero;
    data['quickBookAppId'] = quickBookAppId;
  }
  
  return data;
};
export const prepareData = (values: any = null, id: any = null) => {
  let data: any = {};

  let form = _.cloneDeep(values);

  if(form){
    if(id){
      data['customerId'] = id;
    }
    
    data['isActive'] = form.isActive;

    data['customerName'] = form.customerName;
    data['billingName'] = form.billingName;
    data['billingAddress'] = form.billingAddress;
    data['contactNumber'] = form.contactNumber;
    data['note'] = form.note;
    data['businessRegNo'] = form.businessRegNo;
    data['isRequirePaymentCollection'] = form.isRequirePaymentCollection;
    data['quickBookDefaultTermId'] = form.paymentTermsId;
    data['xeroPaymentTerm'] = form.xeroPaymentTerm;
    data['xeroPaymentTermType'] = form.xeroPaymentTermType;
    data['creditLimit'] = form.creditLimit;
    data['creditLimitAlertMessage'] = form.creditLimitAlertMessage;
    data['creditLimitEmailTemplate'] = form.creditLimitEmailTemplate;
    data['accountBillingProfileId'] = form.accountBillingProfileId;
    data['customerCategoryId'] = form.customerCategoryId;
    data['emailAddresses'] = form.emailAddresses;

    let category = null;
    if(form.customerCategoryId){
      category = {
        customerCategoryName: form.customerCategoryName,
      }
    }
    data['category'] = category;


    form.creditLimitEmailTemplate.recipientCc = values.creditLimitEmailTemplate.recipientCc.join(',');

    let customerCreditLimitTriggers2: any = [];
    let customerCreditLimitTriggers1 = _.cloneDeep(form.customerCreditLimitTriggers);
    customerCreditLimitTriggers1.forEach((item: any, i: number) => {
        let actions = [];
        if(item.action && item.action.length > 0){
            actions = item.action.map((x: any) => ({ action: x }));
        }

        let contacts = [];
        if(item.emailAddress && item.emailAddress.length > 0){
            contacts = item.emailAddress.map((x: any) => {
                let child: any = {};
                if(form.emailAddresses == x){
                    child = { 
                        emailAddress: x
                    }
                } else {
                    child = { 
                        customerContactPerson: {
                            emailAddress: x
                        }
                    }
                }

                let mAdrItems = form.contactPersons.filter((y: any) => y.emailAddress == x);
                if(mAdrItems && mAdrItems.length > 0){
                  child['customerContactPersonId'] = mAdrItems[0].item.customerContactPersonId;
                }

                return child
            });
        }

        let params: any = {
            percentOfCreditLimit: item.percentOfCreditLimit,
            actions: actions,
            contacts: contacts,
        }

        if(item && item.customerCreditLimitTriggerId){
          params['customerCreditLimitTriggerId'] = item.customerCreditLimitTriggerId;
        }

        customerCreditLimitTriggers2.push(params);
    });

    let customerCreditLimitTriggers = [];
    if(customerCreditLimitTriggers2 && customerCreditLimitTriggers2.length > 0){
      var arrCopy = customerCreditLimitTriggers2.slice();
      customerCreditLimitTriggers = removeEmptyRowsWithArrays(arrCopy, (row: any) => {
        if(row['actions'] && row['contacts']){
          return (row['percentOfCreditLimit'] !== null || row['actions'].length > 0 || row['contacts'].length > 0);
        }
      });
    }
    data['customerCreditLimitTriggers'] = customerCreditLimitTriggers;


    let contactPersons: Array<any> = [];
    if(form.contactPersons && form.contactPersons.length > 0){
      contactPersons = removeEmptyRows(form.contactPersons, [ 'firstName', 'emailAddress', 'workPhone', 'mobilePhone', 'department']);
    }
    data['contactPersons'] = contactPersons;
  }

  return data;
};

export const getXeroPaymentTermTypeName = (xeroPaymentTermType: any) => {
  if(isNumeric(xeroPaymentTermType)){
    let selectedItem = XeroPaymentTermTypeItems.filter((x: any) => x.value == xeroPaymentTermType);
    if(selectedItem && selectedItem.length > 0){
      return selectedItem[0].title;
    } else {
      return '';
    }
  } else {
    return '';
  }
}


interface ContactPersons {
  firstName: string|null,
  emailAddress: string|null,
  workPhone: string|null,
  mobilePhone: string|null,
  department: string|null,
};
export const initContactPersons: ContactPersons = {
  firstName: '',
  emailAddress: '',
  workPhone: '',
  mobilePhone: '',
  department: '',
};

interface CustomerCreditLimitTriggers {
  percentOfCreditLimit: any|null,
  action: Array<any>,
  emailAddress: Array<any>,
};
export const initCustomerCreditLimitTriggers: CustomerCreditLimitTriggers = {
  percentOfCreditLimit: null,
  action: [],
  emailAddress: [],
};

interface CreditLimitEmailTemplate {
  subject: string|null,
  fromName: string|null,
  recipientCc: Array<any>,
  message: string|null,
};
export const initCreditLimitEmailTemplate: CreditLimitEmailTemplate = {
  subject: '',
  fromName: '',
  recipientCc: [],
  message: '',
};


export interface initialValuesStruct {
  isActive: boolean,

  customerName: string,
  billingName: string,
  billingAddress: string,
  contactNumber: string,
  businessRegNo: string,
  emailAddresses: string,

  customerCreditLimitTriggers: Array<CustomerCreditLimitTriggers>,
  creditLimitEmailTemplate: CreditLimitEmailTemplate,

  accountBillingProfileId: any,
  billingProfileName: string,

  paymentTermsId: any,
  paymentTermsName: string,

  xeroPaymentTerm: any,
  xeroPaymentTermType: any,

  customerCategoryId: any,
  customerCategoryName: string,
  
  creditLimit: any,
  creditLimitAlertMessage: string,
  isRequirePaymentCollection: boolean,
  note: string,
  
  contactPersons: Array<ContactPersons>,

  isConnectQuickbook: boolean,
  isConnectXero: boolean,
  quickBookAppId: any,
};
export const initialValues: initialValuesStruct = {
  isActive: true, 

  customerName: '',
  billingName: '',
  billingAddress: '',
  contactNumber: '',
  businessRegNo: '',
  emailAddresses: '',

  customerCreditLimitTriggers: [initCustomerCreditLimitTriggers],
  creditLimitEmailTemplate: initCreditLimitEmailTemplate,

  accountBillingProfileId: null,
  billingProfileName: '',

  paymentTermsId: null,
  paymentTermsName: '',

  xeroPaymentTerm: '0',
  xeroPaymentTermType: XeroPaymentTermType.DAYS_AFTER_BILL_DATE,

  customerCategoryId: null,
  customerCategoryName: '',
  
  creditLimit: null,
  creditLimitAlertMessage: '',
  isRequirePaymentCollection: false,
  note: '',

  contactPersons: [initContactPersons],

  isConnectQuickbook: false,
  isConnectXero: false,
  quickBookAppId: 0,
};


interface InitState {
  isLoading: boolean,
  show: boolean,
  id: any|null,
  details: any,

  isLoadingCreateUpdateDelete: boolean,

  isLoadingQuickbookCreditTerms: boolean,
  quickbookCreditTerms: Array<any>,
}

function NewReducer() {
  const name = 'customersSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    id: null,
    details: initialValues,

    isLoadingCreateUpdateDelete: false,

    isLoadingQuickbookCreditTerms: false,
    quickbookCreditTerms: [],
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setId: (state: InitState, action: PayloadAction<any>) => {
      state.id = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, id: number|null}>) => {
      state.id = action.payload.id;
      state.show = action.payload.show;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      // state.customers = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      // state.customers = data;
    },

    startDetails: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.details = action.payload;
    },

    startCreate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startUpdate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startDelete: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
    
    startActivate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishActivate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startReadQuickbookCreditTerms: (state: InitState) => {
      state.isLoadingQuickbookCreditTerms = true;
      state.quickbookCreditTerms = [];
    },
    finishReadQuickbookCreditTerms: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingQuickbookCreditTerms = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      state.quickbookCreditTerms = data;
    },
  };


  const apis = {
    callReadApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startRead());
  
        await axios.get('customer', { params: params }).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishRead(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishRead(null));
        });
    },

    callDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetails());
  
        await axios.get('customer/' + id).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDetails(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDetails(null));
        });
    },

    callCreateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreate());
  
        await axios.post('customer', params).then(result => {
            let data = result.data;
            
            // successAPI(data);
            success('Saved successfully', 'The data you entered has been successfully saved.');

            callback(true, data);
            dispatch(actions.finishCreate(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishCreate(null));
        });
    },

    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdate());
  
        await axios.put('customer', params).then(result => {
            let data = result.data;
            
            // successAPI(data);
            success('Updated successfully', 'Your data has been successfully updated.');

            callback(true, data);
            dispatch(actions.finishUpdate(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdate(null));
        });
    },

    callDeleteApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDelete());
  
        await axios.delete('customer', { data: params }).then(result => {
            let data = result.data;
                
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDelete(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDelete(null));
        });
    },

    callActivateApi: (isActive: boolean, ids: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startActivate());

        let params = {
          isActive: isActive,
          ids: ids,
        };

        await axios.put('customer/deactivate', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishActivate(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishActivate(null));
        });
    },

    callReadQuickbookCreditTermsApi: (quickBookAppId: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startReadQuickbookCreditTerms());
  
        let params = {
          quickBookAppId: quickBookAppId,
        };

        await axios.get('quickbookapp/term', { params: params }).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishReadQuickbookCreditTerms(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishReadQuickbookCreditTerms(null));
        });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();