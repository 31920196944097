import React, { ReactNode } from 'react';
import {useLocation} from 'react-router-dom'
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';

import { 
    Container,
    Row,
    Col,
} from 'react-bootstrap';

// import PrivateHeader from '../sections/PrivateHeader';
import { HeaderWrapper } from '../../../_metronic/layout/components/header/HeaderWrapper'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import Menu from '../sections/Menu';

import { MAIN_BREAKPOINT } from '../utils/enums';

import ConfirmModal from '../components/modal/ConfirmModal';

// ----------------------------------------------------------------------

const StyledContent = styled.div`
  display: flex;
  min-height: calc(100vh - var(--header-height, 64px));

  ${up(MAIN_BREAKPOINT)} {
    display: flex;
  }

  ${down(MAIN_BREAKPOINT)} {
    display: block;
  }
`;
const StyledChildren = styled.div`
  width: 100%;
  overflow: hidden;
  min-height: 300px;
`;

// ----------------------------------------------------------------------

export default function PrivateLayout({ children }: { children: ReactNode }) {
  const headerHeight = document.getElementById('header_ID')?.clientHeight || 64;
  document.documentElement.style.setProperty('--header-height', `${(headerHeight + 1)}px`);


  const location = useLocation()
  React.useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  React.useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])


  return <Container fluid={'xxxl'}>
    <Row>
      <Col xs={12} className={'g-0'}>
        {/* <PrivateHeader /> */}
        <div className='wrapper d-flex flex-column flex-row-fluid kt-wrapper-custom' id='kt_wrapper'>
          <HeaderWrapper />
        </div>

        <StyledContent>
          <Menu isResponsive={true} />
          <StyledChildren>{children}</StyledChildren>
        </StyledContent>
      </Col>
    </Row>
    
    <ConfirmModal />
  </Container>
}

PrivateLayout.propTypes = {
  children: PropTypes.node,
};
