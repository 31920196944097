/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import { axios } from '../../utils/axios';
import { ReadAPIParams, success, successAPI, errorAPI } from '../../utils/utils';
// import {  } from '../../utils/enums';


interface InitState {
  show: boolean,
}


function NewReducer() {
  const name = 'manageLayoutSlice';


  const initialState: InitState = {
    show: true,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },

    setShow: (state: InitState, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
  };


  const apis = {
    
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();