import React from 'react';
import moment from 'moment';

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { Button } from 'react-bootstrap';
import { Popover } from 'antd';

import { dateViewFormat } from '../../utils/utils';

// ----------------------------------------------------------------------


const RangePicker = React.forwardRef(({ placement = 'bottomLeft', icon, placeholder = 'Select dates', ranges, onVisibleChange, ...props }: any, ref: any) => {
    const [open, setOpen] = React.useState(false);
    const [text, setText] = React.useState();

    
    React.useImperativeHandle(
        ref,
        () => ({
            hide() {
                setOpen(false);
            },
        }),
    );


    React.useEffect(() => {
        setText(getText())
    }, [ranges]);


    const getText = () => {
        if(ranges && ranges.length > 0){
            if((ranges[0].startDate != null) && ranges[0].endDate != null){
                return moment(ranges[0].startDate).format(dateViewFormat()) + ' - ' + moment(ranges[0].endDate).format(dateViewFormat())
            } else {
                if(ranges[0].startDate != null){
                    return moment(ranges[0].startDate).format(dateViewFormat())
                } else if(ranges[0].endDate != null){
                    return moment(ranges[0].endDate).format(dateViewFormat())
                } else {
                    return placeholder;
                }
            }
        } else {
            return placeholder;
        }
    };

    const getPicker = () => {
        return <DateRangePicker ranges={ranges} retainEndDateOnFirstSelection={true} {...props } />
    };
    

    return <Popover 
        open={open}
        onOpenChange={(newOpen: boolean) => {
            setOpen(newOpen);

            if (!newOpen) {
                if(onVisibleChange){
                    onVisibleChange(ranges);
                }
            }
        }}
        zIndex={1056}

        title={null}
        placement={placement}
        content={getPicker()}
        trigger="click"
    >
        <Button variant={'custom-outlined'} size={'sm'}>{icon} {text}</Button>
    </Popover>
  });
  

  export default RangePicker;