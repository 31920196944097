import _ from 'lodash';
import queryString from "query-string";

// querystring ----------------------------------------------------------------------

export const getIntProps = (props: any = null, field: string = 'id') => {
  let id = null;

  if (props == null || props[field] == null) {
      let q: any = queryString.parse(window.location.search);
      id = (q[field]) ? parseInt(q[field]) : 0;
  } else {
      id = (props[field]) ? props[field] : null;
  }

  return id;
}
export const getStringProps = (props: any = null, field: string = 'name') => {
  let name = '';

  if (props == null || props[field] == null) {
      let q: any = queryString.parse(window.location.search);
      name = (q[field]) ? decodeURIComponent(q[field]) : '';
  } else {
      name = (props[field]) ? decodeURIComponent(props[field]) : '';
  }

  return name;
}
export const getBoolProps = (props: any = null, field: string = 'isCopy') => {
  let state: any = '';
  let param = 'false';

  if (props == null || props[field] == null) {
      let q: any = queryString.parse(window.location.search);
      param = (q[field]) ? decodeURIComponent(q[field]) : 'false';
  } else {
      param = (props[field]) ? decodeURIComponent(props[field]) : 'false';
  }


  if (param.toLowerCase().trim() == 'true') {
      state = true;
  } else if (param.toLowerCase().trim() == 'false') {
      state = false;
  } else {
      state = false;
  }

  return state;
}

export const setTextProps = (param: string = '', text: string = '') => {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set(param, text);
  return { search: queryParams.toString() }
}
export const getTextProps = (param: string = '', fallback: string = '') => {
  let q: any = queryString.parse(window.location.search);
  let text = (q[param]) ? decodeURIComponent(q[param]) : '';
  return (text && text != '') ? text : fallback;
}
