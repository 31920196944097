/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import { axios } from '../../utils/axios';
import { ReadAPIParams, success, successAPI, errorAPI } from '../../utils/utils';
// import {  } from '../../utils/enums';


export const CURRENT_PAGE = 1;
export const PAGE_SIZE = 30;
export const SORT_COLUMN = 'updated';
export const SORT_DIR = 'desc';
export const SEARCH_QUERY = null;
export const IS_INCLUDE_INACTIVE = null;
export const IS_INACTIVE = null;

interface InitState {
  id: number|null,
  show: boolean,

  isLoadingDetails: boolean,
  details: any,

  isLoadingList: boolean,
  list: Array<any>,
  total: number,
  
  currentPage: number,
  pageSize: number,
  sortColumn: string|null,
  sortDir: string|null,
  searchQuery: string|null,
  isIncludeInactive: boolean|null,
  isInactive: boolean|null,
}


export const getAllActiveInactiveText = (isIncludeInactive: any = null, isInactive: any = false) => {
  if (isIncludeInactive && isInactive == null) {
    return 'All';
  } else if (isIncludeInactive == null && !isInactive) {
    return 'Active';
  } else if (isIncludeInactive == null && isInactive) {
    return 'Inactive';
  } else {
    return 'All';
  }
}

export const findSortItem = (arr: any = [], text: any = '', param: any = 'value') => {
  let item = null;

  if (arr && arr.length > 0) {
    let statusItem = arr.filter((x: any) => x[param].toLowerCase().trim() === text.toLowerCase().trim());

    if (statusItem && statusItem.length > 0) {
      item = statusItem[0];
    }
  }

  return item;
}
export const findSortItemTemplate = (arr: any = [], value: any = '', rowItem: any = null, param: any = 'template') => {
  let item = findSortItem(arr, value, 'value');
  return (item && item[param]) ? item[param](rowItem) : null;
}


function NewReducer() {
  const name = 'customerLayoutSlice';


  const initialState: InitState = {
    id: null,
    show: true,

    isLoadingDetails: false,
    details: null,
    
    isLoadingList: false,
    list: [],
    total: 0,
    
    currentPage: CURRENT_PAGE,
    pageSize: PAGE_SIZE,
    sortColumn: SORT_COLUMN,
    sortDir: SORT_DIR,
    searchQuery: SEARCH_QUERY,
    isIncludeInactive: IS_INCLUDE_INACTIVE,
    isInactive: IS_INACTIVE,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },

    setFirstLoad: (state: InitState, action: PayloadAction<number|null>) => {
      state.id = action.payload;
      state.list = [];
      state.total = 0;
      state.details = null;

      state.currentPage = CURRENT_PAGE;
      state.pageSize = PAGE_SIZE;
      state.sortColumn = SORT_COLUMN;
      state.sortDir = SORT_DIR;
      state.searchQuery = SEARCH_QUERY;
      state.isIncludeInactive = IS_INCLUDE_INACTIVE;
      state.isInactive = IS_INACTIVE;
    },
    setRefreshList: (state: InitState) => {
      state.list = [];
      state.total = 0;

      state.currentPage = CURRENT_PAGE;
      state.pageSize = PAGE_SIZE;
      state.sortColumn = SORT_COLUMN;
      state.sortDir = SORT_DIR;
      state.searchQuery = SEARCH_QUERY;
      state.isIncludeInactive = IS_INCLUDE_INACTIVE;
      state.isInactive = IS_INACTIVE;
    },
    setId: (state: InitState, action: PayloadAction<number|null>) => {
      state.id = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
    setDetails: (state: InitState, action: PayloadAction<boolean>) => {
      state.details = action.payload;
    },

    addInList: (state: InitState, action: PayloadAction<any>) => {
      if(action.payload){
        let list = _.cloneDeep(state.list);
        list.unshift(action.payload);
        state.list = list;
      }
    },
    editInList: (state: InitState, action: PayloadAction<{ field: any, item: any}>) => {
      let list = _.cloneDeep(state.list);
      let index = list.findIndex((x: any) => x[action.payload.field] == action.payload.item[action.payload.field]);
      if(index > -1){
        list[index] = action.payload.item
        state.list = list;
      }
    },
    editFieldInList: (state: InitState, action: PayloadAction<{ fieldId: any, field: any, value: any}>) => {
      let list = _.cloneDeep(state.list);
      let index = list.findIndex((x: any) => x[action.payload.fieldId] == state.id);
      if(index > -1){
        list[index][action.payload.field] = action.payload.value
        state.list = list;
      }
    },
    removeFromList: (state: InitState, action: PayloadAction<{ field: any, id: any}>) => {
      let list = _.cloneDeep(state.list);
      let newList = list.filter((x: any) => x[action.payload.field] != action.payload.id);
      state.list = newList;
    },
    
    setCurrentPage: (state: InitState, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    setSearchQuery: (state: InitState, action: PayloadAction<any>) => {
      state.searchQuery = action.payload;
      state.currentPage = 1;
    },
    setSortDir: (state: InitState, action: PayloadAction<any>) => {
      state.sortDir = action.payload;
      state.currentPage = 1;
    },
    setSortColumn: (state: InitState, action: PayloadAction<any>) => {
      state.sortColumn = action.payload;
      state.currentPage = 1;
    },
    setActive: (state: InitState, action: PayloadAction<{ isIncludeInactive: any, isInactive: any }>) => {
      state.isIncludeInactive = action.payload.isIncludeInactive;
      state.isInactive = action.payload.isInactive;
      state.currentPage = 1;
    },

    startRead: (state: InitState) => {
      state.isLoadingList = true;
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingList = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      let total = (action.payload && action.payload.total) ? action.payload.total : 0;

      state.list = data;
      state.total = total;
    },

    startReadMore: (state: InitState) => {
      state.isLoadingList = true;
    },
    finishReadMore: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingList = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      let total = (action.payload && action.payload.total) ? action.payload.total : 0;

      let arr = state.list;
      let newData = data;
      let newArray = _.concat(arr, newData);

      state.list = newArray;
      state.total = total;
    },

    startDetails: (state: InitState) => {
      state.isLoadingDetails = true;
      state.details = null;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingDetails = false;
      state.details = action.payload;
    },
    
    startActivate: (state: InitState) => {
      state.isLoadingDetails = true;
    },
    finishActivate: (state: InitState, action: PayloadAction<{ fieldId: any, fieldActive: any, isActive: any}>) => {
      state.isLoadingDetails = false;
      
      if(action.payload.fieldActive){
        let details = _.cloneDeep(state.details);
        details[action.payload.fieldActive] = action.payload.isActive;
        state.details = details;
      }
    },
    
    startDelete: (state: InitState) => {
      state.isLoadingDetails = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingDetails = false;

      if(action.payload){
        state.details = null;
      }
    },
  };


  const apis = {
    callFirstLoad: (id: number|null, callback: (initParams: any) => void) => async (dispatch: any, getState: any) => {
      await dispatch(actions.setFirstLoad(id));

      const { currentPage, pageSize, searchQuery, sortColumn, sortDir, isIncludeInactive, isInactive } = getState().customerLayoutSlice;
      
      let params = {
        currentPage: currentPage,
        pageSize: pageSize,
        searchQuery: searchQuery,
        sortColumn: sortColumn,
        sortDir: sortDir,
        isIncludeInactive: isIncludeInactive,
        isInactive: isInactive,
      };

      callback(params);
    },

    callReadApi: (params: ReadAPIParams, path: string, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startRead());

      await axios.get(path, { params: params }).then(result => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishRead(data));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishRead(null));
      });
    },
    
    callReadMoreApi: (params: ReadAPIParams, path: string, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadMore());

      await axios.get(path, { params: params }).then(result => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishReadMore(data));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishReadMore(null));
      });
    },

    callDetailsApi: (id: number|null, path: string, params: any = null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDetails());

      await axios.get(path + '/' + id, { params: params }).then(result => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishDetails(data));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishDetails(null));
      });
    },

    callActivateApi: (isActive: boolean, fieldActive: string, fieldId: string, path: string, callback: (state: boolean, data: any) => void) => async (dispatch: any, getState: any) => {
      const { id } = getState().customerLayoutSlice;
      
      dispatch(actions.startActivate());

      let params = {
        isActive: isActive,
        ids: id.toString(),
      };

      await axios.put(path, params).then(result => {
        let data = result.data;
        
        // successAPI(data);
        success('Mark as ' + (isActive ? 'active' : 'inactive') + ' successfully', 'Your data has been successfully marked as ' + (isActive ? 'active' : 'inactive') + '.');

        let obj = (data && data.data) ? data.data : null;
        callback(true, obj);
        dispatch(actions.finishActivate({ fieldId: fieldId, fieldActive: fieldActive, isActive: isActive}));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishActivate({ fieldId: null, fieldActive: null, isActive: isActive}));
      });
    },

    callDeleteApi: (params: any, path: string, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDelete());

      await axios.delete(path, { data: params }).then(result => {
          let data = result.data;

          // successAPI(data);
          success(data, 'Your data has been successfully deleted.');


          callback(true, data);
          dispatch(actions.finishDelete(data));
      }).catch(error => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishDelete(null));
      });
  },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();