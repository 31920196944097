import _ from 'lodash';

const CHARACTERS = ['\\', ':', ';', '*', '?', '"', '<', '>', '|',];
const ALPHABETIC_CHARACTERS = ['\\', ':', ';', '*', '?', '"', '<', '>', '|', '/', '.', ',', '\'', '[', ']', '{', '}', '!', '@', '#', '$', '%', '^', '&', '(', ')', '_', '=', '+'];

// Escape chars ----------------------------------------------------------------------

export const escapeChar = (e: any = null, char: any = null) => {
  if (e && char) {
      if (char === e.key) {
          e.preventDefault();
          e.stopPropagation();
          return true;
      }
  }

  return false;
}

export const escapeChars = (e: any = null) => {
  if (e) {
      if (CHARACTERS.includes(e.key)) {
          return escapeChar(e, e.key);
      }
  }

  return false;
}
export const escapeCharsInStringOnPaste = (str: any = '') => {
  let cleanedText = str;
  CHARACTERS.forEach((character) => {
      cleanedText = cleanedText.split(character).join('');
  });
  return cleanedText;
}

export const escapeAlphabeticChars = (e: any = null) => {
  if (e) {
      if (ALPHABETIC_CHARACTERS.includes(e.key)) {
          return escapeChar(e, e.key);
      }
  }

  return false;
}
export const escapeAlphabeticCharsInStringOnPaste = (str: any = '') => {
  let cleanedText = str;
  ALPHABETIC_CHARACTERS.forEach((character) => {
      cleanedText = cleanedText.split(character).join('');
  });
  return cleanedText;
}

export const limitRows = (value: any, rows = 5, callback: any = null) => {
  try {
      if(rows > 0){
          const lines = value.split('\n');
          if (lines.length <= rows) {
              if(callback){
                  callback(value);
              }

          } else if(lines.length > rows){
              let newRows: any = [];
              let newExtraRows: any = [];

              lines.forEach((row: any, i: number) => {
                  if(i < rows){
                      newRows.push(row);
                  } else {
                      newExtraRows.push(row);
                  }
              });

              let newValue = (newRows && newRows.length > 0) ? newRows.join('\n') : '';
              let newExtraValue = (newExtraRows && newExtraRows.length > 0) ? newExtraRows.join(' ') : '';
              let result = newValue + ((newExtraValue && newExtraValue != '') ? ' ' + newExtraValue : '');

              if(callback){
                  callback(result);
              }
          }
      } else {
          if(callback){
              callback(value);
          }
      }
  } catch(e){}
}
export const placeholderRows = (rows = 5, firstLineText = '', lineText = '') => {
  let newRows = [];
  for(let i = 0; i < rows; i++){
      if(firstLineText != ''){
          if(i === 0){
              newRows.push(firstLineText);
          } else {
              if(lineText != ''){
                  newRows.push(lineText + i);
              }
          }
      } else {
          if(lineText != ''){
              newRows.push(lineText + (i+1));
          }
      }
  }
  return (newRows && newRows.length > 0) ? newRows.join('\n') : '';
}
