/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import { axios } from '../../../../utils/axios';
import { successAPI, errorAPI, getMaxPageSize } from '../../../../utils/utils';
// import Yup from '../../../../utils/yup';
// import {  } from '../../../../utils/enums';


interface InitState {
  isLoadingChart: boolean,
  dataChart: any,
  totalJobs: number,

  isLoadingStatuses: boolean,
  statuses: any,

  isLoadingCustomerSiteStats: boolean,
  customerSiteStats: any,

  isLoadingServiceItemStats: boolean,
  serviceItemStats: any,
}

function NewReducer() {
  const name = 'customersOverviewSlice';


  const initialState: InitState = {
    isLoadingChart: false,
    dataChart: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: '#8BADFF'
        }
      ]
    },
    totalJobs: 0,

    isLoadingStatuses: false,
    statuses: null,

    isLoadingCustomerSiteStats: false,
    customerSiteStats: null,

    isLoadingServiceItemStats: false,
    serviceItemStats: null,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },

    startStats: (state: InitState) => {
      state.isLoadingChart = true;
    },
    finishStats: (state: InitState, action: PayloadAction<any>) => {
      let labels = [];
      let datasets = [];
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      let total = (action.payload && action.payload.total) ? action.payload.total : 0;

      if(data && data.length > 0){
        labels = data.map((x: any) => x.month);
        datasets = data.map((x: any) => x.value);
      }
      
      state.dataChart = {
        labels: labels,
        datasets: [
          {
            data: datasets,
            backgroundColor: '#8BADFF'
          }
        ]
      };
      state.totalJobs = total;
      state.isLoadingChart = false;
    },

    startJobStatus: (state: InitState) => {
      state.isLoadingStatuses = true;
    },
    finishJobStatus: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.length > 0) ? action.payload : [];

      state.statuses = data;
      state.isLoadingStatuses = false;
    },

    startCustomerSiteStats: (state: InitState) => {
      state.isLoadingCustomerSiteStats = true;
    },
    finishCustomerSiteStats: (state: InitState, action: PayloadAction<any>) => {
      state.customerSiteStats = action.payload;
      state.isLoadingCustomerSiteStats = false;
    },

    startServiceItemStats: (state: InitState) => {
      state.isLoadingServiceItemStats = true;
    },
    finishServiceItemStats: (state: InitState, action: PayloadAction<any>) => {
      state.serviceItemStats = action.payload;
      state.isLoadingServiceItemStats = false;
    },
  };


  const apis = {
    callStatsApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startStats());

      await axios.get('customer/job/stat', { params: params }).then(result => {
        let data = result?.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishStats(data));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishStats(null));
      });
    },

    callJobStatusApi: (callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startJobStatus());

      let params = {
        currentPage: 1,
        pageSize: getMaxPageSize(),
      };

      await axios.get('jobStatus', { params: params }).then(result => {
        let data = result?.data?.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishJobStatus(data));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishJobStatus(null));
      });
    },

    callCustomerSiteStatsApi: (customerId: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startCustomerSiteStats());

      await axios.get('customerSite/stat/active', { params: { customerId } }).then(result => {
        let data = result?.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishCustomerSiteStats(data));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishCustomerSiteStats(null));
      });
    },

    callServiceItemStatsApi: (customerId: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startServiceItemStats());

      await axios.get('service-item/stat/active', { params: { customerId } }).then(result => {
        let data = result?.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishServiceItemStats(data));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishServiceItemStats(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();