import _ from 'lodash';
import { toast } from 'react-toastify';

import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg";
import { ReactComponent as SuccessIcon } from "../../assets/icons/success.svg";
import { ReactComponent as WarningIcon } from "../../assets/icons/warning.svg";
import { ReactComponent as DangerIcon } from "../../assets/icons/danger.svg";

// Toast ----------------------------------------------------------------------

export const info = (title: string|null, desc: string|null = null, autoClose: number|undefined = 1500, position: any = 'top-center') => {
  let options: any = { icon: <><InfoIcon /></>, position: position };

  if(autoClose != undefined){
    options['autoClose'] = autoClose;
  }

  toast.info(<>
    {title && <div className='title'>{title}</div>}
    {desc && <div>{desc}</div>}
  </>, options);
}
export const success = (title: string|null, desc: string|null = null, autoClose: number|undefined = 1500, position: any = 'top-center') => {
  let options: any = { icon: <><SuccessIcon /></>, position: position };

  if(autoClose != undefined){
    options['autoClose'] = autoClose;
  }

  toast.success(<>
    {title && <div className='title'>{title}</div>}
    {desc && <div>{desc}</div>}
  </>, options);
}
export const warning = (title: string|null, desc: string|null = null, autoClose: number|undefined = 1500, position: any = 'top-center') => {
  let options: any = { icon: <><WarningIcon /></>, position: position };

  if(autoClose != undefined){
    options['autoClose'] = autoClose;
  }

  toast.warning(<>
    {title && <div className='title'>{title}</div>}
    {desc && <div>{desc}</div>}
  </>, options);
}
export const danger = (title: string|null, desc: string|null = null, autoClose: number|undefined = 1500, position: any = 'top-center') => {
  let options: any = { icon: <><DangerIcon /></>, position: position };

  if(autoClose != undefined){
    options['autoClose'] = autoClose;
  }

  toast.error(<>
    {title && <div className='title'>{title}</div>}
    {desc && <div>{desc}</div>}
  </>, options);
}
