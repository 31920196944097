import React from 'react';
import _, { forEach } from 'lodash';

import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

import ResizeScreen from '../../resize/ResizeScreen.tsx';
import DataTablePagination from './DataTablePagination.tsx';

// Redux
import { useDispatch } from 'react-redux';
import { AppDispatch, useTypedSelector } from '../../../utils/store.tsx';
import createGenericSlice from './tableSlice.tsx';

// ----------------------------------------------------------------------

function MaterialDataTable({ endpoint = '', method = 'get', firstTimeApiCall = true, rowId, onInit, initialParams, initialExtraParams, customSortParams, sliceName, columns, columnsRender, renderDetailPanel, showPagination = true, renderFallbackValue = '', enableRowSelection = true, enableSorting = true, enableColumnResizing = true, enableColumnOrdering = true, onChangeSort, onChangeColumnVisibility, onChangeColumnSizing, onChangeColumnOrder, onRowSelection, onRowClick = null }: any) {
    const tableSlice = createGenericSlice(sliceName);
    const { tableIsLoading, tableColumns, tableData, tableTotal, currentPage, pageSize, sortColumn, sortDir, searchQuery, isIncludeInactive, isInactive, statusFilter, fromDate, toDate, columnVisibility, columnOrder, columnSizing } = useTypedSelector((state) => state[sliceName]);
    const dispatch = useDispatch();

    
    const getDefColSize = (columns, dataTable) => {
      if(columns && columns.length > 0){
        const el = document.querySelector('#table_wrapper_ID table');
        const tableWidth = el?.clientWidth || 0;

        const COL_WIDTH = 55;

        let totalSize = _.sumBy(columns, (o) => o.size) || 0;
        if(enableRowSelection){
          totalSize = totalSize + COL_WIDTH;
        }
        if(renderDetailPanel){
          totalSize = totalSize + COL_WIDTH;
        }

        const maxSize = tableWidth - totalSize;

        let countSize = columns.filter((x) => 'size' in x).length;
        if(enableRowSelection){
          countSize = countSize + 1;
        }
        if(renderDetailPanel){
          countSize = countSize + 1;
        }
        if(enableRowSelection || renderDetailPanel){
          countSize = countSize + 1;
        }

        let columnLength = columns.length;
        try {
          columnLength = dataTable.getVisibleLeafColumns().length;
        }catch(e){}
        if(enableRowSelection){
          // columnLength = columnLength - 1;
        }
        if(renderDetailPanel){
          // columnLength = columnLength - 1;
        }
        
        const defColWidth = (maxSize / (columnLength - countSize));
        
        let defColSize = {};
        columns.forEach((x: any, i: number) => {
          defColSize[x.accessorKey] = x.size ? x.size : defColWidth;
        });
        if(enableRowSelection){
          defColSize['mrt-row-select'] = COL_WIDTH;
        }
        if(renderDetailPanel){
          defColSize['mrt-row-expand'] = COL_WIDTH;
        }

        return defColSize;
      } else {
        return {};
      }
    }

    const getInitColSize = () => {
      if(tableColumns && tableColumns.length > 0){
        if(_.isEmpty(columnSizing)){
          const el = document.querySelector('#table_wrapper_ID table');
          const tableWidth = el?.clientWidth || 0;
      
          const COL_WIDTH = 55;

          let defColSize = {};
          columns.forEach((x: any, i: number) => {
            if(columnSizing[x.accessorKey]){
              defColSize[x.accessorKey] = columnSizing[x.accessorKey];
            } else {
              defColSize[x.accessorKey] = x.size;
            }
          });
          if(enableRowSelection){
            defColSize['mrt-row-select'] = COL_WIDTH;
          }
          if(renderDetailPanel){
            defColSize['mrt-row-expand'] = COL_WIDTH;
          }
          
          const total = _.sum(_.values(defColSize));

          if(total < tableWidth){
            const maxSize = tableWidth - total;

            try {
              let lastColumn = tableColumns[tableColumns.length - 1];
              defColSize[lastColumn.accessorKey] = lastColumn.size + maxSize - 1;
            }catch(e){}

            return defColSize;
          } else {
            return {}
          }
        } else {
          return {}
        }
      } else {
        return {}
      }
    }

    const initColumnsWidth = () => {
      dispatch(tableSlice.setColumnSizing(getInitColSize()));
    }


    let initColumnOrder = (columns && columns.length > 0) ? [...columns.map((c) => c.accessorKey)] : [];
    
    const [counter, setCounter] = React.useState(1);
    const [isTableInitialized, setIsTableInitialized] = React.useState(false);

    const [rowSelection, setRowSelection] = React.useState({})
    const [sorting, setSorting] = React.useState([ { id: sortColumn, desc: ((sortDir == 'desc') ? true : false) } ])
    const [pagination, setPagination] = React.useState(initialParams ? { pageIndex: (initialParams.currentPage-1), pageSize: initialParams.pageSize } : { pageIndex: (currentPage-1), pageSize: pageSize })


    const getTableColumns = () => {
      let cols = _.cloneDeep(tableColumns);
      if(columnsRender && columnsRender.length > 0){
        columnsRender.forEach((col, i) => {
          let index = cols.findIndex(x => x.id === col.id);
          if(index > -1){
            cols[index]['Cell'] = col?.Cell
            cols[index]['PDF'] = col?.PDF
          }
        });
      }
      return cols;
    }


    const dataTable = useMaterialReactTable({
      // layoutMode: 'grid-no-grow',
      getRowId: (row: any) => {
        if(rowId){
          return row[rowId]
        } else {
          return undefined
        }
      },

      data: tableData,
      rowCount: tableTotal,
      columns: getTableColumns(),
      
      initialState: {
        columnPinning: {
          left: [ 'mrt-row-expand', 'mrt-row-select' ],
        },
      },
      state: {
        isLoading: tableIsLoading,
        pagination,
        columnSizing,
        columnOrder,
        columnVisibility,
        rowSelection,
        sorting,
      },
      
      enableTopToolbar: false,
      enableBottomToolbar: false,
      enableColumnActions: false,
      enableRowNumbers: false,
      enablePagination: false,
      enableStickyHeader: true,

      enableSorting: enableSorting,
      enableColumnOrdering: enableColumnOrdering,
      enableColumnResizing: enableColumnResizing,
      enableRowSelection: enableRowSelection,
      enableSelectAll: true,
      renderFallbackValue: renderFallbackValue,

      enableColumnVirtualization: true,
      enableRowVirtualization: true,

      // memoMode: 'row',
      columnResizeMode: 'onEnd', //'onEnd', //onChange
      manualPagination: true,
      manualSorting: true,
      enableMultiSort: false,
      muiTableBodyRowProps: ({ row }) => {
        return {
          id: 'row_' + row.original[rowId],
          hover: true,
          onClick: (event) => {
            if(onRowClick){
              onRowClick(row);
            }
          },
          sx: {
            cursor: (onRowClick) ? 'pointer' : 'default',
            '&:hover': {
              backgroundColor: (onRowClick) ? '#F6F9FF !important' : 'transparent !important',
            },
          },
        }
      },

      // onColumnSizingChange: setColumnSizing,
      onColumnSizingChange: (x: any) => {
        try {
          let obj = x();
          const mergedObject = Object.assign({}, columnSizing, obj);
          dispatch(tableSlice.setColumnSizing(mergedObject));
          
          if(onChangeColumnSizing){
            onChangeColumnSizing(mergedObject);
          }
        }catch(e){}
      },
      // onColumnOrderChange: setColumnOrder,
      onColumnOrderChange: (x: any) => {
        if (isTableInitialized) {
          try {
            dispatch(tableSlice.setColumnOrder(x));
            
            if(onChangeColumnOrder){
              onChangeColumnOrder(x);
            }
          }catch(e){}
        }
      },
      // onColumnVisibilityChange: setColumnVisibility,
      onColumnVisibilityChange: (x: any) => {
        try {
          let obj = x();
          const mergedObject = Object.assign({}, columnVisibility, obj);
          dispatch(tableSlice.setColumnVisibility(mergedObject));
          
          if(onChangeColumnVisibility){
            onChangeColumnVisibility(mergedObject);
          }
        }catch(e){}
      },
      // onSortingChange: setSorting,
      onSortingChange: (x: any) => {
        setSorting(x);
        
        try {
          let sortX = x();
          let sort = (sortX && sortX.length > 0) ? sortX[0] : null;
          let sortC = (sort && sort.id) ? sort.id : sortColumn;
          let sortD = (sort && ((sort.desc == false) || (sort.desc == true))) ? sort.desc ? 'desc' : 'asc' : sortDir;
          
          if(onChangeSort){
            onChangeSort(sortC, sortD);
          }
        }catch(e){}
      },
      
      onPaginationChange: setPagination,
      onRowSelectionChange: setRowSelection,

      // renderEmptyRowsFallback: ({ table }) => {
      //   return 'No Results'
      // },

      renderDetailPanel: renderDetailPanel,

      // muiTableBodyCellProps: (data: any) => {
      //   let renderedCellValue = data?.cell?.getValue();
      //   let cell = data?.cell;
      //   let column = data?.column;
      //   let row = data?.row;
      //   let table = data?.table;

      //   let value = renderedCellValue;
      //   if(column?.columnDef?.Cell){
      //     value = column?.columnDef?.Cell({ cell, column, renderedCellValue, row, table });
      //   }

      //   return {
      //     children: <div className='custom-cell-line-3'>{value}</div>,
      //   }
      // },

      debugAll: false,
    });

    
    // React.useEffect(() => {
    //   if(tableColumns && tableColumns.length > 0){
    //     setTimeout(() => {
    //       initColumnsWidth();
    //     }, 700);
    //   }
    // }, [tableColumns]);

    React.useEffect(() => {
      if(onRowSelection){
        onRowSelection(dataTable.getSelectedRowModel().rows);
      }
    }, [dataTable.getState().rowSelection]);

    // onColumnsChange
    // React.useEffect(() => {
    //   dispatch(tableSlice.setColumnOrder(tableColumns.map((c) => c.accessorKey)));
    // }, [tableColumns]);

    // onResize
    // React.useEffect(() => {
    //   if(_.isEmpty(columnSizing)){
    //     dispatch(tableSlice.setColumnSizing(getDefColSize(tableColumns, dataTable)));
    //   }
    // }, [columnSizing, columnOrder]);

    
    // onVisibility
    React.useEffect(() => {
      if(onInit){
        onInit({
          state: dataTable.getState(),
          table: dataTable,
          columns: dataTable?.getAllColumns(),
          columnsRender: columnsRender,
          initColumnsWidth: initColumnsWidth,
        });
      }

      // dispatch(tableSlice.setColumnSizing(getDefColSize(tableColumns, dataTable)));
    }, [columnVisibility]);

    // onChecked
    // React.useEffect(() => {
      
    // }, [rowSelection]);

    // onPagination and onSorting
    React.useEffect(() => {
      setIsTableInitialized(true);

      if(onInit){
        onInit({
          state: dataTable.getState(),
          table: dataTable,
          columns: dataTable?.getAllColumns(),
          columnsRender: columnsRender,
          initColumnsWidth: initColumnsWidth,
        });
      }

      let shouldCallApi = false;
      if(firstTimeApiCall){
        shouldCallApi = true;
      } else {
        if(counter > 1){
          shouldCallApi = true;
        }
      }

      if(shouldCallApi){
        let currentPage = (pagination.pageIndex+1);
        let pageSize = pagination.pageSize;
        let sortColumn = (sorting && sorting.length > 0) ? sorting[0].id : null;
        let sortDir = (sorting && sorting.length > 0) ? ((sorting[0].desc == true) ? 'desc' : 'asc') : null;
  
        if(customSortParams && sortColumn && customSortParams[sortColumn]){
          sortColumn = customSortParams[sortColumn];
        }
  
        let params = {
          currentPage: currentPage,
          pageSize: pageSize,
          sortColumn: sortColumn,
          sortDir: sortDir,
          searchQuery: searchQuery,
          isIncludeInactive: isIncludeInactive,
          isInactive: isInactive,
          statusFilter: statusFilter,
          fromDate: fromDate,
          toDate: toDate,
        };
        dispatch(tableSlice.callSetOptions(params));
        callApi(params, initialExtraParams);
      }

      setCounter(counter+1)
    }, [pagination, sorting]);

    // onInit
    React.useEffect(() => {
      dispatch(tableSlice.callSetColumns(columns));
      // dispatch(tableSlice.callSetOptions(initialParams));
      // dispatch(tableSlice.setColumnSizing(getDefColSize(tableColumns, dataTable)));
    }, []);


    const callApi = (params, extraParams, callback: (state, data) => void = () => {}) => {
      dispatch(tableSlice.callSetOptions(params));
      if(method === 'post'){
        dispatch(tableSlice.callReadPostApi(endpoint, extraParams, false, false, callback));
      } else {
        dispatch(tableSlice.callReadApi(endpoint, extraParams, false, false, callback));
      }
    }
    

    return <div id={'table_wrapper_ID'} >
      <MaterialReactTable table={dataTable} />

      {showPagination && <DataTablePagination dataTable={dataTable} columnOrder={columnOrder} tableTotal={tableTotal} />}
      
      <ResizeScreen
        onResize={(width, height) => {
          // dispatch(tableSlice.setColumnSizing(getDefColSize(tableColumns, dataTable)));
        }}
      />
    </div>
};

export default MaterialDataTable;