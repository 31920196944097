import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Form,
  Button,
  InputGroup,
} from 'react-bootstrap';
import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Switch, Divider } from "antd";
import CurrencyInput from 'react-currency-input-field';

// Auth
// import { useAuthContext } from '../../../../../auth/use-auth-context.js';
// import { useRouter } from '../../../../../auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../../utils/store.tsx';
import slice, { useFormikContext, fields, getDriverAppOptionsStepName, getDriverAppOptionsStepInfo } from '../slice.tsx';

// enums
// import {  } from '../../../../../utils/utils.tsx';
// import {  } from '../../../../../utils/enums.tsx';

// components
// import MainContainer from '../../../../../components/container/MainContainer.jsx';

import { ReactComponent as PlusIcon } from "../../../../../../v5/assets/icons/plus-2.svg";
import { ReactComponent as MinusIcon } from "../../../../../../v5/assets/icons/minus.svg";

// ----------------------------------------------------------------------

const StyledStep = styled(Col)`
  border-radius: 6px;
  border: 1px solid var(--bs-gray-100);
  padding: 16px 24px;
  
  .ant-divider {
    margin-top: 4px;
    margin-bottom: 4px;
    border-color: var(--bs-gray-100);
  }
`;
const StyledInputGroup = styled(InputGroup)`
  .input-group-text {
    padding: 5px 8px !important;
    cursor: pointer;

    svg {
      position: relative;
      top: -2px;
    }

    &:hover {
      svg {
        path {
          stroke: var(--bs-gray-900) !important;
        }
      }
    }

    &:first-child ~ .form-control.custom {
      padding-left: 30px !important;
    }
  }

  .form-control {
    width: 80px;
    padding-right: 30px !important;
    text-align: center;
    padding-top: 4px !important;
    padding-bottom: 2px !important;
    min-height: 32px !important;

    &.custom {
      padding-right: 30px !important;
    }
  }
`;

const Tab3 = () => {
  // const location = useLocation();
  // const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  // const { isLoading } = useTypedSelector((state: RootState) => state.manageJobTypesSlice);
  // const dispatch = useDispatch<AppDispatch>();


  const setFormElements = () => {
    return <Row className='g-16'>

      {values.steps.map((item: any, i: number) => {
        let stepName: any = getDriverAppOptionsStepName(i, item, values.steps);
        let stepInfo: any = getDriverAppOptionsStepInfo(i, item, values.steps);
        
        return <StyledStep key={'step_tab3_' + i} xs={12}>
          <Row className='g-12'>
            <Col xs={12} className='semibold'>Step {i+1}: {stepName}</Col>
            
            {stepInfo?.RequiredBinID && <Col xs={12} className='mt-16'>
              <Row className='align-items-center'>
                <Col xs={true}>The driver is required to input bin ID</Col>
                <Col xs={'auto'}>
                  <Switch
                    size='small'
                    checked={true}
                    onChange={(checked) => {
                      console.info(`checked = ${checked}`);
                    }}
                  />
                </Col>
              </Row>
            </Col>}

            {stepInfo?.TakePhoto && <>
              {(stepInfo?.RequiredBinID) && <Col xs={12}><Divider /></Col>}
              <Col xs={12}>
                <Row className='align-items-center'>
                  <Col xs={true}>
                    <Row className='align-items-center'>
                      <Col xs={'auto'}>The driver is required to take</Col>
                      <Col xs={'auto'}>
                        <Form.Group>
                          <StyledInputGroup>
                            <InputGroup.Text
                              onClick={() => {

                              }}
                            >
                              <MinusIcon />
                            </InputGroup.Text>
                            <Form.Control
                              className='custom'
                              as={CurrencyInput}
                              allowDecimals={false}
                              allowNegativeValue={false}
                              decimalsLimit={0}
                              maxLength={1}

                              autoComplete='off'
                              placeholder={''}
                              value={'1'}
                              onValueChange={(value: any) => {
                                console.info('⚡ | value:', value);
                              }}
                              isInvalid={false}
                            />
                            <InputGroup.Text
                              onClick={() => {
                                  
                              }}
                            >
                              <PlusIcon />
                            </InputGroup.Text>
                          </StyledInputGroup>
                          <Form.Control.Feedback type="invalid">Input helper text</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={'auto'}>photo</Col>
                    </Row>
                  </Col>
                  <Col xs={'auto'}>
                    <Switch
                      size='small'
                      checked={true}
                      onChange={(checked) => {
                        console.info(`checked = ${checked}`);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </>}
            
            {stepInfo?.Signature && <>
              {(stepInfo?.RequiredBinID || stepInfo?.TakePhoto) && <Col xs={12}><Divider /></Col>}
              <Col xs={12}>
                <Row className='align-items-center'>
                  <Col xs={true}>The driver is required to take signature</Col>
                  <Col xs={'auto'}>
                    <Switch
                      size='small'
                      checked={true}
                      onChange={(checked) => {
                        console.info(`checked = ${checked}`);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </>}

            {stepInfo?.InputBinweight && <>
              {(stepInfo?.RequiredBinID || stepInfo?.TakePhoto || stepInfo?.Signature) && <Col xs={12}><Divider /></Col>}
              <Col xs={12} className='mt-16'>
                <Row className='align-items-center'>
                  <Col xs={true}>The driver is required to input bin weight</Col>
                  <Col xs={'auto'}>
                    <Switch
                      size='small'
                      checked={true}
                      onChange={(checked) => {
                        console.info(`checked = ${checked}`);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </>}
            
            {stepInfo?.ReviewWasteType && <>
              {(stepInfo?.RequiredBinID || stepInfo?.TakePhoto || stepInfo?.Signature || stepInfo?.InputBinweight) && <Col xs={12}><Divider /></Col>}
              <Col xs={12} className='mt-16'>
                <Row className='align-items-center'>
                  <Col xs={true}>The driver is required to review waste type</Col>
                  <Col xs={'auto'}>
                    <Switch
                      size='small'
                      checked={true}
                      onChange={(checked) => {
                        console.info(`checked = ${checked}`);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </>}
            
          </Row>
        </StyledStep>
      })}

    </Row>
  }


  return <Row className='g-24 mb-100'>
    <Col xs={12}>
      <Row className='g-8'>
        <Col xs={12}>
          <span className="large medium gray-900">Job Setting</span>
        </Col>
        <Col xs={12}>
          <span className="gray-800">Determine how much driver interaction with the app on each job step.</span>
        </Col>
      </Row>
    </Col>

    <Col xs={12} md={10} lg={8} xl={6} xxl={5} xxxl={4}>
      {setFormElements()}
    </Col>
  </Row>
}

export default Tab3;
