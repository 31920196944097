import React, { Component } from "react";
// import moment from 'moment';

import apiUtil from "../../../api/apiUtil.jsx";
import CustomerApi from "../../../api/OLD/Dispatch/Customer.jsx";
import BinApi from "../../../api/OLD/Dispatch/BinType.jsx";
import WasteApi from "../../../api/OLD/Dispatch/WasteType.jsx";
import ReportApi from "../../../api/OLD/Dispatch/Report.jsx";

import {
  Box,
  Grid,
  Button,
  IconButton,
  FormControlLabel,
  RadioGroup,
  Radio,
  TableCell,
  TableRow,
} from "@material-ui/core";

import { ReactComponent as MailIcon } from "../../../../_metronic/assets/img/icons/report-mail.svg";
import { ReactComponent as PrintIcon } from "../../../../_metronic/assets/img/icons/report-print.svg";
import { ReactComponent as DownloadIcon } from "../../../../_metronic/assets/img/icons/report-download.svg";
import { ReactComponent as EditIcon } from "../../../../_metronic/assets/img/icons/report-edit.svg";

import ReportsLayout from "../Layout/ReportsLayout.jsx";
import SendEmailDialog from "../Dialogs/SendEmailDialog.jsx";

import Snackbar from "../../../components/OLD/Snackbar/Snackbar.jsx";
import IconsList from "../../../components/OLD/IconsList/IconsList.jsx";
import ManageLabel from "../../../components/OLD/input/ManageLabel.jsx";
// import RSuiteDateRangePicker from "../../../components/OLD/Datepicker/RSuiteDateRangePicker.jsx";
import RSuiteCheckPicker from "../../../components/OLD/Checkbox/RSuiteCheckPicker.jsx";
import CustomDialog from "../../../components/OLD/Dialog/CustomDialog.jsx";
import DropdownFilter from "../../../components/OLD/Dropdown/DropdownFilter.jsx";
import ManageInput from "../../../components/OLD/input/ManageInput.jsx";
import DataTable from "../../../components/OLD/DataTable/DataTable";
import DataTableAjaxPagination from '../../../components/OLD/DataTable/Pagination/DataTableAjaxPaginationV5';
import DataTableScrollSync from '../../../components/OLD/DataTable/DataTableScrollSync.jsx';

import Utils from "../../../utils/utils.js";
import { connect } from 'react-redux'
import signalRslice from "../../../components/signalR/signalRslice";
import taxRate from "../../../../setup/redux/slices/taxRate";
import cancelToken from "../../../../setup/redux/slices/cancelToken";


class ReportsCustomerServices extends Component {

  constructor(props) {
    super(props);


    this.pageName = 'report_customer_services';
    this.pageIdField = "serviceItemId";


    this.tableRef = React.createRef();
    this.refDataTableScrollSync = React.createRef();

    
    const priceTemplate = (id, row, col, obj, index) => {
        return taxRate.getTaxRateColumn(row);
    };
    const priceExportTemplate = (row) => {
        return Utils.getCurrency() + Utils.formatNumber(row.price);
    };
    const priceExportCSVTemplate = (row) => {
        return Utils.getCurrency() + Utils.formatNumber(row.price);
    };

    const nonBillableWeightTemplate = (id, row, col, obj, index) => {
        let bundleServices = (row && row.bundleServices && row.bundleServices.length > 0) ? row.bundleServices : [];
        let isExist = bundleServices.findIndex(x => (x.nonBillableWeight != null)) > -1;
        return isExist ? '' : row.nonBillableWeight;
    };
    const nonBillableWeightExportTemplate = (row) => {
        let bundleServices = (row && row.bundleServices && row.bundleServices.length > 0) ? row.bundleServices : [];
        let isExist = bundleServices.findIndex(x => (x.nonBillableWeight != null)) > -1;
        return isExist ? '' : row.nonBillableWeight;
    };
    const nonBillableWeightExportCSVTemplate = (row) => {
        let bundleServices = (row && row.bundleServices && row.bundleServices.length > 0) ? row.bundleServices : [];
        let isExist = bundleServices.findIndex(x => (x.nonBillableWeight != null)) > -1;
        return isExist ? '' : row.nonBillableWeight;
    };

    let columns = [
        {
            id: this.pageIdField,
            filterKey: this.pageIdField,
            name: this.pageIdField,
            label: 'ID',
            visible: false
        },
        {
            id: "customerName",
            detailsId: "customerName",
            name: "customerName",
            label: "Customer name",
            responsiveLabel: "Customer name",
            width: "150px",
            orderable: false,
        },
        {
            id: "billingProfileName",
            detailsId: "billingProfileName",
            name: "billingProfileName",
            label: "Billing by",
            responsiveLabel: "Billing by",
            width: "150px",
            orderable: false,
        },
        {
            id: "serviceTag",
            detailsId: "serviceTag",
            name: "serviceTag",
            label: "Service name",
            responsiveLabel: "Service name",
            width: "300px",
            orderable: false,
        },
        {
            id: "wasteTypeName",
            detailsId: "wasteTypeName",
            name: "wasteTypeName",
            label: "Waste type",
            responsiveLabel: "Waste type",
            width: "150px",
            orderable: false,
        },
        {
            id: "binTypeName",
            detailsId: "binTypeName",
            name: "binTypeName",
            label: "Bin type",
            responsiveLabel: "Bin type",
            width: "150px",
            orderable: false,
        },
        {
            id: "price",
            detailsId: "price",
            name: "price",
            label: "Rate",
            responsiveLabel: "Rate",
            width: "150px",
            orderable: false,
            template: priceTemplate,
            exportTemplate: priceExportTemplate,
            exportCSVTemplate: priceExportCSVTemplate,
        },
        {
            id: "billingTypeDisplay",
            detailsId: "billingTypeDisplay",
            name: "billingType",
            label: "Billing action",
            responsiveLabel: "Billing action",
            width: "150px",
            orderable: false,
        },
        {
            id: "chargeCategoryName",
            detailsId: "chargeCategoryName",
            name: "chargeCategoryName",
            label: "Billing category",
            responsiveLabel: "Billing category",
            width: "150px",
            orderable: false,
        },
        {
            id: "description",
            detailsId: "description",
            name: "description",
            label: "Description",
            responsiveLabel: "Description",
            width: "150px",
            orderable: false,
        },
        {
            id: "nonBillableWeight",
            detailsId: "nonBillableWeight",
            name: "nonBillableWeight",
            label: "Non-billable weight",
            responsiveLabel: "Non-billable weight",
            width: "150px",
            orderable: false,
            template: nonBillableWeightTemplate,
            exportTemplate: nonBillableWeightExportTemplate,
            exportCSVTemplate: nonBillableWeightExportCSVTemplate,
        },
    ];
    let initColumns = Array.from(columns);
    columns = Array.from(initColumns);


    let columnVisibility = [
        false,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
    ];
    let initColumnVisibility = Array.from(columnVisibility);
    columnVisibility = Array.from(initColumnVisibility);


    this.state = {
      isLoading: false,

      pageName: "Customer services",
      isIconClicked: false,

      // dateRange: apiUtil.getDateRangeValue('This Month'),
      // dateFormat: apiUtil.getAPIDateFormat(),

      customerIsLoading: false,
      customerText: "",
      customerId: "",
      customerItem: null,
      customer: [],

      binIsLoading: false,
      binText: "",
      binId: "",
      binItem: null,
      bin: [],

      wasteIsLoading: false,
      wasteText: "",
      wasteId: "",
      wasteItem: null,
      waste: [],
      
      search: '',
      sortColumn: 'customerName',
      sortDir: 'asc',
      isIncludeInactive: null,
      isInactive: false,

      selected: [],
      columnVisibility: columnVisibility,
      initColumnVisibility: initColumnVisibility,
      columns: columns,
      initColumns: initColumns,
      rows: [],
      currentPage: 1,
      totalRows: 0,
      rowsPerPageSelected: 300,
      rowsPerPageOptions: [50, 100, 300],

      size: 0,
    };
  }

  
    async componentWillUnmount() {
        clearInterval(this.intervalId);
        await this.props.action.cancelToken.cancel();
    }


  /* API */
  callCustomerReadApi = (searchQuery = "", callback = null) => {
    const { token } = this.context;

    this.setState({
      customerIsLoading: true,
      customer: [],
    });

    CustomerApi.readList(
      {
        currentPage: 1,
        pageSize: apiUtil.getMaxPageSize(),
        searchQuery: searchQuery,
        isIncludeInactive: false,
        sortColumn: "customerName",
        sortDir: "asc",
      },
      token
    ).then((result) => {
      apiUtil.parseResult(
        result,
        (data) => {
          let arr = [];

          if (data && data.data && data.data.length > 0) {
            for (var i = 0; i < data.data.length; i++) {
              let item = data.data[i];
              let id = item.customerId;
              let name = item.customerNameDisplay;

              if (arr.findIndex((x) => x.value === id) === -1) {
                arr.push({
                  value: id,
                  title: name,
                  item: item,
                });
              }
            }
          }

          this.setState(
            {
              customer: arr,
              customerIsLoading: false,
            },
            () => {
              if (callback !== null) {
                callback(this.state.customer);
              }
            }
          );
        },
        (error, type) => {
          this.setState({
            customer: [],
            customerIsLoading: false,
          });
        }
      );
    });
  };

  callBinReadApi = (searchQuery = "", callback = null) => {
    const { token } = this.context;

    this.setState({
        binIsLoading: true,
      bin: [],
    });

    BinApi.read(
      {
        currentPage: 1,
        pageSize: apiUtil.getMaxPageSize(),
        searchQuery: searchQuery,
        isIncludeInactive: false,
        sortColumn: "binTypeName",
        sortDir: "asc",
      },
      token
    ).then((result) => {
      apiUtil.parseResult(
        result,
        (data) => {
          let arr = [];

          if (data && data.data && data.data.length > 0) {
            for (var i = 0; i < data.data.length; i++) {
              let item = data.data[i];
              let id = item.binTypeId;
              let name = item.binTypeName;

              if (arr.findIndex((x) => x.value === id) === -1) {
                arr.push({
                  value: id,
                  title: name,
                  item: item,
                });
              }
            }
          }

          this.setState(
            {
              bin: arr,
              binIsLoading: false,
            },
            () => {
              if (callback !== null) {
                callback(this.state.bin);
              }
            }
          );
        },
        (error, type) => {
          this.setState({
            bin: [],
            binIsLoading: false,
          });
        }
      );
    });
  };
  
  callWasteReadApi = (searchQuery = "", callback = null) => {
    const { token } = this.context;

    this.setState({
      wasteIsLoading: true,
      waste: [],
    });

    WasteApi.read(
      {
        currentPage: 1,
        pageSize: apiUtil.getMaxPageSize(),
        searchQuery: searchQuery,
        isIncludeInactive: false,
        sortColumn: "wasteTypeName",
        sortDir: "asc",
      },
      token
    ).then((result) => {
      apiUtil.parseResult(
        result,
        (data) => {
          let arr = [];

          if (data && data.data && data.data.length > 0) {
            for (var i = 0; i < data.data.length; i++) {
              let item = data.data[i];
              let id = item.wasteTypeId;
              let name = item.wasteTypeName;

              if (arr.findIndex((x) => x.value === id) === -1) {
                arr.push({
                  value: id,
                  title: name,
                  item: item,
                });
              }
            }
          }

          this.setState(
            {
              waste: arr,
              wasteIsLoading: false,
            },
            () => {
              if (callback !== null) {
                callback(this.state.waste);
              }
            }
          );
        },
        (error, type) => {
          this.setState({
            waste: [],
            wasteIsLoading: false,
          });
        }
      );
    });
  };

  callReportReadApi = async (callback = null) => {
    const { token } = this.context;

    Utils.clickOutside();

    this.setState({
      isLoading: true,
    });

    // let fromD = (this.state.dateRange && this.state.dateRange.length > 0) ? this.state.dateRange[0] : '';
    // let toD = (this.state.dateRange && this.state.dateRange.length === 2) ? this.state.dateRange[1] : '';

    // let fromDate = (fromD !== '') ? moment(fromD).format(this.state.dateFormat) : '';
    // let toDate = (toD !== '') ? moment(toD).format(this.state.dateFormat) : '';

    let customerIds = (this.state.customerSearchColumns && this.state.customerSearchColumns.length > 0) ? this.state.customerSearchColumns.join(",") : null;
    let binTypeIds = (this.state.binSearchColumns && this.state.binSearchColumns.length > 0) ? this.state.binSearchColumns.join(",") : null;
    let wasteTypeIds = (this.state.wasteSearchColumns && this.state.wasteSearchColumns.length > 0) ? this.state.wasteSearchColumns.join(",") : null;

    let formData = {
        currentPage: this.state.currentPage,
        pageSize: this.state.rowsPerPageSelected,
        sortColumn: this.state.sortColumn,
        includeGlobalService: true,
        includeLocalService: true,
        includeSites: false,
        includeTaxRate: false,
        // fromDate: fromDate,
        // toDate: toDate,
        customerIds: customerIds,
        binTypeIds: binTypeIds,
        wasteTypeIds: wasteTypeIds,
    };

    ReportApi.readCustom("service-item", formData, token).then((result) => {
      apiUtil.parseResult(
        result,
        (data) => {
            let rows = (data && data.data) ? data.data : [];
            let total = (data && data.total) ? data.total : 0;
            
          this.setState(
            {
              rows: rows,
              totalRows: total,
              isLoading: false,
            },
            () => {
              if (callback !== null) {
                callback(this.state.rows);
              }
            }
          );
        },
        (error, type) => {
          this.setState({
            rows: [],
            totalRows: 0,
            isLoading: false,
          });
        }
      );
    });
  };

  callGenerateEXCELApi = async (callback = null) => {
    const { token } = this.context;
    
    await this.props.action.cancelToken.cancel();

    await this.props.action.signalRslice.connect({
      callback: (percent, progressMessage) => {
        // this.props.action.signalRslice.onPercentage(percent);
        this.props.action.signalRslice.onPercentageWithMessage({ percentage: percent, progressMessage: progressMessage });
      },
      callbackInit: (hubId) => {}
    });
    
    Utils.clickOutside();

    // let fromD = (this.state.dateRange && this.state.dateRange.length > 0) ? this.state.dateRange[0] : '';
    // let toD = (this.state.dateRange && this.state.dateRange.length === 2) ? this.state.dateRange[1] : '';

    // let fromDate = (fromD !== '') ? moment(fromD).format(this.state.dateFormat) : '';
    // let toDate = (toD !== '') ? moment(toD).format(this.state.dateFormat) : '';

    let customerIds = (this.state.customerSearchColumns && this.state.customerSearchColumns.length > 0) ? this.state.customerSearchColumns.join(",") : null;
    let binTypeIds = (this.state.binSearchColumns && this.state.binSearchColumns.length > 0) ? this.state.binSearchColumns.join(",") : null;
    let wasteTypeIds = (this.state.wasteSearchColumns && this.state.wasteSearchColumns.length > 0) ? this.state.wasteSearchColumns.join(",") : null;

    let formData = {
        currentPage: 1,
        pageSize: Utils.getMaxPageSize(),
        sortColumn: this.state.sortColumn,
        includeGlobalService: true,
        includeLocalService: true,
        includeSites: false,
        includeTaxRate: false,
        // fromDate: fromDate,
        // toDate: toDate,
        customerIds: customerIds,
        binTypeIds: binTypeIds,
        wasteTypeIds: wasteTypeIds,
        hubId: this.props.signalRslice.hubId,
    };

    ReportApi.generateCustomEXCEL("service-item/download-report-excel", formData, token).then(
      (result) => {
        apiUtil.parseResult(
          result,
          (data) => {
            if (callback !== null) {
              callback(data);
            }
            
            this.props.action.signalRslice.disconnect();
          },
          (error, type) => {
            if (callback !== null) {
              callback(null);
            }

            this.props.action.signalRslice.disconnect();
          }
        );
      }
    );
  };
  /* END API */


  /* FUNCTIONS */
  isRunReportBtnDisabled = () => {
    let customerLength = this.state.customerSearchColumns && this.state.customerSearchColumns.length > 0 ? this.state.customerSearchColumns.length : 0;
    let binLength = this.state.binSearchColumns && this.state.binSearchColumns.length > 0 ? this.state.binSearchColumns.length : 0;
    let wasteLength = this.state.wasteSearchColumns && this.state.wasteSearchColumns.length > 0 ? this.state.wasteSearchColumns.length : 0;

    return false;
  };
  /* END FUNCTIONS */


  /* SECTIONS */
  topSection = () => {
    return (
      <Box>
        <Grid container spacing={2} alignItems={"center"}>
          {/* <Box clone>
            <Grid item xs={12} md={3} lg={4}>
              <ManageLabel
                className={"no-margin"}
                label={"Reporting periods"}
                weight={"normal"}
                size={"xs"}
              />
              <Box bgcolor="white"  p={[1, 1, 1]}>
                <RSuiteDateRangePicker
                  className={'reports-date-picker'}
                  placeholder={"Select date"}
                  format={"DD.MM.YYYY"}
                  value={this.state.dateRange}
                  cleanable={false}
                  renderValue={(value, format) => {
                    let date = moment(value[0]).format(format) + ' - ' + moment(value[1]).format(format);
                    let label = apiUtil.getDateRangeLabel(value[0], value[1]);

                    return <span>{(label && label !== 'Custom') ? label + ' (' + date + ')' : date}</span>
                  }}
                  onChange={(value) => {
                    this.setState({
                      dateRange: value,
                    });
                  }}
                />
              </Box>

            </Grid>
          </Box> */}

          <Box clone>
            <Grid item xs={12} md={3} lg={3}>
              <ManageLabel
                className={"no-margin"}
                label={"Select Customers"}
                weight={"normal"}
                size={"xs"}
              />
              <RSuiteCheckPicker
                items={this.state.customer}
                value={this.state.customerSearchColumns}
                placeholder={"Select Customers ..."}
                isLoading={this.state.customerIsLoading}
                onInit={() => {
                  this.setState(
                    {
                      customerIsLoading: true,
                    },
                    () => {
                      this.callCustomerReadApi("", (arr) => {
                        this.setState({
                          customerIsLoading: false,
                          customer: arr,
                        });
                      });
                    }
                  );
                }}
                onChange={(values) => {
                  this.setState({
                    customerSearchColumns: values,
                    customerSiteSearchColumns: [],
                  });
                }}
              />
            </Grid>
          </Box>

          <Box clone>
            <Grid item xs={12} md={3} lg={3}>
              <ManageLabel
                className={"no-margin"}
                label={"Select Bins"}
                weight={"normal"}
                size={"xs"}
              />
              <RSuiteCheckPicker
                items={this.state.bin}
                value={this.state.binSearchColumns}
                placeholder={"Select Bins ..."}
                isLoading={this.state.binIsLoading}
                onInit={() => {
                  this.setState(
                    {
                        binIsLoading: true,
                    },
                    () => {
                      this.callBinReadApi("", (arr) => {
                        this.setState({
                          binIsLoading: false,
                          bin: arr,
                        });
                      });
                    }
                  );
                }}
                onChange={(values) => {
                  this.setState({
                    binSearchColumns: values,
                    binSiteSearchColumns: [],
                  });
                }}
              />
            </Grid>
          </Box>

        <Box clone>
            <Grid item xs={12} md={3} lg={3}>
                <ManageLabel
                className={"no-margin"}
                label={"Select Waste"}
                weight={"normal"}
                size={"xs"}
                />
                <RSuiteCheckPicker
                items={this.state.waste}
                value={this.state.wasteSearchColumns}
                placeholder={"Select Waste ..."}
                isLoading={this.state.wasteIsLoading}
                onInit={() => {
                    this.setState(
                    {
                        wasteIsLoading: true,
                    },
                    () => {
                        this.callWasteReadApi("", (arr) => {
                        this.setState({
                            wasteIsLoading: false,
                            waste: arr,
                        });
                        });
                    }
                    );
                }}
                onChange={(values) => {
                    this.setState({
                    wasteSearchColumns: values,
                    wasteSiteSearchColumns: [],
                    });
                }}
                />
            </Grid>
        </Box>

          <Box clone textAlign={"right"}>
            <Grid item xs={12} md={3} lg={3}>
              <ManageLabel
                className={"no-margin"}
                label={<>&nbsp;</>}
                weight={"normal"}
                size={"xs"}
              />
              <Button
                className={"reports-primary-btn"}
                disabled={this.isRunReportBtnDisabled()}
                onClick={() => {
                  this.setState(
                    {
                      rows: [],
                      totalRows: 0,
                      currentPage: 1,
                    },
                    () => {
                      this.callReportReadApi();
                    }
                  );
                }}
              >
                Run report
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Box>
    );
  };

  iconsSection = (ref) => {
    return (
      <Grid container>
        {}
        <IconsList
          items={[
            {
              id: 1,
              text: "Download",
              disabled: this.state.isIconClicked,
              icon: <DownloadIcon />,
            },
          ]}
          onClick={(item) => {
            if (item.id === 1) {
                this.setState({
                    isLoading: false,
                    isIconClicked: true,
                }, () => {
                    this.callGenerateEXCELApi((data) => {
                        let fileName = this.state.pageName + ".xlsx";
                        let type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

                        apiUtil.saveByteArray(fileName, data, type);

                        this.setState({
                            isLoading: false,
                            isIconClicked: false,
                        });
                    });
                });
            }
          }}
        />
      </Grid>
    );
  };

  pageSection = () => {
    return <>
        <div>
            <DataTableScrollSync ref={this.refDataTableScrollSync} className={"with-details"}>{this.setDataTable()}</DataTableScrollSync>
        </div>
        <div>{this.paginationSection()}</div>
    </>
  };

  setDataTable = () => {
    return <DataTable
        ref={this.tableRef}

        className={"no-responsive-table"}
        classNameWrapper={"table-classic"}

        pageName={this.pageName}
        rowId={this.pageIdField}
        caption={this.state.pageName}

        noMarginBottom={false}
        verticalAlign={'middle'}

        stickyHeader={false}
        isHeader={true}
        resizable={false}
        isDragableHeaderColumns={false}
        isDragable={false}
        isCheckbox={false}
        isBorder={false}
        isPagination={false}
        isVirtualize={true}

        columnVisibility={this.state.columnVisibility}

        isLoading={this.state.isLoading}
        rowsPerPageSelected={this.state.rowsPerPageSelected}
        rowsPerPageOptions={this.state.rowsPerPageOptions}

        search={this.state.search}
        rows={this.state.rows}
        columns={this.state.columns}
        details={this.setCustomDetails}

        onRemove={(row) => {

        }}

        onClickCheckAll={(obj, checked, isDisabledBatchAction) => {
            
        }}
        onCheckRow={(obj, id, isDisabledBatchAction) => {
            
        }}

        onSortEnd={(columns, columnVisibility) => {
            this.setState({
                columns: columns,
                columnVisibility: columnVisibility,
            });
        }}
        onOrder={(name, sort) => {
            
        }}
        onResize={(columns) => {
            
        }}
    />
}

setCustomDetails = (row, props, isItemSelected, obj) => {
    let bundleServices = (row && row.bundleServices && row.bundleServices.length > 0) ? row.bundleServices : [];
    
    if(bundleServices && bundleServices.length > 0){
        let isSelected = isItemSelected.isSelected(row[this.pageIdField], row);

      return bundleServices.map((details, d) => {
        let isLast = (d == (bundleServices.length-1)) ? true : false;

        return <TableRow key={d}
          className={'details-row-report custom-details-row-' + row[this.pageIdField] + (row.isLoading ? ' is-loading' : '') + (isSelected ? ' selected' : '') + (isLast ? ' is-last' : '')}
        >
            {(props.columns && props.columns.length > 0) && props.columns.map((col, k) => {
                return col.visible === false ? null : props.columnVisibility[k] === false ? null : (
                <TableCell key={k}>
                    {
                    (col.detailsId && col.detailsId !== '')
                    ?
                    (col.detailsId == 'price') ? taxRate.getTaxRateColumn(details) : details[col.detailsId]
                    :
                    ''
                    }
                </TableCell>
                );
            })}
        </TableRow>
      });
    }
}

paginationSection = () => {
    return <DataTableAjaxPagination
        style={{
            padding: '10px 0px',
            border: '0px',
            left: this.state.size + 'px',
            width: 'calc(100% - ' + (this.state.size + 10) + 'px)'
        }}
        page={this.state.currentPage}
        selected={this.state.rowsPerPageSelected}
        count={this.state.totalRows}
        fixed={true}
        onPageChange={(page) => {
            this.setState({
                currentPage: page,
            }, () => {
                this.callReportReadApi();
            });
        }}
        onOptionsChange={(option) => {
          this.setState({
            currentPage: 1,
            rowsPerPageSelected: option,
          }, () => {
            this.callReportReadApi();
          });
        }}
    />
}
  /* END SECTIONS */

  render() {
    return (
      <Box>
        <ReportsLayout
          auth={this.props.auth}
          isLoading={this.state.isLoading}
          report={(this.state.rows && this.state.rows.length > 0)}
          titleSection={this.state.pageName}
          topSection={this.topSection()}
          infoSection={null}
          iconsSection={this.iconsSection}
          pageSection={this.pageSection()}
          onResize={(size) => {
            this.setState({
                size: size,
            });
          }}
        />
      </Box>
    );
  }
}


const mapStateToProps = state => {
  return {
    ...state,
    ...Utils.mapStateToProps(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  action: {
    signalRslice: {
      connect: (payload) => dispatch(signalRslice.connect(payload)),
      disconnect: (payload) => dispatch(signalRslice.disconnect(payload)),
      onPercentage: (payload) => dispatch(signalRslice.onPercentage(payload)),
      onPercentageWithMessage: (payload) => dispatch(signalRslice.onPercentageWithMessage(payload)),
    },
    cancelToken: {
      cancel: (payload) => dispatch(cancelToken.cancel(payload)),
    },
  }
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ReportsCustomerServices);
