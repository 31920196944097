/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import { axios } from '../../../../utils/axios';
import { ReadAPIParams, success, successAPI, errorAPI, placeholderRows, isNumeric } from '../../../../utils/utils';
import Yup from '../../../../utils/yup';
import { JobStepType } from '../../../../utils/enums';


export interface LocationStruct {
  CustomerSite: number,
  Yard: number,
  Landfill: number,
};
export interface LocationBoolStruct {
  CustomerSite: boolean,
  Yard: boolean,
  Landfill: boolean,
};
export const LocationEnum = {
  CustomerSite: 0,
  Yard: 1,
  Landfill: 2,
};

export interface DriverActivityStruct {
  PickLoadedBin: number,
  DropLoadedBin: number,
  ExchangeBin: number,
  NoActivity: number,
  WasteDisposal: number,
  LoadWaste: number,
  PickEmptyBin: number,
  DropEmptyBin: number,
};
export interface DriverActivityBoolStruct {
  PickLoadedBin: boolean,
  DropLoadedBin: boolean,
  ExchangeBin: boolean,
  NoActivity: boolean,
  WasteDisposal: boolean,
  LoadWaste: boolean,
  PickEmptyBin: boolean,
  DropEmptyBin: boolean,
};
export const DriverActivityEnum: DriverActivityStruct = {
  PickLoadedBin: 0,
  DropLoadedBin: 1,
  ExchangeBin: 2,
  NoActivity: 3,
  WasteDisposal: 4,
  LoadWaste: 5,
  PickEmptyBin: 6,
  DropEmptyBin: 7,
};

export interface DriverAppOptionsStruct {
  RequiredBinID: number,
  TakePhoto: number,
  Signature: number,
  InputBinWeight: number,
  ReviewWasteType: number,
  ChangeBin: number,
  ChangeWaste: number,
  NewBinID: number,
};
export interface DriverAppOptionsBoolStruct {
  RequiredBinID: boolean,
  TakePhoto: boolean,
  Signature: boolean,
  InputBinweight: boolean,
  ReviewWasteType: boolean,
  ChangeBin: boolean,
  ChangeWaste: boolean,
  NewBinID: boolean,
};
export const DriverAppOptionsEnum = {
  RequiredBinID: 0,
  TakePhoto: 1,
  Signature: 2,
  InputBinweight: 3,
  ReviewWasteType: 4,
  ChangeBin: 5,
  ChangeWaste: 6,
  NewBinID: 7,
};


export const fields = {
  jobTemplateName: {
    id: 'jobTemplateName',
    label: 'Job Type Name',
    labelTable: 'Names',
    placeholder: 'Enter job type name',
  },
  jobTemplateDescription: {
    id: 'jobTemplateDescription',
    label: 'Job Type Description',
    labelTable: 'Descriptions',
    placeholder: placeholderRows(5, 'Enter job type description', ''),
    rows: 5,
  },

  jobTemplateType: {
    id: 'jobTemplateType',
    label: 'Types',
    labelTable: 'Types',
    placeholder: '',
  },
  stepType: {
    id: 'stepType',
    label: 'Step type',
    labelTable: 'Step type',
    placeholder: '',
  },

  isActive: {
    id: 'isActive',
    label: 'Status',
    labelTable: 'Status',
    placeholder: '',
  },


};

export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    isActive: Yup.bool().oneOf([true, false]),
    jobTemplateName: Yup.string().required().label(fields.jobTemplateName.label),
    jobTemplateDescription: Yup.string().label(fields.jobTemplateDescription.label),
    jobTemplateType: Yup.number().nullable().label(fields.jobTemplateType.label),
    stepType: Yup.number().nullable().label(fields.stepType.label),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null, defValues: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    let isActive = ((form.isActive == false) || (form.isActive == true)) ? form.isActive : false;
    
    let jobTemplateName = (form.jobTemplateName && form.jobTemplateName !== '') ? form.jobTemplateName : '';
    let jobTemplateDescription = (form.jobTemplateDescription && form.jobTemplateDescription !== '') ? form.jobTemplateDescription : '';
    let jobTemplateType = (form && isNumeric(form.jobTemplateType)) ? form.jobTemplateType : 0;
    let stepType = (form && isNumeric(form.stepType)) ? form.stepType : 0;
    

    data['isActive'] = isActive;

    data['jobTemplateName'] = jobTemplateName;
    data['jobTemplateDescription'] = jobTemplateDescription;
    data['jobTemplateType'] = jobTemplateType;
    data['stepType'] = stepType;
  }
  
  return data;
};
export const prepareData = (values: any = null, id: any = null) => {
  let data: any = {};

  let form = _.cloneDeep(values);

  if(form){
    if(id){
      data['jobTemplateId'] = id;
    }
    
    data['isActive'] = form.isActive;

    data['jobTemplateName'] = form.jobTemplateName;
    data['jobTemplateDescription'] = form.jobTemplateDescription;
    data['jobTemplateType'] = form.jobTemplateType;
    data['stepType'] = form.stepType;
  }

  return data;
};

export const getVehicleBinStatus = (currentStepIndex: number, currentStep: any, steps: Array<any>): any => {
  if(currentStep){
    if (currentStep.driverActivity === DriverActivityEnum.PickEmptyBin) {
      return {
        vehicleStatus: 'Loaded',
        binStatus: 'Empty',
        info: 'You can stack empty bins'
      };

    } else if (currentStep.driverActivity === DriverActivityEnum.PickLoadedBin) {
      return {
        vehicleStatus: 'Loaded',
        binStatus: 'Loaded',
        info: null
      };

    } else if (currentStep.driverActivity === DriverActivityEnum.LoadWaste) {
      return {
        vehicleStatus: 'Loaded',
        binStatus: 'Loaded',
        info: null
      };
      
    } else if (currentStep.driverActivity === DriverActivityEnum.WasteDisposal) {
      return {
        vehicleStatus: 'Loaded',
        binStatus: 'Empty',
        info: null
      };
      
    } else if (currentStep.driverActivity === DriverActivityEnum.DropEmptyBin) {
      return {
        vehicleStatus: 'Empty',
        binStatus: null,
        info: null
      };
      
    } else if (currentStep.driverActivity === DriverActivityEnum.DropLoadedBin) {
      return {
        vehicleStatus: 'Empty',
        binStatus: null,
        info: null
      };
      
    } else if (currentStep.driverActivity === DriverActivityEnum.ExchangeBin) {
      return {
        vehicleStatus: 'Loaded',
        binStatus: 'Loaded',
        info: null
      };
      
    } else if (currentStep.driverActivity === DriverActivityEnum.NoActivity) {
      if(currentStepIndex === 0){
        return null;

      } else {
        let prevIndex = -1;
        let prevStep = null;
        
        for(let i = (currentStepIndex-1); i >= 0; i--){
          if(steps[i] && steps[i].driverActivity !== DriverActivityEnum.NoActivity){
            prevIndex = i;
            prevStep = steps[i];
            break;
          }
        }

        return getVehicleBinStatus(prevIndex, prevStep, steps);
      }
      
    } else {
      return null;
    }
  } else {
    return null;
  }
}
export const disableLocations = (currentStepIndex: number, currentStep: any, steps: Array<any>): LocationBoolStruct => {
  // let prevStep = (currentStepIndex > 0) ? (steps && steps.length > 0) ? steps[currentStepIndex-1] : null : null;

  // if(step === 0){
  //   return {
  //     CustomerSite: false,
  //     Yard: false,
  //     Landfill: true,
  //   };

  // } else {
  //   if(prevStepValue){
  //     if(prevStepValue.location === LocationEnum.CustomerSite){
  //       return {
  //         CustomerSite: true,
  //         Yard: false,
  //         Landfill: false,
  //       };
        
  //     } else if(prevStepValue.location === LocationEnum.Yard){
  //       return {
  //         CustomerSite: false,
  //         Yard: true,
  //         Landfill: false,
  //       };
        
  //     } else if(prevStepValue.location === LocationEnum.Landfill){
  //       return {
  //         CustomerSite: false,
  //         Yard: false,
  //         Landfill: true,
  //       };
    
  //     }
  //   }
  // }

  return {
    CustomerSite: false,
    Yard: false,
    Landfill: false,
  };
}

export const getDriverActivityCurrentStep1 = (currentStepIndex: number, currentStep: any, steps: Array<any>): DriverActivityBoolStruct => {
  // let prevStep = (currentStepIndex > 0) ? (steps && steps.length > 0) ? steps[currentStepIndex-1] : null : null;

  if(currentStep){
    if(currentStep.location === LocationEnum.CustomerSite){
      return {
        PickEmptyBin: false,
        PickLoadedBin: false,
        LoadWaste: false,
        WasteDisposal: true,
        DropEmptyBin: true,
        DropLoadedBin: true,
        ExchangeBin: true,
        NoActivity: false,
      };
      
    } else if(currentStep.location === LocationEnum.Yard){
      return {
        PickEmptyBin: false,
        PickLoadedBin: false,
        LoadWaste: false,
        WasteDisposal: true,
        DropEmptyBin: true,
        DropLoadedBin: true,
        ExchangeBin: true,
        NoActivity: false,
      };
      
    } else if(currentStep.location === LocationEnum.Landfill){
      return {
        PickEmptyBin: true,
        PickLoadedBin: true,
        LoadWaste: true,
        WasteDisposal: true,
        DropEmptyBin: true,
        DropLoadedBin: true,
        ExchangeBin: true,
        NoActivity: false,
      };
    }
  }

  return {
    PickEmptyBin: true,
    PickLoadedBin: true,
    LoadWaste: true,
    WasteDisposal: true,
    DropEmptyBin: true,
    DropLoadedBin: true,
    ExchangeBin: true,
    NoActivity: false,
  };
}
export const canLoadWaste = (currentStepIndex: number, currentStep: any, steps: Array<any>): boolean => {
  let firstStep = (steps && steps.length > 0) ? steps[0] : null;
  let firstStepCanLoadWaste = (firstStep && firstStep.driverActivity && firstStep.driverActivity === DriverActivityEnum.LoadWaste) ? true : false;

  return firstStepCanLoadWaste ? false : true;
}
export const getDriverActivityPrevCustomerSite = (currentStepIndex: number, currentStep: any, steps: Array<any>): DriverActivityBoolStruct => {
  let prevStep = (currentStepIndex > 0) ? (steps && steps.length > 0) ? steps[currentStepIndex-1] : null : null;

  if (prevStep.driverActivity === DriverActivityEnum.PickEmptyBin) {
    if(currentStep){
      if(currentStep.location === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: false,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: false,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }

  } else if (prevStep.driverActivity === DriverActivityEnum.PickLoadedBin) {
    if(currentStep){
      if(currentStep.location === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: false,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: false,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }

  } else if (prevStep.driverActivity === DriverActivityEnum.LoadWaste) {
    if(currentStep){
      if(currentStep.location === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: canLoadWaste(currentStepIndex, currentStep, steps),
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: !canLoadWaste(currentStepIndex, currentStep, steps),
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: canLoadWaste(currentStepIndex, currentStep, steps),
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: !canLoadWaste(currentStepIndex, currentStep, steps),
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }
    
  } else if (prevStep.driverActivity === DriverActivityEnum.WasteDisposal) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: false,
      WasteDisposal: true,
      DropEmptyBin: false,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.driverActivity === DriverActivityEnum.DropEmptyBin) {
    return {
      PickEmptyBin: false,
      PickLoadedBin: false,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.driverActivity === DriverActivityEnum.DropLoadedBin) {
    return {
      PickEmptyBin: false,
      PickLoadedBin: false,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.driverActivity === DriverActivityEnum.ExchangeBin) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: false,
      ExchangeBin: true,
      NoActivity: true,
    };
    
  } else if (prevStep.driverActivity === DriverActivityEnum.NoActivity) {
    if(currentStep){
      if(currentStep.location === LocationEnum.Yard){
        return {
          PickEmptyBin: false,
          PickLoadedBin: false,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: false,
          PickLoadedBin: false,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }
  }

  return {
    PickEmptyBin: true,
    PickLoadedBin: true,
    LoadWaste: true,
    WasteDisposal: true,
    DropEmptyBin: true,
    DropLoadedBin: true,
    ExchangeBin: true,
    NoActivity: false,
  };
}
export const getDriverActivityPrevYard = (currentStepIndex: number, currentStep: any, steps: Array<any>): DriverActivityBoolStruct => {
  let prevStep = (currentStepIndex > 0) ? (steps && steps.length > 0) ? steps[currentStepIndex-1] : null : null;

  if (prevStep.driverActivity === DriverActivityEnum.PickEmptyBin) {
    if(currentStep){
      if(currentStep.location === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: false,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }

  } else if (prevStep.driverActivity === DriverActivityEnum.PickLoadedBin) {
    if(currentStep){
      if(currentStep.location === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }

  } else if (prevStep.driverActivity === DriverActivityEnum.LoadWaste) {
    if(currentStep){
      if(currentStep.location === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: canLoadWaste(currentStepIndex, currentStep, steps),
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: !canLoadWaste(currentStepIndex, currentStep, steps),
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: canLoadWaste(currentStepIndex, currentStep, steps),
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: !canLoadWaste(currentStepIndex, currentStep, steps),
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }
    
  } else if (prevStep.driverActivity === DriverActivityEnum.WasteDisposal) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: false,
      WasteDisposal: true,
      DropEmptyBin: false,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.driverActivity === DriverActivityEnum.DropEmptyBin) {
    return {
      PickEmptyBin: false,
      PickLoadedBin: false,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.driverActivity === DriverActivityEnum.DropLoadedBin) {
    return {
      PickEmptyBin: false,
      PickLoadedBin: false,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.driverActivity === DriverActivityEnum.ExchangeBin) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: false,
      ExchangeBin: true,
      NoActivity: true,
    };
    
  } else if (prevStep.driverActivity === DriverActivityEnum.NoActivity) {
    if(currentStep){
      if(currentStep.location === LocationEnum.Yard){
        return {
          PickEmptyBin: false,
          PickLoadedBin: false,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: false,
          PickLoadedBin: false,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }
  }

  return {
    PickEmptyBin: true,
    PickLoadedBin: true,
    LoadWaste: true,
    WasteDisposal: true,
    DropEmptyBin: true,
    DropLoadedBin: true,
    ExchangeBin: true,
    NoActivity: false,
  };
}
export const getDriverActivityPrevLandfill = (currentStepIndex: number, currentStep: any, steps: Array<any>): DriverActivityBoolStruct => {
  let prevStep = (currentStepIndex > 0) ? (steps && steps.length > 0) ? steps[currentStepIndex-1] : null : null;

  if (prevStep.driverActivity === DriverActivityEnum.PickEmptyBin) {
    if(currentStep){
      if(currentStep.location === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }

  } else if (prevStep.driverActivity === DriverActivityEnum.PickLoadedBin) {
    if(currentStep){
      if(currentStep.location === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }

  } else if (prevStep.driverActivity === DriverActivityEnum.LoadWaste) {
    if(currentStep){
      if(currentStep.location === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }
    
  } else if (prevStep.driverActivity === DriverActivityEnum.WasteDisposal) {
    if(currentStep){
      if(currentStep.location === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.location === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }
    
  } else if (prevStep.driverActivity === DriverActivityEnum.DropEmptyBin) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.driverActivity === DriverActivityEnum.DropLoadedBin) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.driverActivity === DriverActivityEnum.ExchangeBin) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.driverActivity === DriverActivityEnum.NoActivity) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
  }

  return {
    PickEmptyBin: true,
    PickLoadedBin: true,
    LoadWaste: true,
    WasteDisposal: true,
    DropEmptyBin: true,
    DropLoadedBin: true,
    ExchangeBin: true,
    NoActivity: false,
  };
}
export const disableDriverActivityes = (currentStepIndex: number, currentStep: any, steps: Array<any>): DriverActivityBoolStruct => {
  let prevIndex = ((currentStepIndex-1) > -1) ? currentStepIndex-1 : -1;
  let prevStep = (currentStepIndex > 0) ? (steps && steps.length > 0 && steps[prevIndex]) ? steps[prevIndex] : null : null;

  if(currentStepIndex === 0){
    return getDriverActivityCurrentStep1(currentStepIndex, currentStep, steps);

  } else {
    if((prevIndex > 0) && prevStep.driverActivity === DriverActivityEnum.NoActivity){
      let prevPrevIndex = -1;
      let prevPrevStep = null;

      for(let i = prevIndex; i >= 0; i--){
        if(steps[i].driverActivity !== DriverActivityEnum.NoActivity){
          prevPrevIndex = i+1;
          prevPrevStep = steps[prevPrevIndex];
          break;
        }
      }

      if(prevPrevStep){
        if(prevPrevStep.location === LocationEnum.CustomerSite){
          return getDriverActivityPrevCustomerSite(prevPrevIndex, prevPrevStep, steps);
  
        } else if(prevPrevStep.location === LocationEnum.Yard){
          return getDriverActivityPrevYard(prevPrevIndex, prevPrevStep, steps);
          
        } else if(prevPrevStep.location === LocationEnum.Landfill){
          return getDriverActivityPrevLandfill(prevPrevIndex, prevPrevStep, steps);
        }
      }
      
    } else {
      if(prevStep){
        if(prevStep.location === LocationEnum.CustomerSite){
          return getDriverActivityPrevCustomerSite(currentStepIndex, currentStep, steps);
  
        } else if(prevStep.location === LocationEnum.Yard){
          return getDriverActivityPrevYard(currentStepIndex, currentStep, steps);
          
        } else if(prevStep.location === LocationEnum.Landfill){
          return getDriverActivityPrevLandfill(currentStepIndex, currentStep, steps);
        }
      }
    }
  }

  return {
    PickEmptyBin: true,
    PickLoadedBin: true,
    LoadWaste: true,
    WasteDisposal: true,
    DropEmptyBin: true,
    DropLoadedBin: true,
    ExchangeBin: true,
    NoActivity: false,
  };
}

export const getDriverAppOptionsStepName = (currentStepIndex: number, currentStep: any, steps: Array<any>): any => {
  let location: any = '';
  if(currentStep.location === LocationEnum.CustomerSite){
    location = 'Customer Location'
  } else if(currentStep.location === LocationEnum.Yard){
    location = 'Yard'
  } else if(currentStep.location === LocationEnum.Landfill){
    location = 'Landfill'
  }

  let activity: any = '';
  if(currentStep.driverActivity === DriverActivityEnum.PickLoadedBin){
    activity = 'Pick Loaded Bin'
  } else if(currentStep.driverActivity === DriverActivityEnum.DropLoadedBin){
    activity = 'Drop Loaded Bin'
  } else if(currentStep.driverActivity === DriverActivityEnum.ExchangeBin){
    activity = 'Exchange Bin'
  } else if(currentStep.driverActivity === DriverActivityEnum.NoActivity){
    activity = 'No Activity'
  } else if(currentStep.driverActivity === DriverActivityEnum.WasteDisposal){
    activity = 'Waste Disposal'
  } else if(currentStep.driverActivity === DriverActivityEnum.LoadWaste){
    activity = 'Load Waste'
  } else if(currentStep.driverActivity === DriverActivityEnum.PickEmptyBin){
    activity = 'Pick Empty Bin'
  } else if(currentStep.driverActivity === DriverActivityEnum.DropEmptyBin){
    activity = 'Drop Empty Bin'
  }

  if(activity != '' && location != ''){
    return [activity, 'at', location].join(' ');
  } else if(activity != ''){
    return activity;
  } else if(location != ''){
    return location;
  } else {
    return '';
  }
}
export const getDriverAppOptionsStepInfo = (currentStepIndex: number, currentStep: any, steps: Array<any>): DriverAppOptionsBoolStruct => {
  let backData: DriverAppOptionsBoolStruct = {
    RequiredBinID: false,
    TakePhoto: false,
    Signature: false,
    InputBinweight: false,
    ReviewWasteType: false,
    ChangeBin: false,
    ChangeWaste: false,
    NewBinID: false,
  }

  if(currentStep){
    if(currentStep.location === LocationEnum.CustomerSite){
      backData.TakePhoto = true;
      backData.Signature = true;

    } else if((currentStep.location === LocationEnum.Yard) || (currentStep.location === LocationEnum.Landfill)){
      if ((currentStep.driverActivity === DriverActivityEnum.DropLoadedBin) || (currentStep.driverActivity === DriverActivityEnum.WasteDisposal)) {
        backData.InputBinweight = true;
        backData.ReviewWasteType = true;
        backData.TakePhoto = true;
      }
    }

    if ((currentStep.driverActivity === DriverActivityEnum.PickLoadedBin) || (currentStep.driverActivity === DriverActivityEnum.PickEmptyBin) || (currentStep.driverActivity === DriverActivityEnum.ExchangeBin)) {
      backData.RequiredBinID = true;
      backData.ChangeBin = true;
      backData.ChangeWaste = true;
      backData.NewBinID = true;
    }
  }

  return backData;
}


export interface StepSiteStruct {
  isDefault: boolean,
  jobTemplateStepSiteId: number|null,
  stepSiteName: string,
  address: string,
  stepSiteAddress: string,
  stepSiteLatitude: number|null,
  stepSiteLongitude: number|null,
};
export const initStepSite: StepSiteStruct = {
  isDefault: false,
  jobTemplateStepSiteId: null,
  stepSiteName: '',
  address: '',
  stepSiteAddress: '',
  stepSiteLatitude: null,
  stepSiteLongitude: null,
};


export interface StepStruct {
  location: number,
  hasDefaultLocation: boolean,
  templateStepSites: Array<any>,
  driverActivity: number|null,
};
export const initStep: StepStruct = {
  location: LocationEnum.CustomerSite,
  hasDefaultLocation: true,
  templateStepSites: [initStepSite],
  driverActivity: null,
};


export interface initialValuesStruct {
  isActive: boolean,

  jobTemplateName: string,
  jobTemplateDescription: string,
  jobTemplateType: number|null,
  stepType: number|null,

  steps: Array<any>,
};
export const initialValues: initialValuesStruct = {
  isActive: true, 

  jobTemplateName: '',
  jobTemplateDescription: '',
  jobTemplateType: null,
  stepType: null,

  steps: [initStep],
};


interface InitState {
  isLoading: boolean,
  id: any|null,
  details: any,
  tab: string,

  isLoadingCreateUpdateDelete: boolean,
  isLoadingChangeStatus: boolean,
}

function NewReducer() {
  const name = 'manageJobTypesSlice';


  const initialState: InitState = {
    isLoading: false,
    id: null,
    details: initialValues,
    tab: 'tab1',

    isLoadingCreateUpdateDelete: false,
    isLoadingChangeStatus: false,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTab: (state: InitState, action: PayloadAction<string>) => {
      state.tab = action.payload;
    },
    setId: (state: InitState, action: PayloadAction<any>) => {
      state.id = action.payload;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      // state.list = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      // state.list = data;
    },

    startDetails: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.details = action.payload;
    },

    startCreate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startUpdate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startDelete: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
    
    startActivate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishActivate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
    
    startChangeStatus: (state: InitState) => {
      state.isLoadingChangeStatus = true;
    },
    finishChangeStatus: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingChangeStatus = false;
    },
  };


  const apis = {
    callReadApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startRead());
  
        await axios.get('JobTemplate', { params: params }).then((result: any) => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishRead(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishRead(null));
        });
    },

    callDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetails());
  
        await axios.get('JobTemplate/' + id).then((result: any) => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDetails(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDetails(null));
        });
    },

    callCreateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreate());
  
        await axios.post('JobTemplate', params).then((result: any) => {
            let data = result.data;
            
            // successAPI(data);
            success('Saved successfully', 'The data you entered has been successfully saved.');

            callback(true, data);
            dispatch(actions.finishCreate(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishCreate(null));
        });
    },

    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdate());
  
        await axios.put('JobTemplate', params).then((result: any) => {
            let data = result.data;
            
            // successAPI(data);
            success('Updated successfully', 'Your data has been successfully updated.');

            callback(true, data);
            dispatch(actions.finishUpdate(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdate(null));
        });
    },

    callDeleteApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDelete());
  
        await axios.delete('JobTemplate', { data: params }).then((result: any) => {
            let data = result.data;
                
            // successAPI(data);
            success('Deleted successfully', 'Job Type has been successfully deleted.');

            callback(true, data);
            dispatch(actions.finishDelete(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDelete(null));
        });
    },

    callActivateApi: (isActive: boolean, ids: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startActivate());

        let params = {
          isActive: isActive,
          ids: ids,
        };

        await axios.put('JobTemplate/deactivate', params).then((result: any) => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishActivate(obj));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishActivate(null));
        });
    },

    callChangeStatusApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startChangeStatus());

      await axios.put('JobTemplate/activate', params).then((result: any) => {
          let data = result.data;
          
          successAPI(data);

          callback(true, data);
          dispatch(actions.finishChangeStatus(data));
      }).catch((error: any) => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishChangeStatus(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();